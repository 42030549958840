import { isAfter, isBefore } from 'date-fns';

// 현재 오늘 날짜 이전부터 비활성화
export function isDisabled({ date, startDisabledDate }) {
  //  console.log(date, startDisabledDate, endDisabledDate)

  if (isBefore(date, startDisabledDate)) {
    return true;
  }

  return false;
}
