<script>
  import { createEventDispatcher } from 'svelte';
  import Toast from '../Toast.svelte';

  export let mode; // READ or WRITE
  export let content; // 편지 내용
  export let file; // 첨부된 파일
  export let paperType = '';
  let value = content; // 초기 편지 내용 textarea에 저장

  let toast;
  const dispatch = createEventDispatcher();

  console.log(content, file);

  function sendTextareaInput(event) {
    dispatch('body', value);
  }
</script>

<Toast bind:this={toast} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="letter-container">
  <!-- image section -->
  {#if file && file.url}
    {#if file.type == 'image'}
      <div class="image-section">
        <img src={file.url} alt="" />
        <div class="tape">
          <img src="/assets/image/tape.svg" alt="" />
        </div>
      </div>
    {/if}
  {/if}

  <div class="letter-paper">
    <div
      class={mode === 'READ' ? 'content' : mode === 'WRITE' ? 'input' : ''}
      id={paperType}
    >
      <!-- audio section -->
      {#if file && file.url}
        {#if file.type == 'audio'}
          <div class="audio-section">
            <audio controls controlsList="nodownload" src={file.url} />
            <div class="audio-title">
              <br />
            </div>
          </div>
        {/if}
      {/if}

      <!-- content section -->
      {#if mode == 'READ'}
        {content}
        <!-- input section -->
      {:else if mode == 'WRITE'}
        <textarea
          bind:value
          on:input={sendTextareaInput}
          placeholder="편지를 입력해주세요"
        />
      {/if}
    </div>
  </div>
</div>

<style>
  .letter-container {
    margin-top: 20px;
    padding: 0px 28px;
  }

  .letter-paper {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: #fffdf8;
    padding: 12px;
    border-radius: 20px;
  }

  .content,
  .input {
    /* 편지지 디자인 변경에 영향 받지 않는 속성만 기술 */
    box-sizing: border-box;
    padding: 20px 20px;
    left: 12px;
    top: 12px;
    border-radius: 20px;
    font-family: 'Gangwon_Light';
    /* font-size: 16px; */
    font-size: 1.1rem;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
    word-wrap: break-word;
  }
  #letter-A {
    background-color: #fffdf8;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23669AFFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    box-sizing: border-box;
    color: #344054;
  }
  #reply-A {
    background-color: #fffdf8;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23CE9DFFFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    box-sizing: border-box;
    color: #344054;
  }

  .input textarea {
    width: 100%;
    min-height: 456px;
    outline: none;
    border: none;
    resize: none;
    background-color: #fffdf8;
    font-family: 'Gangwon_Light';
    /* font-size: 16px; */
    font-size: 1.1rem;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
    color: #344054;
    -webkit-text-stroke: 0.05px black;
  }
  textarea::placeholder {
    color: #98a2b3;
  }

  /* image & video section */
  .image-section {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    text-align: center;
    position: relative;
  }
  .image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  .audio-section {
    width: 100%;
    left: 60px;
    margin-bottom: 10px;
    height: 50px;
    box-sizing: border-box;
  }

  .audio-section audio {
    width: 100%;
    height: 40px;
    justify-content: space-between;
  }

  .audio-section .audio-title {
    position: absolute;
    display: block;
    margin: auto 0;
    padding: 0 2px;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    height: 28px;
    top: 40px;
    left: 74px;
    background-color: rgba(0, 0, 0, 0); /* var(--gray-200-color); */
    color: var(--gray-500-color);
    font-size: 12px;
    font-family: 'Pretendard_Medium';
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 7;
  }

  audio::-webkit-media-controls-panel {
    -webkit-justify-content: center;
    height: 40px;
    border-radius: 8px;
    background-color: var(--gray-200-color);
  }

  audio::-webkit-media-controls-mute-button {
    display: none;
  }

  audio::-webkit-media-controls-current-time-display {
    display: none;
  }

  audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }

  .tape {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 8;
  }
</style>
