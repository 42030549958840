<script>
  import { navigate } from 'svelte-routing';
  import axios from '../../libs/axios';
  import Toast from '../../components/Toast.svelte';
  import BottomSheet from './LinkBottomSheet.svelte';
  import { errorHandler } from '../../utils/errorHandler';
  import { calculateDDay } from '../../utils/DdayCalculator';
  import { shareKakao, copyToClipboard } from '../../utils/share';

  export let postboxId;
  let toast;
  let deliveryStatus; // before, ing, completed
  let days = 0;

  let isVisible = false;
  let isBottomSheetVisible = false;

  function showBottomSheet() {
    isBottomSheetVisible = true;
  }

  function hideBottomSheet() {
    isBottomSheetVisible = false;
  }

  axios
    .get('/api/postbox')
    .then((response) => {
      if (
        response.data &&
        response.data.results &&
        response.data.results.length > 0
      ) {
        postboxId = response.data.results[0].id;
        checkDeliveryStatus();
      } else {
        navigate('/onboarding');
      }
    })
    .catch((error) => {
      errorHandler(error);
    });

  async function checkDeliveryStatus() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    try {
      const postboxResponse = await axios.get(`/api/rcpt/postbox/${postboxId}`);
      const letterlistResponse = await axios.get(
        `/api/rcpt/postbox/${postboxId}/letter`
      );
      const postboxData = postboxResponse.data;
      const letterlistData = letterlistResponse.data;
      console.log(postboxData, letterlistData);

      const firstLetterDate = new Date(postboxData.letter_start_date);
      firstLetterDate.setHours(0, 0, 0, 0);
      const lastLetterDate = new Date(postboxData.letter_end_date);

      if (now >= lastLetterDate) {
        // 모든 편지 배달 완료
        deliveryStatus = 'completed';
      } else if (now < firstLetterDate) {
        // 편지 배달 전
        deliveryStatus = 'before';
        days = parseInt(calculateDDay(firstLetterDate)[0]);
      } else {
        // 편지 배달 중
        deliveryStatus = 'ing';
        days = parseInt(calculateDDay(firstLetterDate)[0]) + 1;
      }
      console.log(firstLetterDate, now, lastLetterDate, deliveryStatus, days);
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleNextLetter = () => {
    navigate(`/me/letterlist`);
  };
</script>

<Toast bind:this={toast} />

<body class="letter-ui">
  <div class="container">
    <div class="head-section">
      <div class="logo-section">
        <img class="text-logo" src="/assets/image/icon/text-logo.png" />
      </div>
      <!--배달 중 -->
      {#if deliveryStatus == 'ing'}
        <div class="head-wrapper-ing">
          <div class="head-up">
            <div class="head-title">
              <h3>{days}일째 편지 배달을<br />진행중이에요</h3>
            </div>
            <div class="head-btn">
              <button>편지 배달 중</button>
            </div>
          </div>
          <div class="mailbox-container">
            <img src="/assets/image/mailbox/boy-mailbox-ing.png" alt="" />
          </div>
          <div class="end-btn-section">
            <button class="head-low-btn" on:click={handleNextLetter}>
              답장 확인하기
            </button>
          </div>
        </div>
        <!-- 배달 후 -->
      {:else if deliveryStatus == 'completed'}
        <div class="head-wrapper-after">
          <div class="head-up">
            <div class="head-title">
              <h3>모든 편지 배달이<br />완료되었어요</h3>
            </div>
            <div class="head-btn">
              <button>편지 배달 완료</button>
            </div>
          </div>
          <div class="mailbox-container">
            <img src="/assets/image/mailbox/boy-mailbox-after.png" alt="" />
          </div>
          <div class="end-btn-section">
            <button class="head-low-btn" on:click={handleNextLetter}>
              주고 받은 편지들 확인하기
            </button>
          </div>
        </div>
        <!-- 배달 전 -->
      {:else if deliveryStatus == 'before'}
        <div class="head-wrapper-before">
          <div class="head-up">
            <div class="head-title">
              <h3>편지 배달일까지<br />{days}일 남았어요</h3>
            </div>
            <div class="head-btn">
              <button>편지 배달 전</button>
            </div>
          </div>
          <div class="mailbox-container">
            <img src="/assets/image/mailbox/boy-mailbox-before.png" alt="" />
          </div>
          <div class="end-btn-section">
            <button class="head-low-btn" on:click={handleNextLetter}>
              작성한 편지 확인하기
            </button>
          </div>
        </div>
      {:else}
        <br /> <!-- 데이터 받아오기 전 '배달 전'으로 렌더링 방지 -->
      {/if}
      <div class="check-section">
        <!-- 배달 전 -->
        {#if deliveryStatus == 'before'}
          <h3>상대방을 위한 우체통 링크를 전달했나요?</h3>
          <div class="down-context">
            <button class="kakao-btn" on:click={shareKakao(postboxId)}>
              <img src="/assets/image/icon/kakao-logo-size.svg" alt="" />
              카카오톡으로<br />전달하기
            </button>
            <button class="clip-btn" on:click={copyToClipboard(postboxId)}>
              <img src="/assets/image/icon/clip-icon-black.svg" alt="" />
              우체통 링크<br />직접 복사하기</button
            >
          </div>
          <!-- 배달 중 후 -->
        {:else}
          <div class="down-context">
            <button class="not-before-btn" on:click={showBottomSheet}>
              <div class="text-part">
                상대방에게 전달할 우체통 링크를 잃어버렸어요
              </div>
              <div class="arrow-icon" />
            </button>
            <p class="text-faq">
              <a
                href="https://misae.notion.site/e3744848ea944e6998cb8d5718f87199?pvs=4"
                target="_blank"
              >
                달의 우체통에 궁금한 점이 있으신가요?
              </a>
            </p>
          </div>
        {/if}
      </div>
    </div>
  </div>
  <div class="footer-section">
    <a href="https://www.instagram.com/dalchetong_official">
      <img
        class="icon/instagram-logo"
        src="/assets/image/icon/instagram-logo.png"
        alt=""
      />
    </a>
    <div class="info">
      <p>
        <a
          href="https://misae.notion.site/539191bc947f49e494787e50f0d5a5ae?pvs=4"
        >
          <span>이용약관</span>
        </a>
        <span class="line-hr">|</span>
        <a
          href="https://misae.notion.site/71b33659a4454e0a9e4cd1955d0efbce?pvs=4"
        >
          <span>개인정보처리방침</span>
        </a>
      </p>
      <p>
        모든 문의 사항은 달의 우체통 공식 인스타그램 DM 혹은<br />달의 우체통
        공식 이메일을 통해 접수해주세요.
      </p>
      <p>문의: help@dalchetong.com</p>
    </div>
    <div class="copyright">
      미학적인 세상을 꿈꾸는 사람들, MISAE<br />
      Copyright ⓒMISAE. All rights Reserved
    </div>
  </div>

  {#if isBottomSheetVisible}
    <BottomSheet {isVisible} {postboxId} on:close={hideBottomSheet} />
    <div class="modal-dimmed" on:click={hideBottomSheet} />
  {/if}
</body>

<style>
  .container {
    position: relative;
    margin: 0 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .head-section {
    text-align: left;
    margin-top: 36px;
  }

  .logo-section {
    position: relative;
    display: flex;
    margin-bottom: 28px;
  }

  .logo-section img {
    height: 18px;
  }

  .head-wrapper-before {
    position: relative;
    border-radius: 14px;
    border: none;
    height: 328px;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 28px;
    align-items: center;
    background-color: #f2f1ff;
    border-radius: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .head-wrapper-ing {
    position: relative;
    border-radius: 14px;
    border: none;
    height: 328px;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 28px;
    align-items: center;
    background-color: #dbd9ff;
    border-radius: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .head-wrapper-after {
    position: relative;
    border-radius: 14px;
    border: none;
    height: 328px;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 28px;
    align-items: center;
    background-color: #7e75f5;
    border-radius: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .head-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .head-wrapper-before .head-btn button,
  .head-wrapper-ing .head-btn button {
    width: 78px;
    height: 28px;
    border: none;
    border-radius: 48px;
    flex-shrink: 0;
    background-color: #f9fafb;
    color: #6c63eb;
    font-size: 12px;
    font-family: 'Pretendard_Medium';
    align-items: center;
  }

  .head-wrapper-after .head-btn button {
    width: 90px;
    height: 28px;
    border: none;
    border-radius: 48px;
    flex-shrink: 0;
    background-color: #574fbc;
    color: #fff;
    font-size: 12px;
    font-family: 'Pretendard_Medium';
    align-items: center;
  }

  .head-title {
    display: flex;
    flex-direction: column;
  }

  .head-wrapper-before .head-title h3,
  .head-wrapper-ing .head-title h3 {
    text-align: left;
    color: var(--black-color);
    font-family: 'Pretendard_Bold';
    margin-top: 0;
    line-height: 150%;
  }

  .head-wrapper-after .head-title h3 {
    text-align: left;
    color: #fff;
    font-family: 'Pretendard_Bold';
    margin-top: 0;
    line-height: 150%;
  }

  .mailbox-container {
    /* height: 380px; */
    height: 200px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 8px);
  }

  .end-btn-section {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .head-wrapper-before .head-low-btn,
  .head-wrapper-ing .head-low-btn {
    position: relative;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 14px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Pretendard_Regular';
    text-decoration: none;
    color: #fff;
    background-color: #6c63eb;
    align-items: center;
    justify-content: center;
  }

  .head-wrapper-after .head-low-btn {
    position: relative;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 14px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Pretendard_Regular';
    text-decoration: none;
    color: #6c63eb;
    background-color: #fff;
    align-items: center;
    justify-content: center;
  }

  .check-section {
    position: relative;
    height: auto;
    text-align: left;
    margin-bottom: 28px;
  }

  .check-section h3 {
    text-align: left;
    color: var(--black-color);
    font-family: 'Pretendard_Bold';
  }

  .down-context {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .down-context button {
    color: #181600;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    border: none;
    border-radius: 14px;
    font-family: 'Pretendard_Regular';
  }

  .kakao-btn {
    flex-grow: 1;
    max-width: 50%;
    height: 108px;
    padding: 14px;
    background-color: #fee500;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .kakao-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .clip-btn {
    flex-grow: 1;
    max-width: 50%;
    height: 108px;
    padding: 10px;
    background-color: #e7ecf4;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .kakao-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .down-context .not-before-btn {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 14px;
    padding: 0 20px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Pretendard_Regular';
    text-decoration: none;
    color: #1d2939;
    background-color: #eff1f4;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .down-context .not-before-btn .text-part {
    display: flex;
    color: #1d2939;
    font-family: 'Pretendard_Regular';
    font-size: 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .down-context a {
    text-decoration: none;
  }

  .arrow-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/icon/arrow-next-icon-size.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .footer-section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: var(--gray-100-color);
    font-family: 'Pretendard_Regular';
  }

  .footer-section img {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 24px;
    right: 68px;
    cursor: pointer;
  }

  .info {
    font-size: 12px;
    color: var(--gray-500-color);
  }

  .info p {
    line-height: 150%;
  }

  .info span {
    cursor: pointer;
  }

  .info .line-hr {
    margin: 0 10px;
    color: var(--gray-300-color);
    cursor: none;
  }

  .info p a,
  .info p a:link,
  .info p a:visited {
    color: var(--gray-500-color);
  }

  .copyright {
    display: flex;
    margin-top: 26px;
    font-size: 12px;
    color: var(--gray-400-color);
    line-height: 150%;
  }

  .text-faq {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
    color: inherit;
    color: var(--gray-400-color);
    font-family: 'Pretendard_Regular';
    font-size: 12px;
    text-decoration-line: underline;
  }

  .modal-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
  }
</style>
