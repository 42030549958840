<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
	import { slide } from 'svelte/transition';
  import ExpandON from 'svelte-material-icons/ChevronDown.svelte';
  import ExpandOFF from 'svelte-material-icons/ChevronUp.svelte';
  import Button from '../../components/Button.svelte';
  import Help from 'svelte-material-icons/HelpCircleOutline.svelte';

  function shareKakao() {
    const shareURL = `https://dalchetong.com`;
    const shareTitle = '함께하는 훈련소 예약편지, 달의 우체통';
    const shareDes = '우리 같이 달의 우체통에서 편지쓸래?';
    const shareImage = 'https://dalchetong.com/assets/image/shareKakao2.png';

    const Kakao = window.Kakao;
    if (!Kakao.isInitialized()) {
      Kakao.init('d9ef90c29c44323e77756a667a3b2ffc');
    }
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDes,
        imageUrl: shareImage,
        imageWidth: 3600,
        imageHeight: 1860,
        link: {
          mobileWebUrl: shareURL,
          webUrl: shareURL,
        },
      },
      buttons: [
        {
          title: '달의 우체통 바로가기',
          link: {
            mobileWebUrl: shareURL,
            webUrl: shareURL,
          },
        },
      ],
    });
  }

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();

  let clickHelp;
  let clickCount = 0;

  const showHelp = () => {
    clickHelp = true;
  };

  const hideHelp = () => {
    clickHelp = false;
  };

  const handleHelp = () => {
    clickCount++;
    if (clickCount === 2) {
      hideHelp();
      clickCount = 0;
    } else {
      showHelp();
    }
  };

  let open = false;

  const handleOpen = () => {
    open = !open;
  };
</script>

<body class="start-ui">
  <div class="text-section">
    <h3 class="title">
      꽃신을 꿈꾸는 당신,<br />
      <div class="flower">
        환영해요!
        <div class="flower-icon" />
      </div>
    </h3>
    <p class="onboarding">
      함께 달의 우체통을 사용하길 원한다면<br />먼저
      <span>상대방에게 달의 우체통 사이트를 공유</span>해주세요
    </p>
  </div>
  <div class="select-container">
    <div class="box-wrapper">
      <div class="step-icon-1" />
      <div class="box-context">
        <div class="text-context">
          <p class="info-main">예비군화가 먼저 접속해요</p>
          <p class="info-sub">
            달의 우체통은 예비군화가 먼저 접속하여<br />편지를 작성하는
            시스템이에요.
          </p>
        </div>
      </div>
    </div>
    <div class="box-wrapper">
      <div class="step-icon-2" />
      <div class="box-context">
        <div class="text-context">
          <p class="info-main">새로운 우체통 링크를 전달해요</p>
          <p class="info-sub">
            예비군화가 편지를 다 작성했다면,<br />당신에게 새로운 우체통 링크를
            전달할 거예요.
          </p>
        </div>
        <div class="open-section" on:click={handleOpen}>
          <div class="arrow">
            <p>새로운 우체통 링크가 무엇인가요?</p>
            {#if !open}
              <ExpandON width={18} height={18} color={'#E2E0FB'} />
            {:else}
              <ExpandOFF width={18} height={18} color={'#E2E0FB'} />
            {/if}
          </div>
          {#if open}
            <div class="open-content"  transition:slide="{{ y: 60, duration: 100 }}">
              <img src="/assets/image/icon/open-img-sample.png" alt="" />
              <p>
                위의 사진처럼 상대방에게 편지를 받을 새로운 우체통을 메신저를
                통해 전달받을 거예요! 해당 링크에서 편지를 받아볼 수 있답니다 :)
              </p>
            </div>
          {/if}
        </div>
      </div>
    </div>
    <div class="box-wrapper">
      <div class="step-icon-3" />
      <div class="box-context">
        <div class="text-context">
          <p class="info-main">편지는 새로운 우체통 링크에서 받아요</p>
          <p class="info-sub">
            우리만의 우체통 링크에서 편지를 받아보고<br />사라지지 않는 답장도
            남길 수 있어요.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="doit-section">
    <p>우리 같이 달체통으로 편지쓰자!</p>
  </div>
  <div class="button-section">
    <Button class="kakao-ui" handleClick={shareKakao}>
      <div class="kakao-logo" />
      카카오톡으로 바로 공유하기</Button
    >
  </div>
  <div class="tip-container">
    <div class="help-section" id="help-section" on:click={handleHelp}>
      <Help width={16} height={16} color={'#EFEEFF'} />
      <span>링크를 이미 전달했어요! 이제 어떡하죠?</span>
    </div>
    {#if clickHelp}
      <div class="tip-section" id="tip-section">
        <div class="tip-wrapper">
          <p>완벽해요! 이곳에서 당신이 해야 할 모든 일은 마무리되었습니다.</p>
          <p>
            위의 사진처럼 상대방에게 편지를 받을 새로운<br />
            우체통을 메신저를 통해 전달받을 거예요!<br />
            해당 링크에서 편지를 받아볼 수 있답니다 :)
          </p>
        </div>
      </div>
    {/if}
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 56px;
    margin-bottom: 88px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    font-weight: 400;
    text-align: left;
    letter-spacing: -0.56px;
    line-height: 150%;
    margin: 0 24px;
  }

  .flower {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .flower-icon {
    width: 28px;
    height: 28px;
    margin-left: 6px;
    position: relative;
    background-image: url('/assets/image/icon/flower-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .onboarding {
    width: 100%;
    height: auto;
    padding: 12px 28px;
    background-color: #b73292;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
    opacity: 0.95;
  }

  .onboarding span {
    font-weight: 700;
    font-family: 'Pretendard_Medium';
  }

  .select-container {
    position: relative;
    height: auto;
    margin: 48px 24px 20px 24px;
  }

  .box-wrapper {
    width: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }

  .step-icon-1 {
    width: 30px;
    height: 30px;
    margin-left: 6px;
    position: relative;
    background-image: url('/assets/image/icon/step-icon-1.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .step-icon-2 {
    width: 30px;
    height: 30px;
    margin-left: 6px;
    position: relative;
    background-image: url('/assets/image/icon/step-icon-2.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .step-icon-3 {
    width: 30px;
    height: 30px;
    margin-left: 6px;
    position: relative;
    background-image: url('/assets/image/icon/step-icon-3.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .box-context {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 4px;
    margin-bottom: 24px;
  }

  .open-section {
    width: 100%;
    max-width: 216px;
    height: auto;
    margin: 12px auto;
    padding: 0px 18px;
    background-color: #222647;
    border-radius: 8px;
    font-size: 12px;
    color: #e2e0fb;
    text-align: left;
  }

  .arrow {
    width: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }

  .open-content {
    width: 100%;
    max-width: 216px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    line-height: 150%;
    font-size: 12px;
    transition: max-height 0.4s ease-out;
  }

  .open-content img {
    width: 100%;
    height: 100%;
  }

  .open-content p {
    line-height: 150%;
    font-family: 'Pretendard_Regular';
  }

  .text-context {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Pretendard_Regular';
    text-align: left;
  }

  .text-context .info-main {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Pretendard_SemiBold';
    line-height: 150%;
    margin: 0;
  }

  .text-context .info-sub {
    color: #e2e0fb;
    font-size: 13px;
    font-family: 'Pretendard_Medium';
    line-height: 150%;
    margin: 0;
    margin-top: 4px;
  }

  .doit-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 16px;
  }

  .doit-section p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Gangwon_Light';
    -webkit-text-stroke: 0.5px #fff;
    text-align: center;
    margin: 0;
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 16px 40px 16px 40px;
    z-index: 1;
  }

  .kakao-logo {
    background-image: url('/assets/image/icon/kakao-logo-size.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .tip-container {
    position: relative;
    margin-top: 18px;
    margin-bottom: 32px;
  }

  .help-section {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
  }

  .help-section span {
    margin-left: 6px;
    color: #efeeff;
    font-weight: 500;
    font-family: 'Pretendard_Medium';
  }

  .tip-section {
    position: relative;
    display: flex;
    margin: 10px 40px 0 40px;
    justify-content: center;
    background-color: var(--secondary-25-color);
    border: none;
    border-radius: 12px;
    transition: bottom 0.3s ease-in-out;
  }

  .tip-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2.8px 16px;
    justify-content: center;
    line-height: 102%;
    font-family: 'Pretendard_Regular';
  }

  .tip-section p {
    font-size: 10px;
    text-align: left;
    font-weight: 400;
    color: var(--black-color);
    gap: 12px;
  }
</style>
