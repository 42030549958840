<script>
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import Help from 'svelte-material-icons/HelpCircleOutline.svelte';
  import SDateRangePicker from '../../components/Start/date-range-picker/SDateRangePicker.svelte';
  import { endOfWeek, startOfWeek, startOfDay, endOfDay } from 'date-fns';
  //import * as locales from "date-fns/locale";
  import Navbar from '../../components/Start/Navbar.svelte';
  import axios from '../../libs/axios';

  //const random = false;
  //const localesArray = Object.keys(locales).map((i) => locales[i]);
  //const locale = random
  //  ? localesArray[Math.floor(Math.random() * localesArray.length)]
  //  : undefined;

  const singlePicker = false;
  let startDate = new Date();
  let endDate = new Date();

  // let startDate = singlePicker
  //  ? startOfDay(new Date())
  //   : startOfWeek(new Date());
  //let endDate = singlePicker ? startDate : endOfWeek(new Date());

  //function onApply(events) {
  //  startDate = events.detail.startDate;
  //  endDate = events.detail.endDate;
  //}

  // function onSubmit() {
  // console.log("onSubmit");
  // }

  async function isLinkSent() {
    const response = await axios.get('/api/postbox');
    console.log(response.data);

    if (response.data.results[0].is_link_sent) {
      navigate('/sending/end');
    }
  }

  const storedStartDate = localStorage.getItem('letter_start_date');
  const storedEndDate = localStorage.getItem('letter_end_date');

  if (storedStartDate && storedEndDate) {
    startDate = new Date(storedStartDate);
    endDate = new Date(storedEndDate);
  } else {
    startDate = singlePicker ? startOfDay(new Date()) : startOfWeek(new Date());
    endDate = singlePicker ? startDate : endOfWeek(new Date());
  }

  function onApply(events) {
    startDate = events.detail.startDate;
    endDate = events.detail.endDate;
  }

  let clickHelp;
  let clickCount = 0;

  const showHelp = () => {
    clickHelp = true;
  };

  const hideHelp = () => {
    clickHelp = false;
  };

  const handleHelp = () => {
    clickCount++;
    if (clickCount === 2) {
      hideHelp();
      clickCount = 0;
    } else {
      showHelp();
    }
  };

  const handleNext = () => {
    let changedstartdate =
      startDate.getFullYear() +
      '-' +
      (startDate.getMonth() + 1) +
      '-' +
      startDate.getDate();

    let changedenddate =
      endDate.getFullYear() +
      '-' +
      (endDate.getMonth() + 1) +
      '-' +
      endDate.getDate();

    localStorage.setItem('letter_start_date', changedstartdate);
    localStorage.setItem('letter_end_date', changedenddate);
    console.log(changedstartdate, changedenddate);
    navigate('/start/time');
  };
  isLinkSent();
</script>

<body class="start-ui">
  <Navbar pageNumber={1} pageRoute={'/'} />
  <div class="text-section">
    <h3 class="title">어느 기간동안<br />편지를 전달할까요?</h3>
  </div>
  <div class="picker-section">
    <SDateRangePicker {startDate} {endDate} on:apply={onApply} />
  </div>
  <div class="tip-container">
    <div class="help-section" id="help-section" on:click={handleHelp}>
      <Help width={16} height={16} color={'#C7C8D0'} />
      <span>잠깐, 수료 날짜를 잘 모른다면?</span>
    </div>
    {#if clickHelp}
      <div class="tip-section" id="tip-section">
        <div class="tip-wrapper">
          <p>
            육군(논산훈련소): 입소일 + 5주 + 1일, 보통 화요일에 수료<br />
            육군(신교대): 입소일 + 5주 + 1일, 보통 수요일에 수료<br />
            육군(사회복무요원): 입소일로부터 3주, 보통 목요일에 수료<br />
            해군: 보통 5주차 금요일<br />
            공군: 보통 5주차 금요일<br />
            해병대: 보통 7주차 목요일<br />
            <br />
            ex. 육군(논산훈련소): 5월 22일(월) 입대 -> 6월 27일(화) 수료<br />
            ex. 해군: 4월 24일(월) 입대 -> 6월 2일(금) 수료<br />
            <br />
            *<span
              >위의 예시들은 일반적인 경우이며, 모든 수료식 일정은 상황마다,
              부대마다 변경될 수 있어요.</span
            > 따라서 입소하는 훈련소 홈페이지에서 확인하는 것이 더 정확해요.
          </p>
        </div>
      </div>
    {/if}
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">다음</Button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    margin: 44px 40px 18px 40px;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    line-height: 150%;
    text-align: left;
    letter-spacing: -2%;
  }

  .picker-section {
    position: absolute;
    display: flex;
    left: 40px;
    right: 40px;
    justify-content: center;
    z-index: 9;
  }

  .tip-container {
    position: relative;
    margin-top: 128px;
  }

  .help-section {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
  }

  .help-section span {
    margin-left: 6px;
    color: var(--secondary-200-color);
    font-weight: 500;
    font-family: 'Pretendard_Medium';
  }

  .tip-section {
    position: relative;
    display: flex;
    margin: 10px 40px 0 40px;
    justify-content: center;
    background-color: var(--secondary-25-color);
    border: none;
    border-radius: 12px;
    transition: bottom 0.3s ease-in-out;
  }

  .tip-wrapper {
    display: flex;
    padding: 2.8px 16px;
    align-items: center;
    justify-content: center;
    line-height: 102%;
    font-family: 'Pretendard_Regular';
  }

  .tip-section p {
    font-size: 10px;
    text-align: left;
    font-weight: 400;
    color: var(--black-color);
  }

  .tip-section span {
    font-weight: 500;
    font-family: 'Pretendard_Medium';
    color: var(--semantic-danger-color);
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 64px 40px 0px 40px;
    z-index: 1;
  }
</style>
