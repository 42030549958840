<script>
  import "../../../public/modal.css";

  export let goBackAction;
  export let dateTitle;
  export let completeAction;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="header-container">
  <div class="header-wrapper">
      <div class="prev-button" on:click={goBackAction}>
        <div class="arrow-icon" alt=""/>
      </div>
      <div class="text-wrapper-black-font"><p>{dateTitle}</p></div>
      <div class="complete-btn" on:click={completeAction}>완료</div>
  </div>
</div>

<style>
  .header-container {
    top: 0px;
    margin-top: 12px;
  }
  .header-wrapper {
    display: flex;
    height: 36px;
    margin: 0 22px;
    justify-content: space-between;
    align-items: center;
  }
  .complete-btn {
    width: 36px; /* ? */
    line-height: 24px;
    font-family: "Sebang_Regular";
    font-weight: 400;
    font-size: 15px;
    color: #6c63eb;
  }
  p {
    font-family: "Sebang_Regular";
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    text-align: center;
  }
  .text-wrapper-black-font p {
    color: #070c15;
  }
  .arrow-icon {
    width: 28px;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/image/arrow-prev-icon.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .arrow-icon-white {
    width: 28px;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/image/arrow-prev-icon-white.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
</style>
