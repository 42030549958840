export function getLetterDateTitle(letterDate) {
  const date = new Date(letterDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();

  return `${year}년 ${month}월 ${day}일`;
}

export function getLetterCoverType(letterId) {
  const type = Math.floor(letterId.charCodeAt(0) % 9) + 1; // letterId 첫 글자 ASCII 코드값으로 1~9 난수 생성
  return 'cover' + type;
}

export function getLetterCoverImagePath(cover) {
  let imagePath = '';

  if (cover === 'cover1') {
    imagePath = '/assets/image/letter-cover/letter-cover-1.png';
  } else if (cover === 'cover2') {
    imagePath = '/assets/image/letter-cover/letter-cover-2.png';
  } else if (cover === 'cover3') {
    imagePath = '/assets/image/letter-cover/letter-cover-3.png';
  } else if (cover === 'cover4') {
    imagePath = '/assets/image/letter-cover/letter-cover-4.png';
  } else if (cover === 'cover5') {
    imagePath = '/assets/image/letter-cover/letter-cover-5.png';
  } else if (cover === 'cover6') {
    imagePath = '/assets/image/letter-cover/letter-cover-6.png';
  } else if (cover === 'cover7') {
    imagePath = '/assets/image/letter-cover/letter-cover-7.png';
  } else if (cover === 'cover8') {
    imagePath = '/assets/image/letter-cover/letter-cover-8.png';
  } else {
    imagePath = '/assets/image/letter-cover/letter-cover-9.png';
  }
  return imagePath;
}
