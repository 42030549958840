<script>
  import { onMount } from 'svelte';
  import axios from '../../libs/axios';
  import LetterPaper from './LetterPaper.svelte';
  import { errorHandler } from '../../utils/errorHandler';

  export let mode;
  export let id;
  export let letterData = {};
  export let file;
  export let pageFor;

  const getLetterData = async () => {
    try {
      let response = {};

      /* 편지 작성 페이지 또는 me 페이지 : 발신자측 API
      lover 페이지 내 편지 조회 페이지 : 수신자측 API 호출 */
      if (mode == 'WRITE' || pageFor == 'me') {
        response = await axios.get(`api/letter/${id}`);
        letterData = response.data;
      } else if (mode == 'READ' || pageFor == 'lover') {
        response = await axios.get(`api/rcpt/letter/${id}`);
        letterData = response.data;
      }

      if (letterData.body == null) {
        letterData.body = '';
      }

      if (letterData.attachments.length > 0) {
        file = letterData.attachments[0];
      }

      return response;
    } catch (error) {
      errorHandler(error);
    }
  };
  let promise = getLetterData();

  onMount(async () => {
    getLetterData();
  });
</script>

{#await promise}
  <br />
{:then}
  <LetterPaper
    on:body
    {mode}
    content={letterData.body}
    file={letterData.attachments[0]}
    paperType="letter-A"
  />
{/await}
