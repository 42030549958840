<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import axios from '../../libs/axios';
  import { createEventDispatcher } from 'svelte';
  import { errorHandler } from '../../utils/errorHandler';

  import LetterNavbar from '../../components/Letter/LetterNavbar.svelte';
  import Letter from '../../components/Letter/Letter.svelte';
  import Reply from '../../components/Letter/Reply.svelte';
  import FileUploader from '../../components/Letter/FileUploader.svelte';
  import Tooltip from '../../components/Tooltip.svelte';
  import ProgressModal from '../../components/ProgressModal.svelte';
  import SuccessModal from '../../components/SuccessModal.svelte';

  export let letterId = '';
  let replyId = '';
  let postboxId = '';
  let replyData = '';

  let dateTitle = '';
  let body = '';

  let showReply = false; // reply id 받아오기 전 답장 컴포넌트 생성 방지

  let checkingModal;
  let successModal;
  let warningModal;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    getReplyData();
  });

  async function getReplyData() {
    try {
      const letterDataResponse = await axios.get(`api/rcpt/letter/${letterId}`);
      replyId = letterDataResponse.data.comments[0].id;
      postboxId = letterDataResponse.data.postbox;

      const date = new Date(letterDataResponse.data.date);
      const month = date.toLocaleString('ko-KR', { month: 'long' });
      const day = date.getDate();
      dateTitle = `${month} ${day}일 편지 답장`;

      const replyDataResponse = await axios.get(`api/rcpt/comment/${replyId}`);
      replyData = replyDataResponse.data;
      body = replyData.body;
      showReply = true;
    } catch (error) {
      errorHandler(error);
    }
  }

  const goBackAction = () => {
    if (postboxId) {
      navigate(`/lover/letterlist/${postboxId}`);
    }
  };

  const handleNext = () => {
    if (postboxId) {
      navigate(`/lover/letterlist/${postboxId}`);
    }
  };

  const handleReplyBody = (event) => {
    body = event.detail;
    console.log(body);
  };

  async function writeReply() {
    console.log(body);
    checkingModal.hide();
    try {
      const response = await axios.put(`api/rcpt/comment/${replyId}`, {
        body: body,
      });

      if (response.status === 200) {
        console.log('답장 쓰기 성공');
      }
    } catch (error) {
      errorHandler(error);
    }
    successModal.show();
  }

  function handleFileUploadEvent(event) {
    // 신규 파일 첨부 및 첨부파일 삭제 이벤트 감지
    dispatch('fileChangeEvent');
    getReplyData();
  }
</script>

<body class="readletter-ui">
  <LetterNavbar
    goBackAction={() => warningModal.show()}
    {dateTitle}
    completeAction={() => checkingModal.show()}
  />
  <Letter mode="READ" id={letterId} />
  {#if showReply}
    <Reply mode="WRITE" id={replyId} {replyData} on:body={handleReplyBody} />
  {/if}
  <Tooltip
    message="최대 한 개의 첨부 파일을(사진, 음성)을 편지에 담을 수 있어요!"
  />
  {#if replyId}
    <FileUploader
      to="REPLY"
      id={replyId}
      on:fileChangeEvent={handleFileUploadEvent}
    />
  {/if}
  <div style="width:100%; height: 120px" />
</body>

<ProgressModal bind:this={checkingModal}>
  <div class="modal-title">답장을 보내시겠어요?</div>
  <div class="modal-text">
    편지를 부치고 나면 다시 수정하거나 삭제할<br />수 없어요. 답장을
    부치시겠어요?
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={writeReply}>답장 부치기</button>
    <button class="gray-btn" on:click={() => checkingModal.hide()}
      >다시 한 번 확인할래요</button
    >
  </div>
</ProgressModal>

<SuccessModal bind:this={successModal}>
  <div class="modal-title">답장을 부쳤어요!</div>
  <div class="modal-text">
    보관된 편지를 누르면 답장을 함께<br />확인할 수 있어요.
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={handleNext}
      >보관된 편지 확인하기</button
    >
  </div>
</SuccessModal>

<ProgressModal bind:this={warningModal}>
  <div class="modal-title">정말 나가시겠어요?</div>
  <div class="modal-text">
    아직 답장이 저장되지 않았어요!<br />지금 나가면 지금까지 작성한 답장<br
    />내용을 잃게 돼요
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={() => warningModal.hide()}
      >다시 확인할래요</button
    >
    <button class="gray-btn" on:click={goBackAction}>그냥 나갈래요</button>
  </div>
</ProgressModal>
