<script>
  import '../../public/modal.css';
  let shown = false;

  export function show() {
    shown = true;
  }
  export function hide() {
    shown = false;
  }
</script>

<svelte:window
  on:keydown={(e) => {
    if (e.keyCode === 27) {
      hide();
    }
  }}
/>

{#if shown}
  <div class="modal-wrapper">
    <div class="modal-section">
      <div class="shine-moon-section">
        <img src="/assets/image/moon-icon-shine.svg" alt="" />
      </div>
      <slot />
    </div>
    <div class="modal-dimmed" />
  </div>
{/if}
