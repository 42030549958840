export const moonData = [
  {
    moonLogo: '🌑',
    moonTitle: '삭, 날짜 설정하기',
    moonInfo: '달의 우체통이 편지를 배달할 날짜,시간을 설정해요.',
  },
  {
    moonLogo: '🌒',
    moonTitle: '초승달, 우체통 만들기',
    moonInfo: '우리만 확인할 수 있는 우리만의 우체통을 만들어요.',
  },
  {
    moonLogo: '🌓',
    moonTitle: '상현달, 편지쓰기',
    moonInfo: '설정한 편지 배달일 전까지 미리 편지를 작성해요.',
  },
  {
    moonLogo: '🌔',
    moonTitle: '상현망, 편지 전달하기',
    moonInfo:
      '편지 작성이 완료되면 우체통 링크가 생성돼요. 링크를 상대방에게 미리 전달해주세요. 편지 배달일이 되면 사전에 설정한대로 편지 배달이 시작됩니다.',
  },
  {
    moonLogo: '🌕',
    moonTitle: '보름달, 답장 확인하기',
    moonInfo: '언제든지 상대방의 답장을 확인할 수 있어요.',
  },
];
