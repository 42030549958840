<script>
  import { navigate } from 'svelte-routing/src/history';
  import { onMount } from 'svelte';
  import axios from '../../libs/axios';
  import { createEventDispatcher } from 'svelte';
  import { errorHandler } from '../../utils/errorHandler';

  import Letter from '../../components/Letter/Letter.svelte';
  import FileUploader from '../../components/Letter/FileUploader.svelte';
  import Tooltip from '../../components/Tooltip.svelte';
  import LetterNavbar from '../../components/Letter/LetterNavbar.svelte';
  import CheckModal from '../../components/CheckModal.svelte';
  import SuccessModal from '../../components/SuccessModal.svelte';
  import ProgressModal from '../../components/ProgressModal.svelte';

  export let letterId = '';
  let letterData = '';
  let dateTitle = '';
  let tip = {};
  let body = '';

  let checkingModal;
  let successModal;
  let warningModal;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    getLetterData();
  });

  async function getLetterData() {
    try {
      const response = await axios.get(`api/letter/${letterId}`);
      letterData = response.data;
      tip = letterData.suggested_tip;
      body = letterData.body;

      const date = new Date(letterData.date);
      const month = date.toLocaleString('ko-KR', { month: 'long' });
      const day = date.getDate();
      const dayOfWeek = date.toLocaleString('ko-KR', { weekday: 'long' })[0];

      dateTitle = `${month} ${day}일 (${dayOfWeek}) 편지 작성`;
    } catch (error) {
      errorHandler(error);
    }
  }

  const goBackAction = () => {
    navigate('/main/letter');
  };

  const handleNext = () => {
    navigate('/main/letter');
  };

  function wrapLetter() {
    checkingModal.hide();
    writeLetter();
    successModal.show();
  }

  const handleLetterBody = (event) => {
    body = event.detail;
    console.log(body);
  };

  async function writeLetter() {
    console.log(body);
    try {
      const response = await axios.post(`api/letter/${letterId}`, {
        body: body,
      });

      if (response.status === 200) {
        console.log('편지 쓰기 성공');
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  function handleFileUploadEvent(event) {
    // 신규 파일 첨부 및 첨부파일 삭제 이벤트 감지
    dispatch('fileChangeEvent');
    getLetterData();
  }
</script>

<body class="writeletter-ui">
  {#if letterData}
    <LetterNavbar
      goBackAction={() => warningModal.show()}
      {dateTitle}
      completeAction={() => checkingModal.show()}
    />
    <div class="tip-section">
      <div class="tip-wrapper">
        <div class="tip-icon">
          {tip.emoji}
        </div>
        <div class="tip-content">
          {tip.content}
        </div>
      </div>
    </div>
    <Letter
      mode="WRITE"
      id={letterId}
      {letterData}
      on:body={handleLetterBody}
    />
    <Tooltip
      message="최대 한 개의 첨부 파일을(사진, 음성)을 편지에 담을 수 있어요!"
    />
    <FileUploader
      to="LETTER"
      id={letterId}
      on:fileChangeEvent={handleFileUploadEvent}
    />
    <div style="width:100%; height: 400px" />
  {/if}
</body>

<CheckModal bind:this={checkingModal}>
  <div class="modal-title">편지를 포장하시겠어요?</div>
  <div class="modal-text">
    메인 화면에서 편지를 부치기 전까지는<br />언제든지 편지를 꺼내 고칠 수
    있어요.
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={wrapLetter}>편지 포장하기</button>
  </div>
</CheckModal>

<SuccessModal bind:this={successModal}>
  <div class="modal-title">편지를 포장했어요!</div>
  <div class="modal-text">
    포장된 편지는 편지 배달 전까지<br />안전하게 보관돼요.
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={handleNext}
      >메인화면으로 이동하기</button
    >
  </div>
</SuccessModal>

<ProgressModal bind:this={warningModal}>
  <div class="modal-title">정말 나가시겠어요?</div>
  <div class="modal-text">
    아직 편지가 저장되지 않았어요!<br />지금 나가면 지금까지 작성한 편지<br
    />내용을 잃게 돼요
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={() => warningModal.hide()}
      >다시 확인할래요</button
    >
    <button class="gray-btn" on:click={goBackAction}>그냥 나갈래요</button>
  </div>
</ProgressModal>

<style>
  .tip-section {
    padding: 0px 28px;
    margin-top: 24px;
    margin-bottom: 0.05%;
  }
  .tip-wrapper {
    width: 100%;
    min-height: 54px;
    display: flex;
    background-color: #f2f4f7;
    border-radius: 7px;
    justify-content: flex-start;
  }
  .tip-icon {
    display: flex;
    font-size: 24px;
    margin: 15px 8px 15px 15px;
  }
  .tip-content {
    display: flex;
    width: 274px;
    min-height: 31px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 134%;
    letter-spacing: 0px;
    text-align: left;
    color: #667085;
    align-items: center;
    word-break: keep-all;
  }
</style>
