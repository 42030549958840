<script>
  import { navigate } from 'svelte-routing';
  import Button from '../Button.svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';
  import {
    getLetterDateTitle,
    getLetterCoverType,
    getLetterCoverImagePath,
  } from './LetterCardSetter';

  export let postboxId;

  let unreadLetters = [];
  let unreadLetterCount = 0;

  getUnreadLetters();

  async function getUnreadLetters() {
    try {
      const response = await axios.get(`/api/rcpt/postbox/${postboxId}/letter`);

      unreadLetters = response.data.results
        .filter((item) => item.viewed_at === null)
        .map((item, index) => {
          return {
            ['letterId']: item.id,
            ['letterDate']: getLetterDateTitle(item.date),
            ['letterCoverImgPath']: getLetterCoverImagePath(
              getLetterCoverType(item.id)
            ),
          };
        });

      unreadLetterCount = unreadLetters.length;

      if (unreadLetterCount > 4) {
        // 안 읽은 편지 5개 이상이면 4개만 노출
        unreadLetters = unreadLetters.slice(0, 4);
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleLetter = (letterId) => {
    navigate(`/lover/letter/${letterId}`);
  };

  const handleNext = () => {
    window.location.href = `/lover/letterlist/${postboxId}`;
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="container">
  <div class="main-wrapper">
    <div class="head-section">
      <span class="head-title">
        새롭게 도착한 편지
        <span class="head-count">{unreadLetterCount}건</span>
      </span>
    </div>
    <div class="unread-letter-list">
      <div class="list">
        {#each unreadLetters as { letterId, letterDate, letterCoverImgPath }}
          <div class="unread-letter-item">
            <div class="unread-letter-wrapper">
              <img
                src={letterCoverImgPath}
                alt="unread-letter"
                class="unread-img"
                on:click={handleLetter(letterId)}
              />
            </div>
            <p class="unread-date">{letterDate}</p>
          </div>
        {/each}
      </div>
    </div>
    <div class="button-section" on:click={handleNext}>
      <button
        ><img src="/assets/image/letter-box.svg" alt="" />편지 보관함으로 가기</button
      >
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    bottom: 0px;
    transition: bottom 0.2s ease-in-out; /* 트랜지션 설정 */
    border: none;
    border-radius: 40px 40px 0 0;
    color: #000;
    background-color: #f9fafb;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .main-wrapper {
    position: fixed;
    position: relative;
    margin: auto;
    padding: 10px 28px 10px 28px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: 430px) {
    .container {
      max-width: 430px;
      min-height: 564px;
    }

    .main-wrapper {
      padding: 10px 48px 10px 48px;
    }
  }

  .head-section {
    text-align: left;
    margin-top: 32px;
  }

  .head-title {
    font-size: 18px;
    font-family: 'Pretendard_SemiBold';
    color: var(--black-color);
  }

  .head-count {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Pretendard_Bold';
    color: var(--primary-500-color);
    margin-left: 8px;
  }

  .unread-letter-list {
    position: relative;
    width: 100%;
    margin: auto;
    /* overflow: hidden; */
    margin-top: 24px;
    margin-bottom: 64px;
  }

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto 0;
  }

  .unread-letter-list .list {
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    /* display: flex; */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2개의 열을 생성합니다. */
    grid-gap: 16px; /* 각 항목 간의 간격을 조정합니다. */
    /* gap: 12px; */
    flex-direction: column;
  }

  .unread-letter-wrapper {
    background-color: var(--gray-200-color);
    padding: 30px;
    border-radius: 4px;
    flex-direction: row;
  }

  .unread-img {
    width: 100%;
  }

  .unread-date {
    font-size: 14px;
    color: var(--gray-600-color);
    font-family: 'Pretendard_Medium';
    text-align: center;
    margin-top: 8px;
  }

  .button-section {
    position: relative;
    display: flex;
    margin-bottom: 36px;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    width: 100%;
  }

  button {
    display: flex;
    background-color: #6c63eb;
    width: 100%;
    height: 58px;
    color: #fff;
    border: none;
    border-radius: 48px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
  }

  .button-section img {
    margin-right: 6px;
  }
</style>
