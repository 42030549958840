<script>
  import { navigate } from "svelte-routing";
  import {letterData} from "../../stores/letterData";
  import ReadLetterCard from "../../components/Lover/ReadLetterCard.svelte";
  import ReadNavbar from "../../components/Letter/ReadNavbar.svelte";
  import MainNavbar from "../../components/Lover/MainNavbar.svelte";

</script>

<body class="readletter-ui">
  <!-- <MainNavbar title="편지 확인하기" />  -->
  <!-- 라우팅 경로 수정 필요 -->
  <ReadNavbar />
  <div class="container">
    <div class="head-section">
      <span class="head-title">받은 편지들</span>
      </div>
    <div class="read-letter-list">
      <div class="list">
        {#each letterData as { letterCover, letterDate, isReply }}
          <ReadLetterCard {letterCover} {letterDate} {isReply} />
        {/each}
      </div>
    </div>
  </div>
</body>

<style>
  .container {
    margin: auto;
    margin: 12px 28px 0 28px;
    justify-content: center;
    z-index: 9;
  }

  .head-section {
    text-align: left;
  }

  .head-title {
    font-size: 18px;
    font-family: "Pretendard_SemiBold";
    color: var(--black-color);
  }

  .read-letter-list {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .list {
    position: relative;
    align-items: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
</style>