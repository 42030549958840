<script>
  import NumPad from "./NumPad.svelte";
  export let isVisible = false;
  export let postboxId;
</script>

<div class="container" class:visible={isVisible}>
  <div class="wrapper">
    <div class="text-section">
      <h3 class="bs-title">
        달의 우체통을 열기 위해서는<br />비밀코드가 필요해요!
      </h3>
      <p class="bs-hint">hint. 우리가 처음 사귄 날은? (YY/MM/DD)</p>
    </div>
    <div class="code-section">
      <NumPad {postboxId} />
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    width: 100%;
    min-height: 564px;
    bottom: 0px;
    transition: bottom 0.2s ease-in-out; /* 트랜지션 설정 */
    border: none;
    border-radius: 40px 40px 0 0;
    background-color: #f8fafe;
    align-items: center;
    justify-content: center;
    padding: auto;
    overflow-x: hidden;
    z-index: 2;
  }

  .visible {
    bottom: 0; /* 바텀 시트가 나타날 때의 위치 */
  }

  .wrapper {
    position: relative;
    margin: 0 40px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: 430px) {
    .container {
      max-width: 430px;
      min-height: 564px;
    }

    .wrapper {
      margin: 0 64px;
    }
  }

  .text-section {
    display: flex;
    margin-top: 36px;
    flex-direction: column;
  }

  .bs-title {
    display: flex;
    font-size: 22px;
    font-weight: 700;
    font-family: "Pretendard_Bold";
    line-height: 150%;
    text-align: left;
    margin-left: 0;
    margin-bottom: 0px;
    color: var(--black-color);
  }

  .bs-hint {
    display: flex;
    font-size: 15px;
    font-weight: 400;
    font-family: "Pretendard_Regular";
    color: var(--gray-500-color);
  }

  .code-section {
    position: relative;
    margin: auto 0;
    margin-top: 32px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
</style>
