<script>
  import { createEventDispatcher } from "svelte";
  import ChevronLeft from "svelte-material-icons/ChevronLeft.svelte";
  import ChevronRight from "svelte-material-icons/ChevronRight.svelte";
  import {
    addMonths,
    differenceInCalendarMonths,
    differenceInCalendarYears,
    isAfter,
    isBefore,
    isSameMonth,
    subMonths,
    isSameYear,
    isWithinInterval,
  } from "date-fns";
  import { buildMonths, buildYears, localeFormat } from "../utils";

  export let month;
  export let monthFormat;
  export let monthDropdown;
  export let maxDate;
  export let minDate;
  export let nextIcon;
  export let prevIcon;
  export let yearDropdown;

  const disptachEvent = createEventDispatcher();

  $: selectedMonth = {
    value: month,
    text: localeFormat(month, monthFormat),
  };
  $: selectedYear = { value: month, text: localeFormat(month, "yyyy") };
  $: prevMonth = subMonths(month, 1);
  $: nextMonth = addMonths(month, 1);
  $: isMaxDate = isAfter(month, maxDate) || isSameMonth(month, maxDate);
  $: isMinDate = isBefore(month, minDate) || isSameMonth(month, minDate);
  $: months = buildMonths({ month, monthFormat });
  $: years = buildYears({ minDate, maxDate });
  $: nextBtnDisabled = isSameMonth(month, maxDate) || isAfter(month, maxDate);
  $: prevBtnDisabled = isSameMonth(month, minDate) || isBefore(month, minDate);

  $: isOptionDisabled = (mo) =>
    isBefore(mo, minDate) ||
    (!isSameMonth(mo, minDate) && isAfter(mo, maxDate));
</script>

<div>
  <span>
    <small>{localeFormat(month, "yyyy")}년</small>
    <small>{localeFormat(month, "MMMM")}</small>
  </span>
  <div class="button-container">
    <button
      class="select"
      aria-disabled={prevBtnDisabled}
      disabled={prevBtnDisabled}
      on:click={() => disptachEvent("prevMonth")}
      aria-label={`Previous month, ${localeFormat(prevMonth, "MMMM yyyy")}`}
    >
      <ChevronLeft width={20} height={20} />
    </button>
    <button
      class="select"
      aria-disabled={nextBtnDisabled}
      disabled={nextBtnDisabled}
      on:click={() => disptachEvent("nextMonth")}
      aria-label={`Next month, ${localeFormat(nextMonth, "MMMM yyyy")}`}
    >
      <ChevronRight width={20} height={20} />
    </button>
  </div>
</div>

<style>
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  small {
    font-family: "Pretendard_ExtraBold";
    font-weight: 800;
    font-size: 14px;
    color: #fff;
  }

  span {
    margin-left: 10px;
  }

  button {
    border: none;
    background-color: transparent;
    color: #fff;
  }
</style>
