<script>
    export let message;
    let visible = true;

    window.addEventListener('click', () => {
        visible = false;
    });
</script>

{#if visible}
<div class="tooltip-container">
    <div class="tooltip-wrapper">
        <div class = "tooltip-message">
            {message}
        </div>
    </div>
</div>
{/if}

<style>
    .tooltip-container {
        width: 100%;
        bottom: 75px;
        position: fixed;
        /* left: 30px; */
        position: fixed;
        text-align: center;
        align-items: center;
        z-index: 9998;
    }
    .tooltip-wrapper {
        width: 330px;
        height: 36px;
        margin-left: 20px;
        line-height: 36px;
        background-color: #8A82EF;
        border-radius: 7px;
        opacity: 90%;
    }
    .tooltip-wrapper:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top-color: #8A82EF;
        border-bottom: 0;
        margin-left: -40px; /* 말풍선 꼬리 좌우 위치 */
        margin-bottom: -10px; /* 말풍선 꼬리 상하 위치 */
    }
    .tooltip-message {
        display: inline-block; 
        color: white;
        font-family: Pretendard; /* 수정 필요 */
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.02em;
        text-align: left;
    }
    
</style>