<script>
    export let navTitle = ""
    export let handlePrev;
  </script>
  
  <div class="header-container">
    <div class="header-wrapper">
      <div class="prev-button" on:click={handlePrev}>
        <div class="arrow-icon" />
      </div>
      <div class="text-wrapper"><p>{navTitle}</p></div>
    </div>
  </div>
  
  <style>
    .header-container {
      top: 0px;
      margin-top: 18px;
    }
  
    .header-wrapper {
      display: flex;
      height: 36px;
      margin: 0 28px;
      align-items: center;
      z-index: 9;
    }
  
    .arrow-icon {
      width: 32px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("/assets/image/arrow-prev-icon.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  
    .text-wrapper {
      width: 86%;
    }
  
    p {
      font-family: "Sebang_Regular";
      font-weight: 400;
      font-size: 16px;
      color: var(--black-color);
      text-align: center;
    }
  </style>
  