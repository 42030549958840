<script>
  import { navigate } from "svelte-routing";
  import Button from "../../components/Button.svelte";
  import axios from "../../libs/axios"
  import { errorHandler } from "../../utils/errorHandler";

  let title = "";
  let titleLength = title.length;

  // dalchetong.com/share/postbox_id

  async function gettitle() {
    try {
      const response_1 = await axios.get("/api/postbox");
      console.log("백엔드 응답1:", response_1.data);

      const postboxId = response_1.data.results[0].id;

      const response_2 = await axios.get(`/api/rcpt/postbox/${postboxId}`);
      console.log("백엔드 응답2:", response_2.data);
      title = response_2.data.title
    
    } catch (error) {
        errorHandler(error);
    }
  };

  const handleNext = () => {
    navigate("/sender/list");
  };

  gettitle()

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<body class="onboarding-end-ui">
  <div class="text-section">
    <div class="underline-section">
      <h3 class="title">{title}를 위한 달의 우체통</h3>
      <div
        class="main-title-underline"
        style="width: {titleLength * 10 + 260}px;"
      />
    </div>
    <p class="onboarding">모든 편지 배달이 완료되었어요 :)</p>
  </div>

  <div class="mailbox-container">
    <img src="/assets/image/mailbox/mailbox-basic-shadow-size.png" alt="">
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">보관된 편지들 보러가기</Button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 44px;
    margin-bottom: 72px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 28px;
    font-family: "Sebang_Regular";
    margin-bottom: 10px;
  }

  .onboarding {
    font-size: 14px;
    font-family: "Pretendard_Regular";
    line-height: 150%;
  }

  .underline-section {
    width: 100%;
    position: relative;
    display: flex;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .main-title-underline {
    position: absolute;
    top: 52px;
    height: 12px;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: .16;
    background-color: #fff;
    z-index: 9;
  }


  .mailbox-container {
    /* height: 380px; */
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    /* position: fixed;
    display: flex;
    top: 596px;
    left: 40px;
    right: 40px;
    justify-content: center; */
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 40px 24px 40px;
  }
</style>
