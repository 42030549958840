export function isLetter({ date, letterlist, notEmptyLetterlist, startDate, endDate }) {

    //let datelist = [];
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + 1);
    const currentDateStr = currentDate.toISOString().split('T')[0];

    startDate.setDate(startDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 1);

    //while (startDate <= endDate) {
    //    datelist.push(startDate.toISOString().split('T')[0]);
    //    startDate.setDate(startDate.getDate() + 1);
    //}

    console.log("해당 날짜", currentDateStr)
    console.log("빈편지가 아닌 날짜", notEmptyLetterlist)
    console.log("편지 리스트", letterlist)
    //console.log("편지배달 기간", datelist)

    if (letterlist.includes(currentDateStr)) {
        if (notEmptyLetterlist.includes(currentDateStr)) {
            return 1
        }
        return 0
    } else {
        return -1
    }
}
