// import { writable } from 'svelte/store';

// // 초기값
// export const letterCheck = writable({
//     selectDate: new Date(),
//     isLetter: false,
//     isWritten: false,
// })

// // 값 업데이트
// export function setLetterCheck(daySelectDate, dayIsLetter, dayIsWritten) {
//     letterCheck.set({
//         selectDate: daySelectDate,
//         isLetter: dayIsLetter,
//         isWritten: dayIsWritten
//     })

// console.log('Check Store:', daySelectDate, dayIsLetter, dayIsWritten)
// }

import { writable } from 'svelte/store';

// 초기값
const initialData = [];

// writable store 생성
export const letterCheck = writable(initialData);

// 값을 배열로 추가하는 함수
export function setLetterCheck(daySelectDate, dayIsLetter, dayIsWritten) {
  letterCheck.update((existingData) => {
    return [
      ...existingData,
      {
        selectDate: daySelectDate,
        isLetter: dayIsLetter,
        isWritten: dayIsWritten,
      },
    ];
  });
  console.log(
    'Check Store:',
    daySelectDate,
    dayIsLetter,
    dayIsWritten,
    checkLetterStatus(daySelectDate)
  );
}

// 특정 날짜에 해당하는 isLetter 값을 찾는 함수
export function getIsLetterByDate(targetDate) {
  let result = null;
  letterCheck.subscribe((data) => {
    const foundData = data.find((item) => item.selectDate === targetDate);
    if (foundData) {
      result = foundData.isLetter;
    }
  });
  return result;
}

// 특정 날짜에 해당하는 isWritten 값을 찾는 함수
export function getIsWrittenByDate(targetDate) {
  let result = null;
  letterCheck.subscribe((data) => {
    const foundData = data.find((item) => item.selectDate === targetDate);
    if (foundData) {
      result = foundData.isWritten;
    }
  });
  return result;
}

export function checkLetterStatus(targetDate) {
  const isLetter = getIsLetterByDate(targetDate);
  const isWritten = getIsWrittenByDate(targetDate);

  if (isLetter && isWritten) {
    return 1;
  } else if (isLetter && !isWritten) {
    return 2;
  } else {
    return 0;
  }
}
