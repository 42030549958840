<script>
  import { navigate } from 'svelte-routing';

  export let pageNumber = 1;
  export let pageRoute = '';

  const handleNext = () => {
    if (pageNumber > 1) {
      navigate(`${pageRoute}`);
    } else {
      navigate('/onboarding/end');
    }
  };
</script>

<div class="header-container">
  <div class="header-wrapper">
    <div class="prev-button" on:click={handleNext}>
      <div class="arrow-icon" />
    </div>
    <p>{pageNumber} / 4</p>
  </div>
</div>

<style>
  .header-container {
    top: 0px;
    margin-top: 30px;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin: 0 24px;
  }

  .prev-button {
    color: #fff;
  }

  .arrow-icon {
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/arrow-white-prev-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  p {
    font-family: 'Pretendard_Medium';
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-300-color);
  }
</style>
