import { isAfter, isBefore } from 'date-fns';

export function isDisabled({ date, startDisabledDate, endDisabledDate }) {
  //  console.log(date, startDisabledDate, endDisabledDate)

  if (isBefore(date, startDisabledDate)) {
    return true;
  }

  if (isAfter(date, endDisabledDate)) {
    return true;
  }

  return false;
}
