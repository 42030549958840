<script>
  import { navigate } from 'svelte-routing';
  export let remainDay;
  export let dDayPassed = false;

  const handleNext = () => {
    navigate('/main');
  };
</script>

<div class="header-container">
  <div class="header-wrapper">
    <div class="prev-button" on:click={handleNext}>
      <div class="arrow-icon" />
    </div>
    <div class="text-wrapper">
      {#if dDayPassed}
        <p>편지 배달일로부터 D+{remainDay}</p>
        <div class="main-title-underline" />
      {:else}
        <p>편지 배달일까지 D-{remainDay}</p>
        <div class="main-title-underline" />
      {/if}
    </div>
  </div>
</div>

<style>
  .header-container {
    top: 0px;
    margin-top: 32px;
    z-index: 9;
  }

  .header-wrapper {
    display: flex;
    height: 24px;
    margin: 0 24px;
    align-items: center;
  }

  .arrow-icon {
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/arrow-white-prev-icon.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text-wrapper {
    width: 86%;
    position: relative;
    display: flex;
    /* margin: auto 0; */
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  p {
    font-family: 'Sebang_Regular';
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-align: center;
  }

  .main-title-underline {
    position: absolute;
    top: 24px;
    width: 168px;
    height: 12px;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: 0.16;
    background-color: #fff;
    z-index: 9;
  }
</style>
