<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import Swiper from 'swiper';
  import { Pagination } from 'swiper/modules';

  onMount(() => {
    Swiper.use([Pagination]); // 모듈 등록

    let swiper = new Swiper('.swiper-container', {
      pagination: {
        clickable: true,
        el: `swiper-pagination-bullet swiper-pagination-bullet-active`,
      },
    });
  });

  const SocialKakao = () => {
    const rest_api_key = 'c00ea8fc0b9667dab6c066a953dd8d21'; // REST API KEY
    const currentURL = new URL(window.location.href);
    const redirect_uri = `${currentURL.protocol}//${currentURL.host}/auth`; // Redirect URI

    console.log(currentURL, currentURL.protocol, currentURL.host, redirect_uri);
    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
  };

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<body class="start-ui">
  <swiper-container pagination="true">
    <swiper-slide>
      <div class="text-section">
        <h3 class="title">우리만의 우체통을 준비해요</h3>
        <p class="onboarding">
          미리 설정한 기간과 시간에 따라, 오직 서로만<br />확인할 수 있는
          편지들이 배달돼요
        </p>
      </div>
      <div class="select-container">
        <div class="mailbox-moon">
        <img src="/assets/image/mailbox/mailbox-with-moon-size.png" alt="">
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="text-section">
        <h3 class="title">쉽고 재미있게 편지 쓰기</h3>
        <p class="onboarding">
          부담없이 편지를 쓸 수 있도록, 매일 상대가
          <br />즐거워할 다양한 주제를 추천해줘요.
        </p>
      </div>
      <div class="select-container">
        <div class="mailbox-letter">
        <img src="/assets/image/mailbox/mailbox-with-letter-size.png" alt="">

        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="text-section">
        <h3 class="title">답장이 사라지면 섭섭하니까</h3>
        <p class="onboarding">
          3일간 접속하지 않으면 메시지가 사라지는 메신저와 달리,<br />
          달의 우체통에 남긴 답장은 사라지지 않아요.
        </p>
      </div>
      <div class="select-container">
        <div class="mailbox-flower">
          <img src="/assets/image/mailbox/mailbox-with-flower-size.png" alt="">
        </div>
      </div>
    </swiper-slide>
  </swiper-container>

  <div class="button-section" on:click={SocialKakao}>
    <Button class="kakao-ui">
      <div class="kakao-logo" />
      카카오계정으로 시작하기</Button
    >
  </div>

  <div class="tip-container">
    시작할 경우, 달의 우체통의 <a
      href="https://misae.notion.site/539191bc947f49e494787e50f0d5a5ae?pvs=4"
      ><span>서비스 이용약관</span></a
    >과<br />
    <a href="https://misae.notion.site/71b33659a4454e0a9e4cd1955d0efbce?pvs=4"
      ><span>개인정보 보호정책</span></a
    >에 동의하게 됩니다.
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    /* top: 76px; */
    top: 56px;
    /* margin-bottom: 72px; */
    margin-bottom: 36px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.56px;
    line-height: 150%;
    margin: 0 auto;
  }

  .onboarding {
    color: var(--gray-300-color);
    font-size: 14px;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
  }

  .select-container {
    width: 100%;
    /* height: 348px; */
    height: calc(50 * var(--vh, 1vh) + 16px);
    margin: auto 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-moon {
    height: calc(100% - 36px);
    right: 16px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-moon img {
    width: auto;
    height: calc(100% - 72px);
  }

  .mailbox-letter {
    height: calc(100% - 36px);
    left: 0px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mailbox-letter img {
    width: auto;
    height: calc(100% - 108px);
  }
  
  .mailbox-flower {
    height: calc(100% - 36px);
    left: 0px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-flower img {
    width: auto;
    height: calc(100% - 128px);
  }

  /* Custom Swiper Pagination */

  :root {
    --swiper-pagination-color: #fcfcfd;
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #575b7e;
    --swiper-pagination-bullet-inactive-opacity: 0.2;
    --swiper-pagination-bullet-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    --swiper-pagination-bullet-vertical-gap: 6px;
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center; /* 버튼 중앙 배치 */
    margin: 12px 40px 0px 40px;
  }

  .kakao-logo {
    background-image: url('/assets/image/icon/kakao-logo-size.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

  .tip-container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 36px;
    color: #8e90a7;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: var(--secondary-300-color);
    font-family: 'Pretendard_Regular';
  }

  .tip-container span {
    text-decoration: underline;
    color: var(--secondary-100-color);
    font-weight: 400;
    cursor: pointer;
  }
</style>
