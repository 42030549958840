<script>
  //   import { slide } from "svelte/transition";
  import { horizontalSlide } from './HorizontalSlide';
  export let isOpen;
  export function hide() {
    isOpen = false;
    // console.log("사이드바 파일: ", isOpen);
  }
</script>

{#if isOpen}
  <div
    class={isOpen ? 'sidebar open' : 'sidebar'}
    transition:horizontalSlide={{ axis: 'x', duration: 600, closing: !isOpen }}
  >
    <div class="logo-section">
      <img class="text-logo" src="/assets/image/icon/text-logo.png" />
    </div>
    <hr />
    <div class="text-section">
      <ul>
        <li>
          <a href="https://dalchetong.com">달의 우체통 홈페이지로 가기</a>
        </li>
        <li>
          <a href="https://misae.notion.site/0b20049052994693bfa3841a62a235e4"
            >달의 우체통 사용법</a
          >
        </li>
        <li>
          <a
            href="https://misae.notion.site/e3744848ea944e6998cb8d5718f87199?pvs=4"
          >
            자주 묻는 질문
          </a>
        </li>
        <li>
          <a href="mailto:help@dalchetong.com">문의하기</a>
        </li>
      </ul>
    </div>
    <a href="https://www.instagram.com/dalchetong_official">
      <div class="connect-section">
        <img
          class="icon/instagram-logo"
          src="/assets/image/icon/instagram-logo.png"
          alt=""
        />
        <span>공식 인스타그램 바로가기</span>
      </div>
    </a>
  </div>

  <div class="sidebar-dimmed" on:click={() => hide()} />
{/if}

<svelte:window
  on:keydown={(e) => {
    if (e.keyCode === 27) {
      hide();
    }
  }}
/>

<style>
  .sidebar {
    position: fixed;
    top: 0;
    left: -236px;
    width: 236px;
    height: 100%;
    padding: 48px 24px;
    background-color: #fff;
    color: var(--gray-700-color);
    transition: right 0.6s ease;
    z-index: 999;
  }

  .logo-section {
    position: relative;
    display: flex;
  }

  .logo-section img {
    height: 20px;
  }

  hr {
    border: 0.5px solid #eaecf0;
    margin: 24px 0 30px 0;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .text-section {
    position: relative;
    display: flex;
    margin-bottom: 40px;
  }

  .text-section ul {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: 18px;
  }

  .text-section li {
    font-size: 18px;
    font-family: 'Pretendard_Regular';
  }

  .text-section li a,
  .text-section li a:link,
  .text-section li a:visited,
  a {
    color: var(--gray-700-color);
  }

  .connect-section {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    border-radius: 14px;
    background-color: #eff1f4;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
  }

  .connect-section span {
    font-size: 16px;
    font-family: 'Pretendard_Regular';
  }

  .connect-section img {
    margin: 0 8px 0 16px;
    width: 28px;
    height: 28px;
  }

  .sidebar-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.45;
    z-index: 998;
  }

  .open {
    left: 0;
  }
</style>
