<script>
  import { afterUpdate, onMount, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let selected;
  export let data = 0;
  export let type;

  let position = selected ? -(selected - 1) * 50 : 0;
  let offset = 0;
  let dragging = false;

  let itemWrapper, previousY;

  onMount(() => {
    setPosition();
  });

  afterUpdate(() => {
    let selectedPosition = -(selected - 1) * 50;

    if (!dragging && position !== selectedPosition) {
      position = selectedPosition;
      setPosition();
    }
  });

  function onTimeChange(type, changedData) {
    dispatch("timeChange", {
      type,
      changedData,
    });
  }

  function setPosition() {
    let itemPosition = `
      will-change: 'transform';
      transition: transform ${Math.abs(offset) / 100 + 0.1}s;
      transform: translateY(${position}px);
    `;
    itemWrapper.style.cssText = itemPosition;
  }

  let onMouseDown = (event) => {
    previousY = event.touches ? event.touches[0].clientY : event.clientY;
    dragging = true;

    document.body.style.overflow = "hidden";
    event.preventDefault();

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("touchmove", onMouseMove);
    window.addEventListener("touchend", onMouseUp);
  };

  let onMouseMove = (event) => {
    document.body.style.overflow = "hidden";
    event.preventDefault();

    let clientY = event.touches ? event.touches[0].clientY : event.clientY;
    offset = clientY - previousY;

    let maxPosition = -data.length * 50;
    let _position = position + offset;
    position = Math.max(maxPosition, Math.min(50, _position));
    previousY = event.touches ? event.touches[0].clientY : event.clientY;
    setPosition();
  };

  let onMouseUp = (event) => {
    document.body.style.overflow = "hidden";
    event.preventDefault();

    let maxPosition = -(data.length - 1) * 50;
    let rounderPosition = Math.round((position + offset * 5) / 50) * 50;
    let finalPosition = Math.max(maxPosition, Math.min(0, rounderPosition));

    dragging = false;
    position = finalPosition;

    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
    window.removeEventListener("touchmove", onMouseMove);
    window.removeEventListener("touchend", onMouseUp);

    setPosition();
    onTimeChange(type, -(finalPosition-1) / 50);
  };
</script>

<div
  class="touch-time-wrapper"
  on:mousedown={onMouseDown}
  on:touchstart={onMouseDown}
>
  <ul bind:this={itemWrapper} class="touch-time-container">
    {#each data as item}
      <li>{item}</li>
    {/each}
  </ul>
</div>

<style>
  .touch-time-wrapper {
    position: relative;
    width: 88px;
    height: 50px;
    padding-left: -18px;
    border-top: 1px solid var(--svtt-bar-color, #8E90A7);
    border-bottom: 1px solid var(--svtt-bar-color, #8E90A7);
    border-radius: 0;
  }

  .touch-time-container {
    margin: 0;
    padding: 0;
  }

  .touch-time-wrapper:before,
  .touch-time-wrapper:after {
    content: "";
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    /* background-color: #838598; */
    opacity: 0.8;
    pointer-events: none;
    z-index: 1;
  }

  .touch-time-wrapper:before {
    top: -108px;
    width: 80px;
    height: 100px;
    opacity: .7;
    background-color: #14183C;
  }

  .touch-time-wrapper:after {
    bottom: -108px;
    width: 80px;
    height: 100px;
    opacity: .7;
    background-color: #14183C;
  }

  .touch-time-wrapper:active {
    color: #7a7895;
    opacity: 0.7;
    font-size: 20px;
  }

  .touch-time-container li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
</style>
