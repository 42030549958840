<script>
  import Switcher from './Switcher.svelte';

  const HOURS = new Array(12).fill(1).map((v, i) => v + i);
  const MINUTES = new Array(60).fill(0).map((v, i) => v + i);
  const MERIDIEM = ['AM', 'PM'];


  export let time = new Date();
  export let _time, selectedHour, selectedMeridiem, selectedMinute;
  export let visible = false;
  export let classes = '';

  let resetTime = (event) => {
    event.stopPropagation()
    time = new Date();
  }

  $: {
    _time = time.toLocaleTimeString('en-US', {timeStyle: 'short'});
    selectedHour = +time.toLocaleTimeString('en-us', {hour12:true, hour:'numeric'}).split(' ')[0];
    selectedMeridiem = time.getHours() < 12 ? 1 : 2;
    selectedMinute = time.getMinutes()+1;
  }

  let timeChanged = (event) => {
    let {type, changedData} = event.detail;
    let newTime = new Date();

    if (type === 'hours'){
      let thresholdHour = selectedMeridiem === 2 ? 13 : 1;
      newTime.setHours(changedData + thresholdHour);
      newTime.setMinutes(time.getMinutes())

    } else if (type === 'minutes'){
      newTime.setHours(time.getHours())
      newTime.setMinutes(changedData)

    } else if (type === 'meridiem'){
      let thresholdHour = ~~changedData ? 12 : ~11;
      newTime.setHours(time.getHours() + thresholdHour )
      newTime.setMinutes(time.getMinutes())
    }
    console.log('설정시간: ', newTime)
    // newTime.setMinutes(time.getMinutes()-1)
    // console.log('설정시간2: ', newTime)
    time = newTime;
  }
</script>

<!-- <input type="text" class='{classes}' readonly value={_time}> -->
  <div class="touch-time-popup" >
    <div>
      <div class="touch-time-wrapper">
          <div class='touch-time'>{_time}</div>
          <div class='touch-time-picker'>
            <Switcher type='hours' data={HOURS} selected={selectedHour} on:timeChange={timeChanged}/>
            <Switcher type='minutes' data={MINUTES} selected={selectedMinute} on:timeChange={timeChanged}/>
            <Switcher type='meridiem' data={MERIDIEM} selected={selectedMeridiem} on:timeChange={timeChanged}/>
          </div>
        <div class='touch-time-reset'>
          <button on:click={resetTime}>Reset</button>
          <button on:click={() => {visible = !visible}}>Ok</button>
        </div>
      </div>
    </div>
  </div>

  <style>
    .touch-time-popup { 
      position: absolute;
      top:20px;
      left:0;
      right:0;
      bottom:0;
      touch-action: pan-down;
    }

    .touch-time-popup > div {
      /* background: var(--svtt-popup-bg-color, white); */
      background-color: transparent;
      color: var(--svtt-popup-color, black);
      margin-top: 10vh;
      justify-content: center;
      border-radius: var(--svtt-popup-radius, 10px);
    }

    .touch-time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: var(--svtt-font-size, 18px);
      padding: 1.8rem;
      font-family: 'Pretendard_Medium' !important;
      font-weight: 500;
    }
    
    .touch-time-picker {
      display: flex;
      padding: 72px 28px;
      margin: 10px 10px;
      overflow: hidden;
      font-size: 20px;
      color: #fff;
      background-color: transparent;
    }
    
    .touch-time-reset > button {
      width: 100px;
      height: 30px;
      border-radius: 15px;
      border: var(--svtt-border, 1px solid grey);
      outline: none;
      color: var(--svtt-button-color, black);
      background-color: var(--svtt-button-bg-color, transparent);
      box-shadow: var(--svtt-button-box-shadow, none) ;
      font-weight: 300;
      display: none;
    }
    
    .touch-time-reset button:nth-child(1):active {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    
    /* 선택된 시간 */
    .touch-time {
      position: absolute;
      top: 560px;
      font-size: 36px;
      font-weight: 300;
      color: #7A7895;
      display: none;
    }
    
    </style>