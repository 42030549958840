export function calculateDDay(targetDate) {
    let timeDifference, dDayPassed;

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    if (targetDate < currentDate) {
      // 편지 배달일이 지난 경우
      dDayPassed = true;
      timeDifference = currentDate - targetDate;
    } else {
      timeDifference = targetDate - currentDate;
    }

    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    console.log(
      currentDate,
      targetDate,
      timeDifference,
      'D-Day까지 남은 일 수:',
      daysDifference
    );
    return [daysDifference, dDayPassed];
  }