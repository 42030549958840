import App from './App.svelte';
import '../public/font.css';

// console = {};
// console.log = function() {};
// console.warn = function() {};
// console.error = function() {};

var app = new App({
  target: document.body,
});

export default app;
