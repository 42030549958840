<script>
  /** @type {any} */
  export let value = "";
  export let id = "input" + Math.random().toString(36);
  export let ref = null;

  import { getContext, onMount } from "svelte";

  let type;
  let selectTextOnFocus;

  const ctx = getContext("Pincode");
  const unsubscribeType = ctx._type.subscribe((_type) => {
    type = _type;
  });
  const unsubscribeSelectTextOnFocus = ctx._selectTextOnFocus.subscribe(
    (_selectTextOnFocus) => {
      selectTextOnFocus = _selectTextOnFocus;
    }
  );
  const KEYBOARD = {
    CONTROL: "Control",
    COMMAND: "Meta",
    V: "v",
    TAB: "Tab",
    BACKSPACE: "Backspace",
  };

  let unsubscribe = undefined;
  let modifierKeyDown = false;

  onMount(() => {
    ctx.add(id, value);

    unsubscribe = ctx._valuesById.subscribe((_) => {
      value = _[id] || "";
    });

    return () => {
      ctx.remove(id);
      unsubscribe();
      unsubscribeType();
      unsubscribeSelectTextOnFocus();
    };
  });
</script>

<input
  bind:this={ref}
  {...$$restProps}
  type={type === "numeric" ? "number" : "text"}
  inputmode={type === "numeric" ? "numeric" : "text"}
  pattern={type === "numeric" ? "[0-9]{1}" : "^[a-zA-Z0-9]$"}
  maxlength="1"
  {value}
  on:focus
  on:focus={() => {
    if (selectTextOnFocus) ref.select();
  }}
  on:blur
  on:keyup={(e) => {
    console.log(e);
    e.preventDefault();
    if (e.key === KEYBOARD.BACKSPACE) {
      return ctx.clear(id);
    }
    if (/^[0-9]$/.test(e.key)) {
      ctx.update(id, e.key);
    }
  }}
  on:input={(e) => (e.target.value = e.target.value.slice(0, 1))}
/>

<style>
  input {
    width: 3rem;
    height: 3.6rem;
    padding: 0.5rem 0.8rem;
    margin: 0;
    border: none;
    border-radius: 0;
    text-align: center;
    background-color: var(--secondary-500-color);
  }

  input:focus {
    z-index: 1;
    border-right: 2.4px solid #fff;
  }

  input:not(:last-of-type) {
    /* border: 2.4px solid #acb0da; */
  }
</style>
