// 카카오톡 복사
export function shareKakao(postboxId) {
    const shareURL = `https://dalchetong.com/lover/start/${postboxId}`;
    const shareTitle = '똑똑, 당신만을 위한 우체통이 도착했어요';
    const shareDes =
      '어떤 선물같은 편지들이 기다리고 있을까요?\n지금 바로 달의 우체통을 열어보세요!';
    const shareImage = 'https://dalchetong.com/assets/image/shareKakao.png';

    const Kakao = window.Kakao;
    if (!Kakao.isInitialized()) {
      Kakao.init('d9ef90c29c44323e77756a667a3b2ffc');
    }
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: shareTitle,
        description: shareDes,
        imageUrl: shareImage,
        imageWidth: 3126,
        imageHeight: 4096,
        link: {
          mobileWebUrl: shareURL,
          webUrl: shareURL,
        },
      },
      buttons: [
        {
          title: '우체통 살펴보러 가기',
          link: {
            mobileWebUrl: shareURL,
            webUrl: shareURL,
          },
        },
      ],
    });
}

// 클립보드 복사
export function copyToClipboard(postboxId) {
    const shareURL = `https://dalchetong.com/lover/start/${postboxId}`;
    prompt('하단의 URL을 복사해주세요.', shareURL);
}
