<script>
  import Button from '../../components/Button.svelte';
  import BottomSheet from '../../components/Lover/PincodeBottomSheet.svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';

  export let postboxId;
  let isVisible = false;
  let isBottomSheetVisible = false;
  let title = '';

  let particle = '';

  $: particle = getParticle(title);

  function getParticle(text) {
    const trimmedText = text.trim();
    if (!trimmedText) return '를';

    const lastChar = trimmedText.charAt(trimmedText.length - 1);
    const lastCharCode = lastChar.charCodeAt(0);

    const isKoreanCharacter = lastCharCode >= 0xac00 && lastCharCode <= 0xd7a3;
    if (!isKoreanCharacter) return '를';

    const offset = lastCharCode - 0xac00;
    const finalConsonantIndex = offset % 28;

    return finalConsonantIndex > 0 ? '을' : '를';
  }

  // 사용자 정보 불러오기
  async function getTitle() {
    try {
      const response = await axios.get(`/api/rcpt/postbox/${postboxId}`);
      title = response.data.title;
    } catch (error) {
      errorHandler(error);
    }
  }

  function showBottomSheet() {
    isBottomSheetVisible = true;
  }

  function hideBottomSheet() {
    isBottomSheetVisible = false;
  }

  getTitle();

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<svelte:window
  on:keydown={(e) => {
    if (e.keyCode === 27) {
      hideBottomSheet();
    }
  }}
/>

<body class="onboarding-end-ui">
  <div class="text-section">
    <h3 class="title">
      {title}{particle} 위한<br />달의 우체통이 도착했어요!
    </h3>
  </div>
  <div class="mailbox-container">
    <img src="/assets/image/mailbox/mailbox-basic-shadow-size.png" alt="">
  </div>
  <div class="button-section" on:click={showBottomSheet}>
    <Button class="start-ui">우체통 살펴보기</Button>
  </div>

  {#if isBottomSheetVisible}
    <BottomSheet {isVisible} {postboxId} on:close={hideBottomSheet} />
    <div class="modal-dimmed" on:click={hideBottomSheet} />
  {/if}
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 38px;
    margin-bottom: 48px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 28px;
    font-family: 'Sebang_Regular';
    margin-bottom: 10px;
    line-height: 150%;
  }
  
  .mailbox-container {
    /* height: 380px; */
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    /* margin: 18px 40px 24px 40px; 하단으로 내림 */
    margin: 18px 40px 52px 40px;
  }

  .modal-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
  }
</style>
