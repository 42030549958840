<script>
  import { navigate } from 'svelte-routing';
  import Navbar from '../../components/Start/Navbar.svelte';
  import Button from '../../components/Button.svelte';
  import TimePicker from '../../components/Start/touch-time-picker/TimePicker.svelte';
  import axios from '../../libs/axios';

  async function isLinkSent() {
    const response = await axios.get('/api/postbox');
    console.log(response.data);

    if (response.data.results[0].is_link_sent) {
      navigate('/sending/end');
    }
  }

  let date = new Date();

  //const handleNext = () => {
  //  let time = date.getHours() + ":" + date.getMinutes() + ":00";
  //  localStorage.setItem("letter_send_time", time);
  //  console.log(time);
  //  navigate("/start/darling");
  //};

  let time;
  const storedTime = localStorage.getItem('letter_send_time');

  if (storedTime) {
    const timeArray = storedTime.split(':');
    const hour = timeArray[0];
    const minute = timeArray[1];
    date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hour,
      minute,
      0
    );
    console.log(time);
  } else {
    console.log(time);
  }

  const handleNext = () => {
    time = date.getHours() + ':' + date.getMinutes() + ':00';
    localStorage.setItem('letter_send_time', time);
    navigate('/start/darling');
  };
  isLinkSent();
</script>

<body class="start-ui">
  <Navbar pageNumber={2} pageRoute={'/start'} />
  <div class="text-section">
    <h3 class="title">매일 어떤 시간에<br />편지를 전달할까요?</h3>
  </div>
  <div class="select-container">
    <TimePicker bind:time={date} />
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">다음</Button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    margin: 44px 40px 24px 40px;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    line-height: 150%;
    text-align: left;
    letter-spacing: -2%;
  }

  .select-container {
    position: absolute;
    display: flex;
    top: 100px;
    left: 40px;
    right: 40px;
    align-items: center;
    justify-content: center;
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 360px 40px 24px 40px;
    z-index: 1;
  }

  :root {
    --svtt-bar-color: #8e90a7;
    --svtt-button-color: #fff;
    --svtt-popup-radius: 10px;
    --svtt-popup-color: #2e3147;
    --svtt-font-size: 14px;
    --svtt-button-bg-color: #2e3147;
    --svtt-border: none;
    --svtt-button-box-shadow: 0 1px 4px -2px #2e3147;
  }
</style>
