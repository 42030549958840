<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';

  import ReadNavbar from '../../components/Letter/ReadNavbar.svelte';
  import Button from '../../components/Button.svelte';
  import Letter from '../../components/Letter/Letter.svelte';
  import Reply from '../../components/Letter/Reply.svelte';

  export let letterId;
  let postboxId;
  const currentURL = window.location.pathname;
  const pageFor = currentURL.includes('/lover/')
    ? 'lover'
    : currentURL.includes('/me/')
      ? 'me'
      : '';

  let dateTitle = '';
  let isThereReply; // 답장 객체 생성 여부
  let isValidReply; // 내용 있는 답장인지 (빈 편지면 false)
  let replyId;

  onMount(async () => {
    let response;
    try {
      if (pageFor == 'lover') {
        response = await axios.get(`api/rcpt/letter/${letterId}`);
      } else if (pageFor == 'me') {
        response = await axios.get(`api/letter/${letterId}`);
      }
      console.log(response.data);

      const date = new Date(response.data.date);
      const month = date.toLocaleString('ko-KR', { month: 'long' });
      const day = date.getDate();
      dateTitle = `${month} ${day}일 편지`;

      postboxId = response.data.postbox;

      isThereReply = response.data.comments.length > 0;
      isValidReply = isThereReply && response.data.comments[0].body;

      if (isThereReply) {
        replyId = response.data.comments[0].id;
      }
    } catch (error) {
      errorHandler(error);
    }
  });

  async function createNewReply() {
    try {
      const response = await axios.post(`api/rcpt/letter/${letterId}/comment`);
      console.log(response);

      if (response.status === 201) {
        console.log('답장 신규 생성 성공');
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleReply = () => {
    if (!isThereReply) {
      // 기존에 생성되어 있던 답장 없을 시에만 새로운 답장 생성
      createNewReply();
    }
    navigate(`/lover/reply/${letterId}`);
  };

  const handlePrev = () => {
    if (pageFor == 'lover') {
      if (postboxId) {
        navigate(`/lover/letterlist/${postboxId}`);
      }
    } else {
      navigate('/me/letterlist');
    }
  };
</script>

<!-- <Theme>
  <body class="dimmed-ui"> -->
<body class="readletter-ui">
  <ReadNavbar navTitle={dateTitle} {handlePrev} />
  <Letter mode="READ" id={letterId} {pageFor} />

  {#if isValidReply}
    <Reply mode="READ" id={replyId} />
    <div style="width: 100%; height: 100px" />
  {/if}
  {#if pageFor == 'lover' && !isValidReply}
    <div class="button-section">
      <Button class="start-ui" handleClick={handleReply}>답장 쓰러가기</Button>
    </div>
  {/if}
</body>

<!-- </Theme> -->

<style>
  .button-section {
    display: flex;
    justify-content: center;
    padding: 70px 28px;
  }
</style>
