<script>
    export let message;
    export let visible = false;

    export function show(msg) {
        message = msg;
        visible = true;

        setTimeout(() => {
            visible = false;
        }, 3000);
    }
</script>

{#if visible}
<div class="toast-container">
    <div class="toast-wrapper">
        <div class = "toast-message">
            {message}
        </div>
    </div>
</div>
{/if}

<style>
    .toast-container {
        bottom: 85px;
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        position: fixed;
        text-align: center;
        z-index: 9999;
    }
    .toast-wrapper {
        width: 334px;
        height: 48px;
        line-height: 48px;
        background-color: #1D2939;
        border-radius: 7px;
        opacity: 90%;
    }
    .toast-message {
        display: inline-block; 
        color: white;
        font-family: Pretendard; /* 수정 필요 */
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
    }
    
</style>