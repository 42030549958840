<script>
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import Moon from '../../components/Moon.svelte';
  import { moonData } from '../../stores/moonData';
  import OnboardingMoon from '../../components/OnboardingMoon.svelte';
  import InfoModal from '../../components/InfoModal.svelte';
  let modal;

  const handleNext = () => {
    navigate('/onboarding/pre');
  };

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();

</script>

<body class="onboarding-ui">
  <div class="moon-container" on:click={() => modal.show()}>
    <Moon />
  </div>
  <div class="text-section">
    <h3 class="title">달의 우체통</h3>
    <p class="onboarding">
      당신의 빈자리를 가장 크게 느끼게 될 이를 위해<br />달의 우체통에 미리
      편지를 남겨보세요
    </p>
  </div>

  <div class="mailbox-container">
    <img src="/assets/image/mailbox/mailbox-basic-shadow-size.png" alt="">
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">달의 우체통 시작하기</Button>
  </div>
</body>

<InfoModal bind:this={modal}>
  <div class="info-modal-wrapper">
    <h3 class="info-modal-title">달의 우체통은 이렇게 작동해요!</h3>
    <div class="title-underline" />
    <div class="info-modal-text">
      {#each moonData as { moonLogo, moonTitle, moonInfo }}
        <div class="moon-section">
          <OnboardingMoon {moonLogo} {moonTitle} {moonInfo} />
        </div>
      {/each}
    </div>
  </div>
</InfoModal>

<style>
  .moon-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .text-section {
    position: relative;
    margin: auto;
    /* top: 44px; */
    top: 38px;
    /* margin-bottom: 72px; */
    margin-bottom: 48px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 28px;
    font-family: 'Sebang_Regular';
    margin-bottom: 10px;
  }

  .onboarding {
    font-size: 14px;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
  }

  .mailbox-container {
    /* height: 380px; */
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 40px 24px 40px;
  }

  .info-modal-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -10px;
  }

  .info-modal-title {
    color: var(--gray-900-color);
    font-family: 'Sebang_Regular';
    line-height: 150%;
    font-size: 18px;
    font-weight: 400;
  }

  .title-underline {
    position: absolute;
    top: 36px;
    left: 28px;
    width: 240px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: 0.7;
    background: var(--gray-200-color);
    z-index: -1;
  }

  .info-modal-text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 216px;
    gap: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .moon-section {
    margin-top: 0;
  }
</style>
