<script>
  let buttonProps = {
    class: [$$restProps.class],
    style: ""
  }

  export let handleClick;

</script>

<div class = "button-area">
  <button class={buttonProps.class} {...buttonProps} on:click={handleClick}>
    <div class="item-container">
      <slot />
    </div>
  </button>
</div>


<style>
button {
  border: none;
}

.button-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.button-container {
  width: 100%;
  height: 54px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.item-container {
  margin: auto;
  height: 100%;
  text-align: center;
  font-weight: 600;
  font-family: 'Pretendard_SemiBold';
  /* 버튼 내 텍스트 중앙 배치 */
  display: flex;
  align-items: center; /* 콘텐츠 아이템 내부 상하관계 정렬 */
  justify-content: center; /* 콘텐츠 아이템의 좌우관계 정렬 */
}

.start-ui {
  background-color: var(--primary-500-color);
  width: 100%;
  height: 58px;
  color: #fff;
  border-radius: 48px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}

.start-ui:disabled {
  opacity: 0.42;
}

.lover-main-ui {
  background-color: var(--primary-500-color);
  width: 90%;
  height: 58px;
  color: #fff;
  border-radius: 48px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}

.modal-ui {
  background-color: #14183C;
  width: 100%;
  height: 58px;
  color: #fff;
  border-radius: 32px;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.modal-ui-close {
  background-color: #C7C8D0;
  width: 316px;
  height: 58px;
  color: #fff;
  border-radius: 32px;
  text-align: center;
  align-items: center;
  cursor: pointer;
}

.kakao-ui {
  background-color: #FEE500;
  width: 100%;
  height: 58px;
  color: #181600;
  border-radius: 48px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}
</style>