<script>
  export let moonLogo = "";
  export let moonTitle = "";
  export let moonInfo = "";
</script>

<div class="moon-container">
  <div class="title">
    <div class="moon-logo">{moonLogo}</div>
    <div class="moon-title">{moonTitle}</div>
  </div>
  <div class="moon-info">{moonInfo}</div>
</div>

<style>
  .moon-container {
    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 6px 18px;
  }

  .title {
    display: flex;
    text-align: left;
    align-items: center;
    min-width: 280px;
  }

  .moon-logo {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .moon-title {
    color: var(--primary-900-color);
    font-size: 16px;
    font-weight: 900;
    font-family: 'Gangwon_Bold' !important;
    outline-width: 1px;
    margin-bottom: 1px;
  }

  .moon-info {
    position: relative;
    margin-top: 4px;
    padding: 0 20px 0 28px;
    text-align: left;
    color: var(--gray-600-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    font-family: 'Gangwon_Light' !important;
    -webkit-text-stroke: 0.1px #475467;
  }
</style>