<script>
  import { onMount } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { errorHandler } from '../../utils/errorHandler';
  import axios from '../../libs/axios';
  import Toast from '../Toast.svelte';
  import CheckModal from '../CheckModal.svelte';

  export let to; // LETTER or REPLY
  export let id;
  let file; // 현재 파일/답장에 업로드 된 파일

  let fileCheckApiUrl; // 파일 유무 체크하기 위한 편지/답장 조회 API url
  let fileAttachApiUrl; // 파일 첨부 API url
  let fileDeleteApiUrl; // 파일 삭제 API url

  let toast;
  let fileDeleteModal;

  let isIOSDevice = false;
  let allowUpload;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    detectDeviceType();
    console.log(id);
    if (id) {
      console.log(id);
      setApiUrl();
      getFileData();
      checkAllowUpload();
    }
  });

  function setApiUrl() {
    // to가 어디인지(LETTER or REPLY)에 따라 파일 첨부 API url 동적 지정
    if (to == 'LETTER') {
      fileCheckApiUrl = `api/letter/${id}`;
      fileAttachApiUrl = `api/letter/${id}/attachment`;
      // fileDeleteApiUrl = `api/letter/attachment/${file.id}`;
    } else if (to == 'REPLY') {
      fileCheckApiUrl = `api/rcpt/comment/${id}`;
      fileAttachApiUrl = `api/rcpt/comment/${id}/attachment`;
      // fileDeleteApiUrl = `api/rcpt/comment/attachment/${file.id}`;
    }
  }

  const getFileData = async () => {
    setApiUrl();
    console.log('getFileData');
    try {
      const response = await axios.get(fileCheckApiUrl);
      console.log(response, 'fi9lefilefilefile');

      if (response.data.attachments.length > 0) {
        console.log('파일이있어요 15515151');
        file = response.data.attachments[0];
        allowUpload = false;
        console.log(file);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const checkUploadedFile = async (event) => {
    setApiUrl();
    checkAllowUpload();

    if (!allowUpload) {
      toast.show('첨부파일은 한 개만 담을 수 있어요!');
      event.preventDefault();
      return;
    }

    if (document.getElementById('file-upload')) {
      document.getElementById('file-upload').value = null;
    }
    if (document.getElementById('image-upload')) {
      document.getElementById('image-upload').value = null;
    }
    if (document.getElementById('voice-upload')) {
      document.getElementById('voice-upload').value = null;
    }
    console.log(65);
  };

  async function checkAllowUpload() {
    try {
      const response = await axios.get(fileCheckApiUrl);
      allowUpload = response.data.attachments.length > 0 ? false : true;
      console.log(allowUpload);
    } catch (error) {
      errorHandler(error);
    }
  }

  async function uploadNewFile(event) {
    setApiUrl();
    console.log(allowUpload, 'in uploadeNewFile');
    if (allowUpload) {
      const selectedFile = event.target.files[0];
      console.log(selectedFile);
      if (selectedFile.name.length >= 100) {
        toast.show('파일명이 100자 이하인 파일만 첨부할 수 있어요.');
        return;
      }
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('file_type', selectedFile.type.slice(0, 5));

      try {
        const response = await axios.post(fileAttachApiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 201) {
          dispatch('fileChangeEvent', selectedFile);
          getFileData();
          toast.show('파일을 저장했어요!');
        } else {
          console.error('파일 업로드 실패');
        }
      } catch (error) {
        console.error('파일 업로드 실패', error);
        errorHandler(error);
      }
    } else {
      console.log(allowUpload, 'nono');
      return;
    }
  }

  const deleteFile = async () => {
    let response;
    console.log(to, file);
    if (file) {
      try {
        if (to == 'LETTER') {
          response = await axios.delete(`api/letter/attachment/${file.id}`);
        } else if (to == 'REPLY') {
          response = await axios.delete(
            `api/rcpt/comment/attachment/${file.id}`
          );
        }
        console.log(response.status, response);
        if (response.status === 204) {
          dispatch('fileChangeEvent');
          file = {};
          console.log(file);
          toast.show('파일을 삭제했어요!');
          allowUpload = true;
          fileDeleteModal.hide();
        } else {
          console.error(error);
        }
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  function detectDeviceType() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf('iphone') > -1 ||
      userAgent.indexOf('ipad') > -1 ||
      userAgent.indexOf('ipod') > -1
    ) {
      isIOSDevice = true;
    }
  }
</script>

<Toast bind:this={toast} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="file-upload-footer-section">
  <div class="upload-section">
    {#if isIOSDevice}
      <input
        type="file"
        id="file-upload"
        accept="image/*, .mp3, .wav, .m4a, .3gp. amr; capture=camera"
        on:click={checkUploadedFile}
        on:change={uploadNewFile}
        style="display: none"
      />
      <label class="file-uploader" for="file-upload">
        <img src="/assets/image/file-plus.svg" alt="" />
      </label>
    {:else}
      <input
        type="file"
        id="image-upload"
        accept="image/*"
        on:click={checkUploadedFile}
        on:change={uploadNewFile}
        style="display: none"
      />
      <label class="image-uploader" for="image-upload">
        <img src="/assets/image/image-icon.svg" alt="" />
      </label>

      <input
        type="file"
        id="voice-upload"
        accept=".mp3, .wav, .m4a, .3gp. amr"
        on:click={checkUploadedFile}
        on:change={uploadNewFile}
        style="display: none"
      />
      <label class="voice-uploader" for="voice-upload">
        <img src="/assets/image/mic-icon.svg" alt="" />
      </label>
    {/if}
  </div>

  {#if !allowUpload}
    <div class="delete-button" on:click={() => fileDeleteModal.show()}>
      <p>첨부파일 삭제</p>
    </div>
  {/if}
</div>

<CheckModal bind:this={fileDeleteModal}>
  <div class="modal-title">첨부파일을 삭제할까요?</div>
  <div class="modal-text">
    사진, 음성 중 최대 1개의 첨부파일을<br />편지 안에 첨부할 수 있어요.
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={deleteFile}>삭제하기</button>
  </div>
</CheckModal>

<style>
  .file-upload-footer-section {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0px;
    width: 100%;
    height: 24px;
    background-color: #fcfcfd;
    border-top: 1px solid #cfd1e1;
    padding: 12px;
    align-items: center;
  }
  .upload-section {
    display: flex;
    justify-content: left;
  }
  .image-uploader,
  .file-uploader {
    margin-left: 16px;
  }
  .voice-uploader {
    margin-left: 22px;
  }

  .delete-button {
    right: 0px;
    margin-right: 24px;
    width: 80px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
  }
  .delete-button p {
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--primary-500-color);
  }
</style>
