export const letterData = [
  {
    letterTitle: '편지제목이여',
    letterDate: '2023년 8월 5일',
    letterCover: 'cover1',
    isRead: true,
    isReply: true,
  },
  {
    letterTitle: '편지제목이여',
    letterDate: '2023년 8월 6일',
    letterCover: 'cover2',
    isRead: false,
    isReply: false,
  },
  {
    letterTitle: '편지제목이여',
    letterDate: '2023년 8월 8일',
    letterCover: 'cover3',
    isRead: true,
    isReply: false,
  },
  {
    letterTitle: '편지제목이여',
    letterDate: '2023년 8월 9일',
    letterCover: 'cover1',
    isRead: false,
    isReply: false,
  },
  {
    letterTitle: '편지제목이여',
    letterDate: '2023년 8월 10일',
    letterCover: 'cover3',
    isRead: false,
    isReply: false,
  },
];

export function getReadLetter() {
  return letterData.filter((item) => item.isRead === true);
}

export function getUnreadLetter() {
  return letterData.filter((item) => item.isRead === false);
}

export function getLetterCoverImagePath(cover) {
  let imagePath = '';

  if (cover === 'cover1') {
    imagePath = '/assets/image/letter-cover/letter-cover-1.png';
  } else if (cover === 'cover2') {
    imagePath = '/assets/image/letter-cover/letter-cover-2.png';
  } else if (cover === 'cover3') {
    imagePath = '/assets/image/letter-cover/letter-cover-3.png';
  } else if (cover === 'cover4') {
    imagePath = '/assets/image/letter-cover/letter-cover-4.png';
  } else if (cover === 'cover5') {
    imagePath = '/assets/image/letter-cover/letter-cover-5.png';
  } else if (cover === 'cover6') {
    imagePath = '/assets/image/letter-cover/letter-cover-6.png';
  } else if (cover === 'cover7') {
    imagePath = '/assets/image/letter-cover/letter-cover-7.png';
  } else if (cover === 'cover8') {
    imagePath = '/assets/image/letter-cover/letter-cover-8.png';
  } else {
    imagePath = '/assets/image/letter-cover/letter-cover-9.png';
  }

  return imagePath;
}
