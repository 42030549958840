<script>
  import SendingLottie from "../../components/Main/SendingLottie.svelte";
  import { navigate } from "svelte-routing";
  import axios from "../../libs/axios";
  import { errorHandler } from "../../utils/errorHandler";
  import Letter from "../../components/Letter/Letter.svelte";

  const redirectTo = "/sending/end";
  let letterCount = 0;

  // 편지 갯수 불러오기
  async function getLetterCount() {
    try {
      const response_1 = await axios.get("/api/postbox");
      console.log("백엔드 응답1:", response_1.data);

      const postboxId = response_1.data.results[0].id;

      const response_2 = await axios.get(`/api/postbox/${postboxId}/letter`);
      console.log("백엔드 응답2:", response_2.data);

      try {
        await axios.post(`/api/postbox/${postboxId}/send`);
      } catch (error) {
        errorHandler(error);
        window.location.href = "/main";
      }

      const waitForResponse = () => {
        if (letterCount < response_2.data.count) {
          letterCount += 1;
          setTimeout(waitForResponse, 300);
        } else navigate(redirectTo);
      };
      waitForResponse();
    } catch (error) {
        errorHandler(error);
    }
  }

  getLetterCount();
</script>

<body class="start-ui">
  <div class="loading-container">
    <div class="lottie-section"><SendingLottie /></div>
    <div class="star-section"><div class="mailbox-star" /></div>
  </div>
  <div class="text-section">
    {letterCount}개의 편지를<br />달에게 부치는 중...
  </div>
</body>

<style>
  .loading-container {
    width: 100%;
    height: 336px;
    margin: auto;
    margin-top: 64px;
    /* margin-left: 24px; */
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .lottie-section {
    margin: auto;
    justify-content: center;
    align-items: center;
    z-index: 9;
    /* margin-left: 18px; */
  }

  .star-section {
    width: 100%;
    height: 320px;
    position: absolute;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
  }

  .mailbox-star {
    width: 244px;
    height: 244px;
    position: absolute;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/image/moon-with-star.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text-section {
    position: relative;
    margin: auto;
    justify-content: center;
    margin-top: 116px;
    font-size: 28px;
    font-family: "Sebang_Regular";
    text-align: center;
    line-height: 150%;
    letter-spacing: -0.56px;
  }
</style>
