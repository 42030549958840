<script>
  import { navigate } from "svelte-routing";
  import Button from "../../components/Button.svelte";

  const handleNext = () => {
    navigate("/start");
  };

  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key !== "token") {
      localStorage.removeItem(key);
    }
  });

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<body class="onboarding-end-ui">
  <div class="text-section">
    <h3 class="title">환영해요 :)</h3>
    <p class="onboarding">
      달의 우체통과 함께<br />꽃신까지의 첫 걸음을 내딛어볼까요?
    </p>
  </div>

  <div class="mailbox-container">
    <img src="/assets/image/mailbox/mailbox-basic-shadow-size.png" alt="">
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">나만의 우체통 만들러가기</Button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 38px;
    margin-bottom: 48px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 28px;
    font-family: "Sebang_Regular";
    margin-bottom: 10px;
  }

  .onboarding {
    font-size: 14px;
    font-family: "Pretendard_Regular";
    line-height: 150%;
  }

  .mailbox-container {
    /* height: 380px; */
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 40px 24px 40px;
  }
</style>
