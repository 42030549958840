<script>
  import axios from '../libs/axios';
  import { navigate } from 'svelte-routing';
  import LoginLottie from '../components/Start/LoginLottie.svelte';
  import Toast from '../components/Toast.svelte';

  let toast;

  // 인가코드 백으로 보내는 작업 하는곳
  const code = new URL(window.location.href).searchParams.get('code');
  const currentURL = new URL(window.location.href);
  const redirect_uri = `${currentURL.protocol}//${currentURL.host}/auth`; // Redirect URI

  //console.log(currentURL, currentURL.protocol, currentURL.host, redirect_uri);

  const kakaoLogin = async () => {
    try {
      const response = await axios.post(`/api/auth/social/kakao`, {
        code,
        redirect_uri,
      });
      console.log(response.data);
      localStorage.setItem('token', response.data.token);
      isPostbox();
    } catch (error) {
      console.log(error.response);

      // 신규 가입 불가 처리 (~ V2 오픈일)
      if (parseInt(error.response.status) == 403) {
        /*
        toast.show(
          `현재 업데이트 작업으로 신규 가입이 어려워요😥 \n다가오는 ${
            openDate.getMonth() + 1
          }월 ${openDate.getDate()}일에 곧 만나요!`
        );
        */
        const openDate = new Date(error.response.data.open_date);
        alert(
          `현재 업데이트 작업으로 신규 가입이 어려워요😥 \n다가오는 ${
            openDate.getMonth() + 1
          }월 ${openDate.getDate()}일에 곧 만나요!`
        );
        navigate('/onboarding');
        console.log(error.response.data.open_date);
      }
    }
  };

  function isPostbox() {
    axios
      .get('/api/postbox')
      .then((response) => {
        // 서버 응답을 처리
        // 토큰 검증이 성공하고 사용자 ID 존재
        if (
          response.data &&
          response.data.results &&
          response.data.results.length > 0
        ) {
          // 우체통 링크가 발송 전이면 main, 발송 후면 sending/end로
          let postboxId = response.data.results[0].postboxId;
          navigate(response.data.results[0].is_link_sent ? `/me` : '/main');
        } else {
          // 사용자 ID가 없는 경우 로그인 온보딩 페이지로 리디렉션
          navigate('/onboarding/end');
        }
      })
      .catch((error) => {
        navigate('/onboarding/end');
        console.error(error);
        // 오류 처리
      });
  }

  kakaoLogin();
</script>

<body>
  <body class="start-ui">
    <div class="loading-container">
      <div class="lottie-section"><LoginLottie /></div>

      <div class="mailbox-star" />
    </div>
    <div class="text-section">
      <!-- 달의 우체통에<br />로그인 하는 중... -->
      <img src="/assets/image/auth-text.svg" alt="" />
    </div>
  </body>

  <Toast bind:this={toast} />

  <style>
    .loading-container {
      width: 100%;
      height: 336px;
      margin-top: 140px;
      margin-left: 28px;
      display: flex;
      text-align: center;
      justify-content: center;
    }

    .lottie-section {
      margin: auto;
      justify-content: center;
      align-items: center;
      margin-left: 24px;
    }

    .mailbox-star {
      width: 248px;
      height: 248px;
      position: absolute;
      align-items: center;
      justify-content: center;
      /*background-image: url("/assets/image/mailbox-star.svg");*/
      background-size: contain;
      background-repeat: no-repeat;
    }

    .text-section {
      position: relative;
      margin: auto;
      justify-content: center;
      margin-top: 36px;
      font-size: 30px;
      text-align: center;
      line-height: 150%;
    }
  </style>
</body>
