<script>
	// import { LottiePlayer } from '@lottiefiles/svelte-lottie-player';
	import { onMount } from 'svelte';
    
	// @ts-ignore
	let LottiePlayer;
	onMount(async () => {
		const module = await import('@lottiefiles/svelte-lottie-player');
		LottiePlayer = module.LottiePlayer;
	});
</script>

{#if LottiePlayer}
	<LottiePlayer
		src="/assets/lottie/step-login.json"
		autoplay={true}
		loop={true}
		controls={false}
		renderer="svg"
		background="transparent"
		width={320}
        height={320}
	/>
{/if}
