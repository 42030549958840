<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import {element} from "svelte/internal";

  let isFirstSelected = false;
  let isSecondSelected = false;

  const handleNext = () => {
    if (isFirstSelected) {
      navigate('/onboarding');
    }
    if (isSecondSelected) {
      navigate('/onboarding/pre/info');
    }
  };

  $: isNextEnabled = isFirstSelected || isSecondSelected;

  const selectFirst = () => {
    isFirstSelected = true;
    isSecondSelected = false;
  };

  const selectSecond = () => {
    isFirstSelected = false;
    isSecondSelected = true;
  };

</script>

<body class="start-ui">
  <div class="text-section">
    <h3 class="title">당신은 누구인가요?</h3>
    <p class="onboarding">
      달의 우체통 작동순서에 따라<br />다음 단계를 안내해드릴게요.
    </p>
  </div>
  <div class="select-container">
    <div
      class="box-context"
      on:click={selectFirst}
      class:active={isFirstSelected}
    >
      <div class="text-context">
        <p><img src="/assets/image/icon/onboarding-pre-1.svg" alt="" /></p>
        <p class="info-main">입대를 앞둔 예비군인이에요</p>
        <p class="info-sub">상대방에게 남길 편지를 작성하고 싶어요.</p>
      </div>
    </div>
    <div
      class="box-context"
      on:click={selectSecond}
      class:active={isSecondSelected}
    >
      <div class="text-context">
        <p><img src="/assets/image/icon/onboarding-pre-2.svg" alt="" /></p>
        <p class="info-main">편지를 받을 예비곰신이에요</p>
        <p class="info-sub">예약된 편지를 받아보고 답장을 남기고 싶어요.</p>
      </div>
    </div>
  </div>

  <div class="button-section" on:click={handleNext}>
    <button class="start-ui"  disabled={!isNextEnabled}>다음으로</button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 56px;
    margin-bottom: 100px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.56px;
    line-height: 150%;
    margin: 0 auto;
  }

  .onboarding {
    color: var(--gray-300-color);
    font-size: 14px;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
  }

  .select-container {
    position: relative;
    height: auto;
    margin: 24px;
  }

  .box-context {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 20px 24px 20px;
    border-radius: 16px;
    background-color: #222647;
    flex-shrink: 0;
    margin-bottom: 22px;
    cursor: pointer;
  }

  .box-context:active, .box-context:focus, .box-context:hover {
    border: 3px solid #fff;
    background: #1e234f;
    box-shadow: 4px 4px 13.7px 0px rgba(0, 0, 0, 0.25);
  }

  .active {
    border: 3px solid #fff;
    background: #1e234f;
    box-shadow: 4px 4px 13.7px 0px rgba(0, 0, 0, 0.25);
  }

  .text-context {
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard_Regular';
    margin-top: 0;
  }

  .text-context p {
    margin: 0;
  }

  .text-context img {
    margin-bottom: 24px;
  }

  .text-context .info-main {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Pretendard_SemiBold';
    line-height: 150%;
    margin: 0;
    /* margin-top: 16px; */
  }

  .text-context .info-sub {
    color: var(--primary-50-color);
    font-size: 14px;
    font-family: 'Pretendard_Medium';
    margin: 0;
    margin-top: 2px;
  }

  .button-section {
    position: relative;
    display: flex;
    margin: 48px 40px 24px 40px;
    justify-content: center;
  }

  .button-section button {
    width: 100%;
    height: 58px;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 48px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Pretendard_SemiBold';
    background-color: #6c63eb;
    flex-shrink: 0;
  }

  button:disabled {
    opacity: 0.42;
  }
</style>