<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import axios from '../../libs/axios';
  import ReadLetterCard from '../../components/Lover/ReadLetterCard.svelte';
  import ReadNavbar from '../../components/Letter/ReadNavbar.svelte';
  import { getLetterDateTitle } from '../../components/Lover/LetterCardSetter';
  import { errorHandler } from '../../utils/errorHandler';

  export let postboxId;

  const currentURL = window.location.pathname;
  const pageFor = currentURL.includes('/lover/')
    ? 'lover'
    : currentURL.includes('/me/')
      ? 'me'
      : '';

  let viewableLetters = [];

  onMount(async () => {
    if (pageFor == 'me') {
      try {
        const response = await axios.get('/api/postbox');
        postboxId = response.data.results[0].id;
        console.log(postboxId);
      } catch (error) {
        errorHandler(error);
      }
    }
    if (postboxId) {
      getViewableLetters();
    }
  });

  async function getViewableLetters() {
    console.log(postboxId);
    let response;
    try {
      if (pageFor == 'lover') {
        response = await axios.get(`/api/rcpt/postbox/${postboxId}/letter`);
        viewableLetters = response.data.results;
      } else if (pageFor == 'me') {
        response = await axios.get(`/api/postbox/${postboxId}/letter`);
        viewableLetters = response.data.results;
      }
      viewableLetters = response.data.results.map((item, index) => {
        return {
          ['letterId']: item.id,
          ['letterDate']: getLetterDateTitle(item.date),
          ['isRead']: item.viewed_at === null ? false : true,
          ['isReply']:
            item.comments.length > 0 && item.comments[0].body ? true : false,
        };
      });
      console.log(viewableLetters);
    } catch (error) {
      errorHandler(error);
    }
  }

  const handlePrev = () => {
    if (pageFor == 'lover') {
      if (postboxId) {
        navigate(`/lover/${postboxId}`);
      }
    } else {
      navigate('/me');
    }
  };
</script>

<body class="readletter-ui">
  <ReadNavbar {handlePrev} />
  <div class="container">
    <div class="head-section">
      <span class="head-title">편지 보관함</span>
    </div>
    <div class="read-letter-list">
      <div class="list">
        {#each viewableLetters as { letterCover, letterId, letterDate, isReply }}
          <ReadLetterCard {letterCover} {letterId} {letterDate} {isReply} />
        {/each}
      </div>
    </div>
  </div>
</body>

<style>
  .container {
    margin: auto;
    margin: 12px 28px 0 28px;
    justify-content: center;
    z-index: 9;
  }

  .head-section {
    text-align: left;
  }

  .head-title {
    font-size: 18px;
    font-family: 'Pretendard_SemiBold';
    color: var(--black-color);
  }

  .read-letter-list {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .list {
    position: relative;
    align-items: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
</style>
