<script>
  import { onMount } from 'svelte';
  import axios from '../../libs/axios';
  import LetterPaper from './LetterPaper.svelte';
  import { errorHandler } from '../../utils/errorHandler';

  export let mode;
  export let id;
  export let replyData = {};
  export let file;

  const getReplyData = async () => {
    try {
      let response = {};
      response = await axios.get(`api/rcpt/comment/${id}`);
      replyData = response.data;
      console.log(replyData);

      if (replyData.attachments.length > 0) {
        console.log('파일있음');
        file = replyData.attachments[0];
      }
      return response;
    } catch (error) {
      errorHandler(error);
    }
  };
  let promise = getReplyData();

  onMount(async () => {
    getReplyData();
    console.log(replyData, id, file);
  });
</script>

{#await promise}
  <br />
{:then}
  <div class="reply-container">
    <img
      src="/assets/image/reply-pig-tail-line.svg"
      alt=""
      class="pig-tail-line"
    />
    <LetterPaper
      on:body
      {mode}
      content={replyData.body}
      file={replyData.attachments[0]}
      paperType="reply-A"
    />
  </div>
{/await}

<style>
  .reply-container {
    text-align: center;
  }
  .pig-tail-line {
    width: 34px;
    height: 36px;
    margin-top: 14px;
    margin-bottom: 2px;
    text-align: center;
  }
</style>
