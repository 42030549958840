<script>
  import { navigate } from 'svelte-routing';
  import Navbar from '../../components/Start/Navbar.svelte';
  import { onMount } from 'svelte';
  import axios from '../../libs/axios';

  async function isLinkSent() {
    const response = await axios.get('/api/postbox');
    console.log(response.data);

    if (response.data.results[0].is_link_sent) {
      navigate('/sending/end');
    }
  }

  let title = '';
  const storedtitle = localStorage.getItem('title');

  let particle = '';

  $: particle = getParticle(title);

  function getParticle(text) {
    const trimmedText = text.trim();
    if (!trimmedText) return '를'; // 빈 문자열 처리

    const lastChar = trimmedText.charAt(trimmedText.length - 1);
    const lastCharCode = lastChar.charCodeAt(0);

    // 한글 범위 확인
    const isKoreanCharacter = lastCharCode >= 0xac00 && lastCharCode <= 0xd7a3;
    if (!isKoreanCharacter) return '를'; // 한글이 아니면 '를' 반환

    // 한글인 경우 받침 여부 확인
    const offset = lastCharCode - 0xac00;
    const finalConsonantIndex = offset % 28;

    return finalConsonantIndex > 0 ? '을' : '를';
  }

  if (storedtitle) {
    title = storedtitle;
  }

  let isValid = false;

  const validateForm = () => {
    const inputRegex = /^[\s\S]{0,4}$/;
    const isInputValid = inputRegex.test(title);

    isValid = isInputValid;
  };

  onMount(() => {
    validateForm();
  });

  function handleInput() {
    if (title.length <= 4 && !/\s/.test(title)) {
      title = event.target.value;
    }

    validateForm();
  }

  const handleNext = () => {
    localStorage.setItem('title', title);
    navigate('/start/password');
  };

  isLinkSent();

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<body class="start-ui">
  <Navbar pageNumber={3} pageRoute={'/start/time'} />
  <div class="text-section">
    <h3 class="title">누구를 위한<br />우체통인가요?</h3>
    <h3 class="subtitle">이름은 공백 포함 최대 4글자까지 가능해요.</h3>
  </div>
  <div class="input-section">
    <input
      type="text"
      id="darling-name"
      class="name-input"
      placeholder="받는 사람"
      bind:value={title}
      on:input={handleInput}
      maxlength="4"
      required
    />
    <label for="darling-name">{particle} 위한 달의 우체통</label>
  </div>
  <div class="select-container">
    <div class="mailbox-container">
      <img src="/assets/image/mailbox/mailbox-with-moon-darling-size.png" alt="">
    </div>
  </div>
  <div class="button-section">
    <button
      on:click={handleNext}
      disabled={!validateForm || title.length === 0}
    >
      다음
    </button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    margin: 44px 40px 24px 40px;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    line-height: 150%;
    text-align: left;
    letter-spacing: -2%;
    margin-bottom: -4px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-200-color);
    font-family: 'Pretendard_Regular';
    text-align: left;
  }

  .input-section {
    position: relative;
    width: 100%;
    height: 48px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 15px;
    color: var(--secondary-100-color);
    font-family: 'Pretendard_Regular';
  }

  input {
    position: relative;
    margin-left: 40px;
    width: 108px;
    height: 36px;
    border: none;
    border-bottom: 1px solid var(--secondary-300-color);
    outline: none;
    text-align: center;
  }

  input[type='text'] {
    padding-top: 6px;
    padding-left: 2px; /* input태그 입력 위치 조정 */
    padding-bottom: 0;
    color: #8e90a7;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Pretendard_Regular';
    background-color: transparent;
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 450;
  }

  input[type='text']:valid {
    padding-top: 6px;
    padding-left: 2px; /* input태그 입력 위치 조정 */
    padding-bottom: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
  }

  input[type='text']::before {
    content: attr(placeholder);
    width: 100%;
  }

  input[type='text']:focus::before,
  input[type='text']:valid::before {
    display: none;
  }

  label {
    position: relative;
    display: inline-block; /* 라벨 가로 배치 */
    font-size: 15px;
    color: #cfd1e1;
    font-weight: 400;
  }

  .select-container {
    width: 100%;
    height: 388px;
    margin-top: 12px;
  }

  .mailbox-container {
    /* height: 380px; */
    height: calc(40 * var(--vh, 1vh) + 20px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 40px 24px 40px;
  }

  button {
    background-color: #6c63eb;
    width: 100%;
    height: 58px;
    color: #fff;
    border: none;
    border-radius: 48px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
  }

  button:disabled {
    color: #706dab;
    background-color: #403e84;
  }
</style>
