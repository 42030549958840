<script>
  import { navigate } from 'svelte-routing';
  import Button from '../../components/Button.svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';

  let title = localStorage.getItem('title');
  let titleLength = title.length;

  let particle = '';

  $: particle = getParticle(title);

  function getParticle(text) {
    const trimmedText = text.trim();
    if (!trimmedText) return '를';

    const lastChar = trimmedText.charAt(trimmedText.length - 1);
    const lastCharCode = lastChar.charCodeAt(0);

    const isKoreanCharacter = lastCharCode >= 0xac00 && lastCharCode <= 0xd7a3;
    if (!isKoreanCharacter) return '를';

    const offset = lastCharCode - 0xac00;
    const finalConsonantIndex = offset % 28;

    return finalConsonantIndex > 0 ? '을' : '를';
  }

  function getAllDataFromLocalStorage() {
    const allData = {};
    const keys = [
      'ah_yeonaehagosipda_end_date',
      'title',
      'letter_start_date',
      'letter_end_date',
      'letter_send_time',
    ];
    keys.forEach((element) => {
      allData[element] = localStorage.getItem(element);
    });

    return JSON.stringify(allData);
  }

  async function createPostbox(data) {
    try {
      const response = await axios.post('/api/postbox', data);
      console.log('백엔드 응답:', response.data);
    } catch (error) {
      errorHandler(error);
    }
  }

  createPostbox(getAllDataFromLocalStorage());

  const handleNext = () => {
    navigate('/main');
  };

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<body class="onboarding-end-ui">
  <div class="text-section">
    <div class="underline-section">
      <h3 class="title">{title}{particle} 위한 달의 우체통</h3>
      <div
        class="main-title-underline"
        style="width: {titleLength * 10 + 260}px;"
      />
    </div>
    <p class="onboarding">
      우체통을 성공적으로 만들었어요!<br />이제 편지 배달 전까지 편지를
      작성해볼까요?
    </p>
  </div>
  <div class="mailbox-container">
    <img src="/assets/image/mailbox/mailbox-basic-shadow-size.png" alt="">
  </div>
  <div class="button-section" on:click={handleNext}>
    <Button class="start-ui">편지 작성하러가기</Button>
  </div>
</body>

<style>
  .text-section {
    position: relative;
    margin: auto;
    top: 44px;
    margin-bottom: 72px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 28px;
    font-family: 'Sebang_Regular';
    margin-bottom: 10px;
  }

  .onboarding {
    font-size: 14px;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
  }

  .underline-section {
    width: 100%;
    position: relative;
    display: flex;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .main-title-underline {
    position: absolute;
    top: 52px;
    height: 12px;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: 0.16;
    background-color: #fff;
    z-index: 9;
  }

  .mailbox-container {
    /* height: 380px; */
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }


  .button-section {
    position: relative;
    display: flex;
    justify-content: center; /* 버튼 중앙 배치 */
    margin: 10px 40px 24px 40px;
  }
</style>
