import api from 'axios';

// export async function verify() {
//   if (!getAuthToken()) {
//     return null;
//   }
//   return api
//     .get("/verify")
//     .then((response) => {
//       if (response.data.token) {
//         localStorage.setItem("auth_data", JSON.stringify(response.data));
//       }
//       return response.data;
//     })
//     .catch((error) => {
//       localStorage.removeItem("auth_data");
//       return null;
//     });
// }

export function getAuthToken() {
  const auth_data = localStorage.getItem('token');
  if (auth_data) {
    return auth_data;
  }
  return null;
}
