<script>
  import { Route, Router } from 'svelte-routing';
  import Onboarding from './routes/OnboardingPage/Onboarding.svelte';
  import PreSelect from './routes/OnboardingPage/PreSelect.svelte';
  import PreInfo from './routes/OnboardingPage/PreInfo.svelte';
  import Step1 from './routes/OnboardingPage/Step1.svelte';
  import StepEnd from './routes/OnboardingPage/StepEnd.svelte';
  import SelectDate from './routes/StartPage/SelectDate.svelte';
  import SelectTime from './routes/StartPage/SelectTime.svelte';
  import SelectDarling from './routes/StartPage/SelectDarling.svelte';
  import SettingPassword from './routes/StartPage/SettingPassword.svelte';
  import Main from './routes/MainPage/Main.svelte';
  import LetterPage from './routes/WritingLetterPage/WriteLetter.svelte';
  import StartLover from './routes/LoverPage/StartLover.svelte';
  import MainLover from './routes/LoverPage/MainLover.svelte';
  import LetterList from './routes/LoverPage/LetterList.svelte';
  import LetterDetail from './routes/LoverPage/LetterDetail.svelte';
  import WriteReply from './routes/LoverPage/WriteReply.svelte';
  import Auth from './routes/Auth.svelte';
  import StartEnd from './routes/StartPage/StartEnd.svelte';
  import SendingLetter from './routes/MainPage/SendingLetter.svelte';
  import SendingEnd from './routes/MainPage/SendingEnd.svelte';
  import SenderFinish from './routes/SenderPage/SenderFinish.svelte';
  import SenderList from './routes/SenderPage/SenderList.svelte';
  import Home from './routes/MainPage/Home.svelte';
  import MainMe from './routes/BoyPage/MainMe.svelte';

  export let url = '';
</script>

<Router {url}>
  <Route path="/" component={Onboarding} />
  <Route path="/onboarding" component={Step1} />
  <Route path="/onboarding/pre" component={PreSelect} />
  <Route path="/onboarding/pre/info" component={PreInfo} />
  <Route path="/auth" component={Auth} />
  <Route path="/onboarding/end" component={StepEnd} />
  <Route path="/start" component={SelectDate} />
  <Route path="/start/time" component={SelectTime} />
  <Route path="/start/darling" component={SelectDarling} />
  <Route path="/start/password" component={SettingPassword} />
  <Route path="/start/end" component={StartEnd} />
  <Route path="/main/" component={Home} />
  <Route path="/main/letter" component={Main} />
  <Route path="/sending" component={SendingLetter} />
  <Route path="/sending/end" component={SendingEnd} />
  <Route path="/letter/:letterId" component={LetterPage} />
  <Route path="/lover/start/:postboxId" component={StartLover} />
  <Route path="/lover/:postboxId" component={MainLover} />
  <Route path="/lover/letterlist/:postboxId" component={LetterList} />
  <Route path="/lover/letter/:letterId" component={LetterDetail} />
  <Route path="/lover/reply/:letterId" component={WriteReply} />
  <Route path="/sender/finish" component={SenderFinish} />
  <Route path="/sender/list" component={SenderList} />
  <Route path="/me" component={MainMe} />
  <Route path="/me/letterlist" component={LetterList} />
  <Route path="/me/letter/:letterId" component={LetterDetail} />
</Router>
