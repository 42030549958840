<script>
  import { createEventDispatcher, onMount, onDestroy } from 'svelte';
  import {
    addMonths,
    addYears,
    subYears,
    endOfWeek,
    isBefore,
    isSameDay,
    startOfWeek,
    subMonths,
  } from 'date-fns';
  import { localeFormat } from './utils';
  import Calendar from './components/Calendar.svelte';
  export let letterlist = [];
  export let notEmptyLetterlist = [];
  export let autoApply = false;
  export let dateFormat = 'yyyy년 MMM dd일';
  export let monthIndicator = true;
  export let endDate = endOfWeek(new Date());
  export let events = [];
  export let firstDayOfWeek = 'sunday';
  export let isoWeekNumbers = false;
  export let locale;
  export let maxDate = addYears(new Date(), 10);
  export let minDate = subYears(new Date(), 10);
  export let monthDropdown = false;
  export let monthFormat = 'MMMM';
  export let numPages = 1;
  export let rtl = false;
  export let singlePicker = true;
  export let prevIcon = '&#9666;';
  export let nextIcon = '&#9656;';
  export let weekGuides = false;
  export let weekNumbers = false;
  export let yearDropdown = false;
  export let id = `s-date-range-picker-${Math.random()}`;
  export let letter; // 배달체크
  export let yellow; // 노랑체크

  export let disabledDates = [];

  import { letterDates } from '../../../libs/store';
  // 날짜 상태 구독
  let unsubscribe;
  unsubscribe = letterDates.subscribe((value) => {
    let letterStartDate = value.startDateStore;
    let letterEndDate = value.endDateStore;

    console.log('Calender Store SemiTest:', letterStartDate, letterEndDate);
  });

  export let letterStartDate = new Date();
  export let letterEndDate;

  console.log('Calender Store Test:', letterStartDate, letterEndDate);
  export let newEndDate = new Date(letterStartDate); // 처음 월 선택을 위함
  export let tempStartDate = new Date(letterStartDate); // 선택된 시작 날짜
  export let today = new Date(tempStartDate); // 처음 월 선택을 위함
  export let tempTheEndDate = new Date();
  export let hoverDate = new Date();

  const hideSelect = () => {
    clickSelectDate = false;
    apply();
  };

  hoverDate = tempStartDate;
  let tempEndDate = endDate;
  export let startDate = null;
  let hasSelection = true;

  const dispatchEvent = createEventDispatcher();
  const cellWidth = 44;
  const maxCalsPerPage = 2;
  const pageWidth = cellWidth * 7;
  const pageWidthWithPadding = pageWidth + 96;

  window.__locale__ = locale;

  $: canApply = function () {
    if (!hasSelection) {
      return false;
    }

    return (
      !isSameDay(tempStartDate, startDate) || !isSameDay(tempEndDate, endDate)
    );
  };

  $: maxWidth =
    pickerWidth >= maxCalsPerPage * pageWidth
      ? maxCalsPerPage * pageWidthWithPadding
      : pickerWidth;
  $: months = [...Array(numPages)].map((_, i) =>
    addMonths(new Date(tempStartDate), i)
  ); // 처음 지정된 월
  $: pickerWidth = numPages * pageWidthWithPadding;

  $: startDateReadout = function () {
    if (!hasSelection && isBefore(hoverDate, tempStartDate)) {
      return localeFormat(hoverDate, dateFormat);
    }
    return localeFormat(tempStartDate, dateFormat);
  };

  $: endDateReadout = function () {
    if (!hasSelection) {
      if (isBefore(hoverDate, tempStartDate)) {
        return localeFormat(tempStartDate, dateFormat);
      }

      return localeFormat(hoverDate, dateFormat);
    }

    return localeFormat(tempEndDate, dateFormat);
  };

  function apply() {
    if (!canApply()) {
      return;
    }

    dispatchEvent('apply', {
      startDate: tempStartDate,
      endDate: tempEndDate,
    });
  }

  $: onSelection = function ({ detail }) {
    newEndDate = new Date(
      detail.getFullYear(),
      detail.getMonth(),
      detail.getDate(),
      tempStartDate.getHours(),
      tempStartDate.getMinutes(),
      tempStartDate.getSeconds()
    );

    if (singlePicker) {
      tempStartDate = newEndDate;

      console.log('선택날짜: ', newEndDate);
      dispatchEvent('newEndDateChange', {
        newEndDate,
      });
      hoverDate = newEndDate;
    }
  };

  function onHover({ detail }) {
    hoverDate = detail;
    console.log('hoverDate:', hoverDate);
  }

  function onPrevMonth() {
    months = months.map((mo) => subMonths(mo, 1));
  }

  function onNextMonth() {
    months = months.map((mo) => addMonths(mo, 1));
  }

  function onPageChange({ detail: { incrementAmount } }) {
    if (incrementAmount > 0) {
      months = months.map((mo) => addMonths(mo, incrementAmount));
    } else {
      const absIncrementAmount = Math.abs(incrementAmount);
      months = months.map((mo) => subMonths(mo, absIncrementAmount));
    }
  }

  function foo() {
    console.log('foo');
  }

  onMount(function () {
    dispatchEvent('newEndDateChange', {
      newEndDate,
    });
    apply();
    console.log('처음 선택 날짜', startDateReadout(), endDateReadout());
  });

  onDestroy(() => {
    unsubscribe(); // 구독 취소
  });
</script>

<form
  on:submit|preventDefault={foo}
  {id}
  style={`width: ${maxWidth + 16}px`}
  class={rtl ? 'rtl s-date-range-picker' : 's-date-range-picker'}
>
  <div>
    <div class="grid">
      {#each months as month}
        <Calendar
          on:pageChange={onPageChange}
          on:hover={onHover}
          on:selection={onSelection}
          on:prevMonth={onPrevMonth}
          on:nextMonth={onNextMonth}
          on:apply={apply}
          {prevIcon}
          {nextIcon}
          {disabledDates}
          {events}
          {hoverDate}
          {hasSelection}
          {firstDayOfWeek}
          {isoWeekNumbers}
          {maxDate}
          {minDate}
          {month}
          {monthDropdown}
          {monthFormat}
          {monthIndicator}
          {pageWidth}
          {rtl}
          {singlePicker}
          {tempEndDate}
          {today}
          {weekGuides}
          {weekNumbers}
          {yearDropdown}
          {letter}
          {yellow}
          {tempStartDate}
          {tempTheEndDate}
          {letterStartDate}
          {letterEndDate}
          {letterlist}
          {notEmptyLetterlist}
        />
      {/each}
      <div class="selectBtn" on:click={hideSelect}>선택 완료</div>
    </div>
    <div class="full-height-scroll" />
  </div>
</form>

<style>
  .s-date-range-picker {
    position: relative;
    top: 0px;
    font-size: 18px;
    margin: 2em;
    border: none;
    border-radius: 14px;
    margin: 0 auto;
    padding: 4px;
  }

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 18px;
    border: none;
    border-radius: 14px;
    margin: auto;
    align-items: center;
    justify-content: center;
    padding: 0.3em;
  }

  .s-date-range-picker :global(.calendar-row) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  /* 달력 셀 크기 */
  .s-date-range-picker :global(.calendar-cell) {
    width: 36px;
    height: 36px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .s-date-range-picker :global(.select) {
    padding: 6px 6px;
    background-color: transparent;
    margin: 0.2px;
    cursor: pointer;
  }

  @media all and (min-width: 390px) and (max-width: 411px) {
    .s-date-range-picker :global(.calendar-cell) {
      width: 44px;
      height: 44px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media all and (min-width: 412px) {
    .s-date-range-picker :global(.calendar-cell) {
      width: 44.8px;
      height: 44.8px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .rtl {
    direction: rtl;
  }
  .s-date-range-picker :global(button) {
    cursor: pointer;
  }
  .s-date-range-picker :global(button:disabled) {
    cursor: not-allowed;
  }

  .selectBtn {
    width: 100px;
    height: 36px;
    position: relative;
    display: none;
    font-size: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: #6c63eb;
    cursor: pointer;
  }
</style>
