<script>
  export let shown = false;
  export function show() {
    shown = true;
  }
  export function hide() {
    shown = false;
  }
</script>

<svelte:window
  on:keydown={(e) => {
    if (e.keyCode === 27) {
      hide();
    }
  }}
/>

{#if shown}
  <div class="modal-wrapper">
    <div class="modal-section">
      <!-- <span class="close" on:click={() => hide()}>X </span> -->
      <slot />
    </div>
    <div class="modal-dimmed" on:click={() => hide()} />
  </div>
{/if}

<style>
  .modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
  }

  .modal-section {
    position: relative;
    min-width: 280px;
    max-width: 296px;
    min-height: 488px;
    border-radius: 18px;
    background-color: #f8fafe;
    z-index: 999;
    margin: auto;
    margin-top: 80px;
    padding: 8px 10px;
    overflow: hidden;
  }

  .modal-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
  }

  .close {
    float: right;
    cursor: pointer;
  }
</style>
