<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import {
    addMonths,
    addYears,
    subYears,
    endOfWeek,
    addWeeks,
    isAfter,
    isBefore,
    isSameMinute,
    isSameSecond,
    isSameDay,
    isSameMonth,
    subMonths,
  } from 'date-fns';
  import { roundDown, localeFormat } from './utils';
  import Calendar from './components/Calendar.svelte';
  import TimePicker from './components/TimePicker.svelte';

  export let autoApply = false;
  export let dateFormat = 'yyyy년 MMM dd일';
  export let monthIndicator = true;
  export let disabledDates = [];
  export let endDate = endOfWeek(new Date());
  export let events = [];
  export let firstDayOfWeek = 'sunday';
  export let isoWeekNumbers = false;
  export let locale;
  export let maxDate = addYears(new Date(), 10);
  export let minDate = subYears(new Date(), 10);
  export let monthDropdown = false;
  export let monthFormat = 'MMMM';
  export let numPages = 1;
  export let rtl = false;
  export let singlePicker = false;
  export let startDate = new Date(); // 설정 시작 날짜
  export let timePicker = false;
  export let timePicker24Hour = true;
  export let minuteIncrement = 1;
  export let secondIncrement = 1;
  export let timePickerSeconds = false;
  export let prevIcon = '&#9666;';
  export let nextIcon = '&#9656;';
  export let today = new Date();
  export let weekGuides = false;
  export let weekNumbers = false;
  export let yearDropdown = false;
  export let id = `s-date-range-picker-${Math.random()}`;

  let clickSelectDate = false;
  let clickCount = 0;

  const showSelect = () => {
    clickSelectDate = true;
  };

  const hideSelect = () => {
    clickSelectDate = false;
    apply();
  };

  const handleCount = () => {
    clickCount++;
    if (clickCount === 2) {
      hideSelect();
      clickCount = 0;
    } else {
      showSelect();
    }
  };

  let hoverDate = endDate;
  let tempEndDate = addWeeks(endDate, 1)
  console.log('tempEndDate:', tempEndDate)
  let tempStartDate = today;
  let hasSelection = true;

  const dispatchEvent = createEventDispatcher();
  const cellWidth = 44;
  const maxCalsPerPage = 2;
  const pageWidth = cellWidth * 7;
  const pageWidthWithPadding = pageWidth + 96;

  window.__locale__ = locale;

  $: canApply = function () {
    if (!hasSelection) {
      return false;
    }

    if (timePicker) {
      if (timePickerSeconds) {
        return (
          !isSameSecond(tempStartDate, startDate) ||
          !isSameSecond(tempEndDate, endDate)
        );
      }

      return (
        !isSameMinute(tempStartDate, startDate) ||
        !isSameMinute(tempEndDate, endDate)
      );
    }

    return (
      !isSameDay(tempStartDate, startDate) || !isSameDay(tempEndDate, endDate)
    );
  };

  $: maxWidth =
    pickerWidth >= maxCalsPerPage * pageWidth
      ? maxCalsPerPage * pageWidthWithPadding
      : pickerWidth;
  $: months = [...Array(numPages)].map((_, i) => addMonths(today, i));
  $: pickerWidth = numPages * pageWidthWithPadding;

  $: startDateReadout = function () {
    if (hasSelection && isBefore(hoverDate, tempStartDate)) {
      return '';
    }
    if (!hasSelection && isBefore(hoverDate, tempStartDate)) {
      return localeFormat(hoverDate, dateFormat);
    }
    return localeFormat(tempStartDate, dateFormat);
  };

  $: endDateReadout = function () {
    if (hasSelection && isBefore(hoverDate, tempStartDate)) {
      return '';
    }
    if (!hasSelection) {
      if (isBefore(hoverDate, tempStartDate)) {
        return localeFormat(tempStartDate, dateFormat);
      }

      return localeFormat(hoverDate, dateFormat);
    }

    return localeFormat(tempEndDate, dateFormat);
  };

  onMount(function () {
    console.log(startDateReadout(), endDateReadout());

    if (timePicker) {
      tempStartDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
        startDate.getHours(),
        roundDown(startDate.getMinutes(), minuteIncrement),
        roundDown(startDate.getSeconds(), secondIncrement)
      );

      tempEndDate = hoverDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate(),
        endDate.getHours(),
        roundDown(endDate.getMinutes(), minuteIncrement),
        roundDown(endDate.getSeconds(), secondIncrement)
      );
    }
  });

  function apply() {
    if (!canApply()) {
      return;
    }
    dispatchEvent('apply', {
      startDate: tempStartDate,
      endDate: tempEndDate,
    });
  }

  function onSelection({ detail }) {
    const newEndDate = new Date(
      detail.getFullYear(),
      detail.getMonth(),
      detail.getDate(),
      tempEndDate.getHours(),
      tempEndDate.getMinutes(),
      tempEndDate.getSeconds()
    );

    if (singlePicker) {
      tempStartDate = tempEndDate = newEndDate;
    } else if (hasSelection) {
      tempStartDate = new Date(
        detail.getFullYear(),
        detail.getMonth(),
        detail.getDate(),
        tempStartDate.getHours(),
        tempStartDate.getMinutes(),
        tempStartDate.getSeconds()
      );
      hasSelection = false;
    } else {
      if (isBefore(newEndDate, tempStartDate)) {
        tempEndDate = tempStartDate;
        tempStartDate = newEndDate;
      } else {
        tempEndDate = newEndDate;
      }

      hasSelection = true;

      dispatchEvent('selection', {
        startDate: tempStartDate,
        endDate: tempEndDate,
      });

      if (autoApply) {
        apply();
      }
    }
  }

  function onHover({ detail }) {
    hoverDate = detail;
  }

  function onPrevMonth() {
    months = months.map((mo) => subMonths(mo, 1));
  }

  function onNextMonth() {
    months = months.map((mo) => addMonths(mo, 1));
  }

  function onPageChange({ detail: { incrementAmount } }) {
    if (incrementAmount > 0) {
      months = months.map((mo) => addMonths(mo, incrementAmount));
    } else {
      const absIncrementAmount = Math.abs(incrementAmount);
      months = months.map((mo) => subMonths(mo, absIncrementAmount));
    }
  }

  function foo() {
    console.log('foo');
  }
</script>

<form
  on:submit|preventDefault={foo}
  {id}
  style={`width: ${maxWidth}px`}
  class={rtl ? 'rtl s-date-range-picker' : 's-date-range-picker'}
>
  <div class="label-row" on:click={handleCount}>
    <div class="select-container">
      <div class="start-section">
        <label for="date-start">편지 배달 시작</label>
        <input
          type="text"
          id="date-start"
          class="date-input"
          placeholder="클릭 시 날짜 선택"
          required
          readonly
          value={startDateReadout()}
        />
      </div>
      <div class="dot-section">
        <div class="dot" />
        <div class="dot" />
        <div class="dot" />
      </div>
      <div class="end-section">
        <label for="date-end">편지 배달 종료</label>
        <input
          type="text"
          id="date-end"
          class="date-input"
          placeholder="클릭 시 날짜 선택"
          required
          readonly
          value={endDateReadout()}
        />
      </div>
    </div>
  </div>
  {#if clickSelectDate}
    <div>
      <div class="grid">
        {#each months as month}
          <Calendar
            on:pageChange={onPageChange}
            on:hover={onHover}
            on:selection={onSelection}
            on:prevMonth={onPrevMonth}
            on:nextMonth={onNextMonth}
            on:apply={apply}
            {prevIcon}
            {nextIcon}
            {disabledDates}
            {events}
            {hoverDate}
            {hasSelection}
            {firstDayOfWeek}
            {isoWeekNumbers}
            {maxDate}
            {minDate}
            {month}
            {monthDropdown}
            {monthFormat}
            {monthIndicator}
            {pageWidth}
            {rtl}
            {singlePicker}
            {tempEndDate}
            {tempStartDate}
            {today}
            {weekGuides}
            {weekNumbers}
            {yearDropdown}
          />
        {/each}
        <div class="selectBtn" on:click={hideSelect}>선택 완료</div>
      </div>
      <div class="full-height-scroll" />
    </div>
  {/if}
</form>

<style>
  .s-date-range-picker {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    background-color: #14183c;
    border: none;
    border-radius: 14px;
    margin: auto 40px;
    padding: 4px;
  }

  form {
    width: 100%;
  }

  .label-row {
    position: relative;
    align-items: center;
    margin: 0 60px 32px 60px;
  }

  .grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-size: 16px;
    background-color: #2b3058;
    border: none;
    border-radius: 14px;
    margin: 12px 60px;
    align-items: center;
    justify-content: center;
  }

  .s-date-range-picker :global(.calendar-row) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .s-date-range-picker :global(.calendar-cell) {
    width: 36px;
    height: 36px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .s-date-range-picker :global(.select) {
    padding: 8px 12px;
    background-color: transparent;
    margin: 1px;
    cursor: pointer;
  }
  .rtl {
    direction: rtl;
  }
  .s-date-range-picker :global(button) {
    cursor: pointer;
  }
  .s-date-range-picker :global(button:disabled) {
    cursor: not-allowed;
  }

  .selectBtn {
    width: 100px;
    height: 34px;
    padding: 0 28px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    font-size: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: var(--primary-500-color);
    cursor: pointer;
    font-family: 'Pretendard_Medium';
  }

  .select-container {
    width: 100%;
    height: 60px;
    margin: 0 auto;
    position: relative;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border: none;
    background-color: transparent;
  }

  button:active,
  button:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    background-color: transparent !important;
  }

  .dot-section {
    position: relative;
    display: flex;
    float: right;
    max-width: 40px;
    height: 12px;
    top: 22px;
    margin: 0px 18px;
    align-items: center;
    justify-content: center;
  }

  .dot {
    position: relative;
    width: 3.2px;
    height: 3.2px;
    margin: 0 2.8px;
    border-radius: 100%;
    background: #8e90a7;
  }

  .start-section,
  .end-section {
    position: relative;
    height: 48px;
    z-index: 999;
  }

  label {
    position: relative;
    /* display: inline-block;  라벨 가로 배치 */
    top: 6px;
    font-size: 13px;
    font-weight: 400;
    line-height: 150%;
    color: var(--secondary-300-color);
    text-align: left;
    font-family: 'Pretendard_Regular';
  }

  input {
    position: relative;
    width: 100%;
    min-width: 112px;
    height: 40px;
    border: none;
    border-bottom: 0.8px solid var(--secondary-300-color);
    outline: none;
    cursor: pointer;
  }

  input[type='text'] {
    padding-top: 6px;
    padding-left: 0.3px; /* input태그 입력 위치 조정 */
    padding-bottom: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    font-family: 'Pretendard_SemiBold';
    background-color: transparent;
    -webkit-text-size-adjust: none;
  }

  input[type='text']:valid {
    padding-top: 6px;
    padding-left: 0.3px; /* input태그 입력 위치 조정 */
    padding-bottom: 0;
    color: #fff;
    font-size: 13.2px;
    font-weight: 600;
    background-color: transparent;
  }

  input[type='text']::before {
    content: attr(placeholder);
    width: 100%;
  }

  input[type='text']:focus::before,
  input[type='text']:valid::before {
    display: none;
  }
</style>
