export const loverMoonData = [
  {
    moonLogo: '🌒',
    moonTitle: '초승달, 우체통에서 편지 받기',
    moonInfo:
      '다음 편지의 배달 시간을 실시간으로 메인 화면에서 확인할 수 있어요. 우체통을 눌러 배달 온 편지들을 확인해요.',
  },
  {
    moonLogo: '🌓',
    moonTitle: '상현달, 답장 쓰기',
    moonInfo:
      '편지를 읽은 뒤 바로 답장을 작성할 수 있어요. 상대가 3일 간 접속하지 않으면 메시지가 사라지는 메신저 서비스와 달리, 달의 우체통에 남긴 답장은 사라지지 않고 언제든지 확인할 수 있어요.',
  },
  {
    moonLogo: '🌕',
    moonTitle: '보름달, 기록 모아보기',
    moonInfo:
      '달에게 남긴 기록들이 보고 싶다면 언제든지 달의 우체통에 찾아오세요.',
  },
];
