<script>
    export let currentTimeClass;

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();

    if (hours >= 10 && hours < 17) {
      return "morning";
    } else if (hours >= 17 && hours < 20) {
      return "sunset";
    } else if ((hours >= 20 && hours < 24) || (hours >=0 && hours < 5)) {
      return "night";
    } else return "dawn";
  }
  currentTimeClass = getCurrentTime();

  function setScreenSize() {
        let vh = window.innerHeight * 0.01;

        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }

      setScreenSize();
</script>

<div class="theme {currentTimeClass}">
  <slot />
</div>

<style>
  .dawn {
    width: auto !important;
    max-width: calc(var(--vh, 1vh) * 100);
    background-image: url("/assets/image/background/bg-dawn-size.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .morning {
    width: auto !important;
    max-width: calc(var(--vh, 1vh) * 100);
    background-image: url("/assets/image/background/bg-morning-size.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sunset {
    width: auto !important;
    max-width: calc(var(--vh, 1vh) * 100);
    background-image: url("/assets/image/background/bg-sunset-size.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .night {
    width: auto !important;
    max-width: calc(var(--vh, 1vh) * 100);
    background-image: url("/assets/image/background/bg-night-size.png");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .theme {
    transition: background-color 0.5s;
  }
</style>
