<script>
  import { navigate } from 'svelte-routing';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';
  import { shareKakao, copyToClipboard } from '../../utils/share';

  getPostboxInfo();

  let postboxId, shareURL;

  async function getPostboxInfo() {
    try {
      const response = await axios.get('/api/postbox');
      postboxId = response.data.results[0].id;
      shareURL = `https://dalchetong.com/lover/start/${postboxId}`;
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleMeHome = (event) => {
    navigate(`/me`);
  };
</script>

<body class="start-ui">
  <div class="text-section">
    <h3 class="title">모든 편지들이 성공적으로<br />달에게 접수되었어요!</h3>
  </div>
  <div class="info-section">
    <div class="up-context">
      <img src="/assets/image/icon/sending-end-1.svg" alt="" />
      <div class="text-context">
        <p class="info-main">상대방에게 우체통<br />링크를 전달해요.</p>
        <p class="info-sub">
          <span>상대방</span>이 편지를 받는 우체통 링크예요!
        </p>
      </div>
    </div>
    <div class="down-context">
      <button class="kakao-btn" on:click={shareKakao(postboxId)}>
        <img src="/assets/image/icon/kakao-logo-size.svg" alt="" />
        카카오톡으로<br />전달하기
      </button>
      <button class="clip-btn" on:click={copyToClipboard(postboxId)}>
        <img src="/assets/image/icon/clip-icon.svg" alt="" />
        우체통 링크<br />직접 복사하기</button
      >
    </div>
  </div>
  <div class="info-section">
    <div class="up-context">
      <img src="/assets/image/icon/sending-end-2.svg" alt="" />
      <div class="text-context">
        <p class="info-main">달의 우체통에 돌아와<br />답장을 확인해요.</p>
        <p class="info-sub">내가 답장을 확인하는 곳이예요!</p>
      </div>
    </div>
    <div class="down-context">
      <button class="home-btn" on:click={handleMeHome}>
        달의 우체통 메인으로 돌아가기</button
      >
    </div>
  </div>
</body>

<style>
  body {
    padding: 0 28px 0 28px;
  }

  .text-section {
    position: relative;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 36px;
    text-align: center;
    justify-content: center;
  }

  .title {
    font-size: 26px;
    font-family: 'Sebang_Regular';
    margin-bottom: 14px;
    line-height: 150%;
  }

  .info-section {
    position: relative;
    height: auto;
    padding: 20px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.06);
    flex-shrink: 0;
    margin-bottom: 12px;
  }

  .up-context {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }

  .up-context img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
  }

  .up-context .text-context {
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard_Regular';
    margin-top: 0;
    margin: 4px 0 0 8px;
  }

  .up-context .text-context .info-main {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
  }

  .up-context .text-context .info-sub {
    color: var(--primary-50-color);
    font-size: 14px;
    margin: 0;
    margin-top: 8px;
  }

  .up-context .text-context .info-sub span {
    color: #ff65af;
    font-weight: 600;
  }

  .info-section button {
    font-size: 14px;
    line-height: 150%;
    border: none;
    border-radius: 14px;
    font-family: 'Pretendard_Regular';
  }

  .down-context {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
  }

  .kakao-btn {
    flex-grow: 1;
    max-width: calc(100% - 120px - 12px);
    height: 108px;
    padding: 14px;
    background-color: #fee500;
    color: #181600;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .kakao-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .clip-btn {
    width: 120px;
    height: 108px;
    padding: 10px;
    background-color: #9091a6;
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .kakao-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .home-btn {
    width: 100%;
    height: 54px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    background-color: #6c63eb;
    flex-shrink: 0;
  }
</style>
