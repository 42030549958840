<script>
  import { shareKakao, copyToClipboard } from '../../utils/share';

  export let isVisible = false;
  export let postboxId;

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  setScreenSize();
</script>

<div class="container" class:visible={isVisible}>
  <div class="wrapper">
    <div class="text-section">
      <h3 class="bs-title">
        상대방에게 전달할 우체통 링크를<br />잃어버리셨나요?
      </h3>
      <p class="bs-sub">걱정마세요. 언제든지 다시 링크를 찾을 수 있어요.</p>
    </div>
    <div class="mailbox-container">
      <img src="/assets/image/mailbox/boy-mailbox-before.png" alt="" />
    </div>
    <div class="button-section">
      <button class="kakao-btn" on:click={shareKakao(postboxId)}>
        <img src="/assets/image/icon/kakao-logo-size.svg" alt="" />
        카카오톡으로 링크 전달하기
      </button>
      <button class="clip-btn" on:click={copyToClipboard(postboxId)}>
        우체통 링크 직접 복사하기</button
      >
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    width: 100%;
    min-height: 564px;
    bottom: 0px;
    transition: bottom 0.2s ease-in-out; /* 트랜지션 설정 */
    border: none;
    border-radius: 40px 40px 0 0;
    background-color: #f8fafe;
    align-items: center;
    justify-content: center;
    padding: auto;
    overflow-x: hidden;
    z-index: 2;
  }

  .visible {
    bottom: 0; /* 바텀 시트가 나타날 때의 위치 */
  }

  .wrapper {
    position: relative;
    margin: 0 32px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: 430px) {
    .container {
      max-width: 430px;
      min-height: 564px;
    }

    .wrapper {
      margin: 0 64px;
    }
  }

  .text-section {
    display: flex;
    margin-top: 24px;
    flex-direction: column;
  }

  .bs-title {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Pretendard_Bold';
    line-height: 150%;
    text-align: left;
    margin-left: 0;
    margin-bottom: 0px;
    color: var(--black-color);
  }

  .bs-sub {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Pretendard_Regular';
    color: var(--gray-500-color);
  }

  .mailbox-container {
    height: calc(32 * var(--vh, 1vh) + 20px);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex-wrap: wrap;
    margin: auto 0;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .button-section button {
    width: 100%;
    height: 54px;
    display: flex;
    padding: 14px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 36px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    border: none;
    font-family: 'Pretendard_SemiBold';
  }

  .kakao-btn {
    background-color: #fee500;
    color: #181600;
    display: flex;
    flex-direction: row;
  }

  .kakao-btn img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  .clip-btn {
    background-color: #14183c;
    color: #fff;
  }
</style>
