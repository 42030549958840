<script>
  import Navbar from '../../components/Start/Navbar.svelte';
  import { Pincode, PincodeInput } from '../../components/Start/svelte-pincode';
  import StartEndModal from '../../components/Start/StartEndModal.svelte';
  import '../../../public/modal.css';

  let modal;
  let love_start_date = '';
  let storedTitle = localStorage.getItem('title');
  let storedStartDate = localStorage.getItem('letter_start_date');
  let storedEndDate = localStorage.getItem('letter_end_date');
  let storedTime = localStorage.getItem('letter_send_time').split(':');
  let storedTimeHour = storedTime[0];
  let storedTimeMinute = storedTime[1];

  let code = [];
  let value = '';
  let isFormValid = false;

  let errorMsg = '날짜를 다시 한 번 확인해주세요';

  let inputValue = '';
  let success = false;
  let error = false;
  let complete = false;

  $: success = complete;
  $: error = false;
  $: if (inputValue && inputValue.length !== 6) {
    error = true;
  } else {
    let year = '20' + inputValue.slice(0, 2);
    let month = inputValue.slice(2, 4);
    let day = inputValue.slice(4);

    let daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let daysInInputMonth = daysInMonth[parseInt(month, 10) - 1];

    let isValidDate = day <= daysInInputMonth;

    if (isValidDate) {
      console.log(`입력된 날짜: ${year}-${month}-${day}`);
      console.log(`해당 월의 일수: ${daysInInputMonth}`);
      error = false;
    } else {
      console.error('유효하지 않은 날짜입니다.');
      error = true;
    }
  }

  const inputPassword = () => {
    love_start_date =
      '20' +
      inputValue.slice(0, 2) +
      '-' +
      inputValue.slice(2, 4) +
      '-' +
      inputValue.slice(4);
    localStorage.setItem('ah_yeonaehagosipda_end_date', love_start_date);
    console.log(love_start_date);
  };

  const handleNext = () => {
    window.location.href = '/start/end';
  };
</script>

<body class="start-ui">
  <Navbar pageNumber={4} pageRoute={'/start/darling'} />
  <div class="text-section">
    <h3 class="title">
      우체통 확인을 위한<br />비밀코드를 설정해주세요.
    </h3>
    <h3 class="setting-pwd">
      두 분이 서로 사귀게 된 날짜는 언제인가요?<br />
      ex. 2023년 3월 5일 -> 230305
    </h3>
  </div>

  <div class="select-container">
    <div class="start-section">
      <div class="code-section">
        <Pincode bind:complete bind:value={inputValue} type="numeric">
          <PincodeInput />
          <PincodeInput />
          <PincodeInput />
          <PincodeInput />
          <PincodeInput />
          <PincodeInput />
        </Pincode>
      </div>

    </div>
    <div class="error-section" class:complete class:success class:error>
      {#if error}
        {errorMsg}
      {/if}
    </div>
    <div class="tip-container">
      <div class="alert-section">
        <p class="star">*</p>
        <p>
          상대방이 다른 비밀코드를 입력하면 편지를 확인할 수 없어요.<br />
          우체통을 완성하기 전 마지막으로 비밀코드를 확인해주세요.
        </p>
      </div>
    </div>
    <div class="button-section">
      <button
        class="next-btn"
        on:click={() => {
          inputPassword();
          modal.show();
        }}
        disabled={!success || error}
      >
        다음
      </button>
    </div>
  </div>
</body>

<StartEndModal bind:this={modal}>
  <div class="modal-title">우체통을 만드시겠어요?</div>
  <div class="modal-text">
    우체통을 완성할 시 사전 설정의 변경이 불가능해요. 입력하신 정보가 아래와
    동일한지 꼼꼼하게 확인해주세요.
  </div>
  <div class="modal-info-text">
    <li>
      <ul>
        편지 전달 기간:<br />{storedStartDate} ~ {storedEndDate}
      </ul>
      <ul>편지 전달 시간: {storedTimeHour}시 {storedTimeMinute}분</ul>
      <ul>우체통 받는 분: {storedTitle}</ul>
      <ul>비밀코드: {inputValue}</ul>
    </li>
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={handleNext}>우체통 완성하기</button>
    <button class="gray-btn" on:click={() => modal.hide()}
      >다시 한 번 확인할래요</button
    >
  </div>
</StartEndModal>

<style>
  .text-section {
    position: relative;
    margin: auto;
    margin: 44px 40px 24px 40px;
    justify-content: center;
  }

  .title {
    font-family: 'Sebang_Regular';
    font-size: 26px;
    line-height: 150%;
    text-align: left;
    letter-spacing: -2%;
    margin-bottom: -4px;
  }

  .setting-pwd {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-200-color);
    font-family: 'Pretendard_Regular';
    text-align: left;
    line-height: 150%;
  }

  .select-container {
    width: 100%;
    height: auto;
    position: relative;
    /* position: absolute; */
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .start-section {
    display: flex;
    /* margin: 0 36px 0 36px; */
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .error-section {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: var(--semantic-danger-color);
    text-align: center;
  }

  .code-section {
    /* position: relative; */
    display: flex;
    margin-top: 24px;
    justify-content: center;
    font-size: 20px;
  }

  /** Pincode **/
  .code-section > :global([data-pincode]) {
    display: inline-flex;
    font-family: 'Pretendard_SemiBold';
  }

  /** PincodeInput */
  .code-section > :global([data-pincode] input) {
    width: 2.5rem;
    height: 3.6rem;
    margin: 0 0.02rem;
    padding: 0;
    border: 0;
    border-radius: 10px;
    font-size: 1.6rem;
    text-align: center;
    outline: none;
    color: #fff !important;
    background-color: var(--secondary-500-color) !important;
  }

  .code-section > :global([data-pincode] input:focus) {
    z-index: 1;
    border: 2.4px solid #fff;
    color: #fff;
    padding: 0;
  }

  .tip-container {
    position: relative;
    display: flex;
    margin: 0 auto; /* 텍스트 중앙 배열 */
    margin-top: 236px;
  }

  .alert-section {
    position: relative;
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .alert-section p {
    display: flex;
    justify-content: center;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    color: var(--secondary-300-color);
    font-family: 'Pretendard_Regular';
  }

  .star {
    display: flex;
    padding-bottom: 16px;
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 10px 40px 24px 40px;
  }

  .next-btn {
    background-color: #6c63eb;
    width: 100%;
    height: 58px;
    color: #fff;
    border: none;
    border-radius: 48px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
  }

  .next-btn:disabled {
    color: #706dab;
    background-color: #403e84;
  }

  .modal-text {
    font-size: 14px;
    color: var(--gray-500-color);
    font-family: 'Pretendard_Regular';
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    margin-bottom: 14px;
  }

  .modal-info-text {
    position: relative;
    height: auto;
    margin: 24px auto;
    padding: 8px 6px;
    text-align: center;
    align-items: center;
    border-radius: 12px;
    border: 1px dashed #14183c;
    background: #f6f6f6;
  }

  .modal-info-text li {
    flex-direction: column;
    list-style: none;
    padding: 0;
  }

  .modal-info-text ul {
    width: 100%;
    padding: 0 4px;
    font-size: 14px;
    color: var(--gray-500-color);
    font-family: 'Pretendard_Medium';
    font-weight: 400;
    text-align: center;
    list-style: none;
  }

  .modal-button-section {
    margin-top: 16px;
  }

  .modal-button-section button {
    width: 100%;
    height: 58px;
    border-radius: 32px;
    text-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    font-family: 'Pretendard_Regular';
    line-height: 24px;
  }

  .indigo-btn {
    background-color: #14183c;
    margin-bottom: 12px;
  }

  .gray-btn {
    background-color: #bdbecb;
  }
</style>
