<script>
  import { navigate } from 'svelte-routing';
  import ProgressModal from '../../components/ProgressModal.svelte';
  import '../../../public/modal.css';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';
  import { calculateDDay } from '../../utils/DdayCalculator';
  import StartEndModal from '../../components/Start/StartEndModal.svelte';

  let firstModal;
  let secondModal;
  export let isSendingFine = true; // 편지 최종 부치기 모달
  let sendingAgree = false;

  let letterCount = '';
  let dDay = '';
  let deliveryPeriod = '';
  let dDayPassed = false;

  // 사용자 정보 불러오기
  async function getPostboxInfo() {
    try {
      const response_1 = await axios.get('/api/postbox');
      console.log('백엔드 응답1:', response_1.data);

      if (response_1.data.results[0].is_link_sent) {
        navigate('/sending/end');
      }

      const postboxId = response_1.data.results[0].id;

      const response_2 = await axios.get(`/api/postbox/${postboxId}/letter`);
      console.log('백엔드 응답2:', response_2.data);

      letterCount = response_2.data.count;
      console.log('작성한 편지 수:', letterCount);

      const targetDate = new Date(response_1.data.results[0].letter_start_date);
      dDay = calculateDDay(targetDate)[0];
      dDayPassed = calculateDDay(targetDate)[1];

      const startDate = new Date(response_1.data.results[0].letter_start_date);
      const endDate = new Date(response_1.data.results[0].letter_end_date);
      deliveryPeriod = calculateDeliveryPeriod(startDate, endDate);
    } catch (error) {
      errorHandler(error);
    }
  }

  const handleSendLetter = () => {
    navigate('/sending');
  };

  const handleNextLetter = () => {
    navigate('/main/letter');
  };

  function calculateDeliveryPeriod(startDate, endDate) {
    const timeDifference = endDate - startDate;
    const daysDifference =
      Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
    console.log('편지 배달기간:', daysDifference);
    return daysDifference;
  }

  getPostboxInfo();

  function showNextModal() {
    firstModal.hide();
    secondModal.show();
  }

  function handleCheckboxChange(event) {
    sendingAgree = event.target.checked;
  }
</script>

<body class="letter-ui">
  <div class="container">
    <div class="head-section">
      <div class="logo-section">
        <img class="text-logo" src="/assets/image/icon/text-logo.png" />
      </div>
      <div class="head-wrapper">
        <span class="head-date">편지 배달기간 {deliveryPeriod}일 중</span>
        <span class="head-letter"
          >지금까지 총 <span class="head-date-written">{letterCount}</span>장의
          <br />편지를 작성했어요
        </span>
        <div class="write-letter-section" />

        <button class="write-btn" on:click={handleNextLetter}>
          <a href="/main/letter">
            <div>
              바로 편지 쓰러가기
              <ChevronRight
                class="write-icon"
                width={20}
                height={24}
                color={'#fff'}
              />
            </div>
          </a>
        </button>
      </div>
      <div class="left-section">
        <div class="left-wrapper">
          <div class="mailbox-icon" />
          <div class="left-text">
            {#if dDayPassed}
              편지 배달일로부터 <span>{dDay}일</span> 지났어요
            {:else}
              편지 배달일까지 <span>{dDay}일</span> 남았어요
            {/if}
          </div>
        </div>
      </div>
      <hr />
    </div>
    <div class="recent-section">
      <div class="end-section">
        <div class="end-letter-wrapper" on:click={() => firstModal.show()}>
          <div class="end-up">
            <div class="end-text">
              <span class="end-maintext"
                >기간 내 계획한<br />
                모든 편지를 작성했어요</span
              >
              <span class="end-subtext">더 이상 작성할 편지가 없다면,</span>
            </div>
            <div class="end-moon" />
          </div>
          <div class="end-btn-section">
            <button class="end-button">
              달에게 편지 부치기
              <div class="arrow-icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <a href="https://www.instagram.com/dalchetong_official">
      <img
        class="icon/instagram-logo"
        src="/assets/image/icon/instagram-logo.png"
        alt=""
      />
    </a>
    <div class="info">
      <p>
        <a
          href="https://misae.notion.site/539191bc947f49e494787e50f0d5a5ae?pvs=4"
        >
          <span>이용약관</span>
        </a>
        <span class="line-hr">|</span>
        <a
          href="https://misae.notion.site/71b33659a4454e0a9e4cd1955d0efbce?pvs=4"
        >
          <span>개인정보처리방침</span>
        </a>
      </p>
      <p>
        모든 문의 사항은 달의 우체통 공식 인스타그램 DM 혹은<br />달의 우체통
        공식 이메일을 통해 접수해주세요.
      </p>
      <p>문의: help@dalchetong.com</p>
    </div>
    <div class="copyright">
      미학적인 세상을 꿈꾸는 사람들, MISAE<br />
      Copyright ⓒMISAE. All rights Reserved
    </div>
  </div>
</body>

<StartEndModal bind:this={firstModal}>
  {#if isSendingFine}
    <div class="warning-modal-title">
      달에게 편지를 부치기 전<br />반드시 확인해주세요.
    </div>
    <div class="modal-warning">
      <div class="text-section">
        <li>
          달에게 편지를 부치면 <span
            >더 이상 새로운 편지를 작성할 수 없어요.</span
          >
        </li>
        <li>
          반드시 계획한 모든 편지 작성을 끝낸 후 달에게 편지를 부쳐주세요.
        </li>
        <li>
          달에게 편지를 부치면 상대방에 전달할 수 있는 우체통 링크가 생성됩니다.
        </li>
      </div>
    </div>
    <div class="agree-section">
      <div class="checkbox-line">
        <input
          type="checkbox"
          bind:checked={sendingAgree}
          on:change={handleCheckboxChange}
          id="agree"
        />
        <label for="agree">
          <div class="agree-text">위의 내용을 모두 확인하였습니다.</div>
        </label>
      </div>
    </div>
    <div class="modal-button-section">
      {#if !sendingAgree}
        <button class="gray-btn">다음으로</button>
      {:else}
        <button class="indigo-btn" on:click={showNextModal}>다음으로</button>
      {/if}
    </div>
  {/if}
</StartEndModal>

<ProgressModal bind:this={secondModal}>
  {#if isSendingFine}
    <div class="modal-title">편지를 부치시겠어요?</div>
    <div class="modal-text">
      최종으로 편지를 접수하면 새로운 편지를 작성하거나 기존 편지를 수정할 수
      없어요. 계획한 모든 편지를 작성했다면 접수해주세요.
    </div>
    <div class="modal-button-section">
      <button class="indigo-btn" on:click={handleSendLetter}
        >최종으로 편지 접수하기</button
      >
      <button class="gray-btn" on:click={() => secondModal.hide()}
        >다시 한 번 확인할래요</button
      >
    </div>
    <!-- {:else}
    <div class="modal-title">텅 빈 편지가 남아있어요!</div>
    <div class="modal-text">
      내용 없는 편지는 날짜 위에 노란 점으로 표시돼요. 돌아가서 빈 편지를
      삭제하거나 편지를 마저 작성한 후 다시 편지를 부쳐주세요.
    </div>
    <div class="modal-button-section">
      <button class="indigo-btn" on:click={() => modal.hide()}
        >빈 편지 확인하러 가기</button
      >
    </div> -->
  {/if}
</ProgressModal>

<style>
  .container {
    position: relative;
    margin: 0 24px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .head-section {
    text-align: left;
    margin-top: 36px;
  }

  .logo-section {
    position: relative;
    display: flex;
    margin-bottom: 16px;
  }

  .logo-section img {
    height: 18px;
  }

  .head-wrapper {
    position: relative;
    height: 216px;
    background-color: #f2f1ff;
    border-radius: 14px;
    border: none;
    padding: 32px 28px;
  }

  .head-date {
    font-size: 12px;
    color: var(--primary-500-color);
    font-family: 'Pretendard_SemiBold';
  }

  .head-letter {
    display: block;
    margin-top: 6px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    line-height: 140%;
    color: var(--black-color);
    font-family: 'Pretendard_Bold';
  }

  .write-btn {
    position: relative;
    margin: auto;
    margin-top: 12px;
    margin-left: 8px;
    width: 156px;
    height: 42px;
    flex-shrink: 0;
    background-color: var(--primary-500-color);
    border-radius: 80px;
    border: none;
  }

  .write-btn div {
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 0px;
    justify-content: space-around;
    font-size: 12px;
    font-family: 'Pretendard_Regular';
    font-weight: 600;
    color: #fff;
  }

  a {
    text-decoration: none;
  }

  .write-letter-section {
    top: 12px;
    right: 0;
    width: 90px;
    height: 78px;
    margin: 0 auto;
    margin-right: -8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/icon/no-letter.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .left-section {
    position: relative;
    margin-top: 24px;
    width: 100%;
    text-align: left;
  }

  .left-wrapper {
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #eff1f4;
    border-radius: 12px;
  }

  .mailbox-icon {
    width: 30px;
    height: 30px;
    margin: 0 12px 0 20px;
    background-image: url('/assets/image/icon/main-mailbox-icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .left-text {
    flex-direction: column;
    color: var(--black-color);
    font-size: 14px;
    font-family: 'Pretendard_Regular';
  }

  .left-text span {
    font-family: 'Pretendard_SemiBold';
  }

  hr {
    border: 0.5px solid #eaecf0;
    margin: 32px 0;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .recent-section {
    position: relative;
    text-align: left;
    margin-bottom: 28px;
  }

  .end-section {
    position: relative;
    text-align: left;
  }

  .end-letter-wrapper {
    height: 180px;
    flex-direction: column;
    padding: 24px;
    margin-bottom: 64px;
    align-items: center;
    background-color: #e6effc;
    border-radius: 10px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .end-up {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .end-moon {
    width: 62px;
    height: 68px;
    margin: auto 0;
    background-image: url('/assets/image/icon/moon-end-icon.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .end-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: var(--black-color);
  }

  .end-subtext {
    font-size: 12px;
    font-family: 'Pretendard_Regular';
    line-height: 150%;
    margin-top: 6px;
  }

  .end-maintext {
    font-size: 16px;
    font-family: 'Pretendard_Bold';
    line-height: 150%;
    display: flex;
    margin: 3px 0;
  }

  .end-btn-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 48px;
  }

  .end-button {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 68px;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Pretendard_Regular';
    text-decoration: none;
    color: #1d2939;
    background-color: var(--gray-25-color);
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .arrow-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/icon/arrow-next-icon-size.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .footer-section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    background-color: var(--gray-100-color);
    font-family: 'Pretendard_Regular';
  }

  .footer-section img {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 24px;
    right: 68px;
    cursor: pointer;
  }

  .info {
    font-size: 12px;
    color: var(--gray-500-color);
  }

  .info p {
    line-height: 150%;
  }

  .info span {
    cursor: pointer;
  }

  .info .line-hr {
    margin: 0 10px;
    color: var(--gray-300-color);
    cursor: none;
  }

  .info p a,
  .info p a:link,
  .info p a:visited {
    color: var(--gray-500-color);
  }

  .copyright {
    display: flex;
    margin-top: 26px;
    font-size: 12px;
    color: var(--gray-400-color);
    line-height: 150%;
  }

  /* First Modal */
  .warning-modal-title {
    width: 100%;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Pretendard_Bold';
    font-size: 24px;
    font-weight: 700;
    color: #070c15;
    line-height: 36px;
    padding: 26px 0px;
  }
  .modal-warning {
    width: 100%;
    height: 170px;
    border-radius: 12px;
    background-color: #f6f6f6;
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .modal-warning .text-section {
    padding: 14px;
    text-align: left;
    color: #475467;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
  }
  li {
    margin: 8px 0px;
    word-break: keep-all;
  }
  .modal-warning span {
    color: var(--semantic-danger-color);
  }
  .agree-section {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .checkbox-line {
    display: flex;
  }
  .agree-text {
    margin-left: 25px;
    color: #070c15;
    font-family: Pretendard;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox'] + label {
    cursor: pointer;
    background: url('/assets/image/icon/checkbox-unchecked.png') no-repeat 0 0px /
      contain;
  }
  input[type='checkbox']:checked + label {
    background: url('/assets/image/icon/checkbox-checked.png') no-repeat 0 0px /
      contain;
  }
</style>
