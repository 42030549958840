<script>
  import { createEventDispatcher } from 'svelte';
  import { isSameMonth, isSameDay, startOfWeek, endOfWeek } from 'date-fns';
  import { localeFormat } from '../utils';
  import { isDisabled } from '../utils/is-disabled';
  import { isChecked } from '../utils/is-date-checked';
  import { isLetter } from '../utils/is-letter';

  export let letterlist;
  export let notEmptyLetterlist;
  export let day;
  export let rtl;
  export let letter = false; // 배달체크
  export let yellow = false; // 노랑체크
  export let hoverDate = new Date();
  import { letterDates } from '../../../../libs/store';
  import {
    letterCheck,
    getIsLetterByDate,
    getIsWrittenByDate,
    checkLetterStatus,
  } from '../../../../libs/checkStore';
  let dayLetterStartDate = new Date();
  let dayLetterEndDate = new Date();

  let dayIsLetter = false;
  let dayIsWritten = false;

  // 날짜 상태 구독
  let unsubscribe;
  unsubscribe = letterDates.subscribe((value) => {
    dayLetterStartDate = value.startDateStore;
    dayLetterEndDate = value.endDateStore;

    //  console.log('Day Store:', dayLetterStartDate, dayLetterEndDate)
  });

  $: dayLetterStartDate = $letterDates.startDateStore;
  $: dayLetterEndDate = $letterDates.endDateStore;

  console.log('시작, 끝', dayLetterStartDate, dayLetterEndDate);

  // console.log('Day:', dayLetterStartDate, dayLetterEndDate)

  // // 날짜 상태 구독
  // unsubscribe = letterCheck.subscribe((value) => {
  //   dayDate = value.selectDate;
  //   dayIsLetter = value.isLetter;
  //   dayIsWritten = value.isWritten;

  //   //  console.log('Day Store:', dayLetterStartDate, dayLetterEndDate)
  // });

  // dayDate = $letterCheck.selectDate
  // dayIsLetter = getIsLetterByDate(dayDate);
  // dayIsWritten = getIsWrittenByDate(dayDate);

  // function dayChecked(dayDate) {
  //   if(getIsLetterByDate(dayDate) && getIsWrittenByDate(dayDate)) return 1;
  //   else if(getIsLetterByDate(dayDate) && !getIsWrittenByDate(dayDate)) return 2;
  //   else return 0
  // }
  $: checkLetterStatus(day.date);
  // console.log('isDateChecked: ', day.date, getIsLetterByDate(day.date), getIsWrittenByDate(day.date), checkLetterStatus(day.date))

  const dispatchEvent = createEventDispatcher();

  //let oneDay = 24 * 60 * 60 * 1000;

  $: isDayPurple =
    isChecked({
      date: day.date,
      isLetter: getIsLetterByDate(day.date),
      isWritten: getIsWrittenByDate(day.date),
    }) === 1;

  $: isDayYellow =
    isChecked({
      date: day.date,
      isLetter: getIsLetterByDate(day.date),
      isWritten: getIsWrittenByDate(day.date),
    }) === 2;

  $: isDayDisabled = isDisabled({
    date: day.date,
    //startDisabledDate: new Date(dayLetterStartDate.getTime() - oneDay),
    startDisabledDate: new Date(dayLetterStartDate.getTime()),
    endDisabledDate: new Date(dayLetterEndDate.getTime()),
  });

  $: isSelected = isSameDay(day.date, hoverDate);

  // 해당 날짜에 편지여부 확인하는 로직
  $: isDayHasLetter = isLetter({
    date: day.date,
    letterlist,
    notEmptyLetterlist,
    startDate: new Date(dayLetterStartDate.getTime()),
    endDate: new Date(dayLetterEndDate.getTime()),
  });

  function onDateSelect() {
    if (isSelected) {
      dayLetterStartDate = day.date; // 시작 날짜 2개 선택 방지
      dispatchEvent('selection', day.date);
    }
  }

  function onKeydown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      dispatchEvent('apply');
    }
  }
</script>

<div
  class:rtl
  class:today={day.isToday}
  class:weekend={day.isWeekend}
  class:next-month={day.isNextMonth}
  class:prev-month={day.isPrevMonth}
  class:start-date={day.isStartDate}
  class:end-date={day.isEndDate}
  class:within-selection={day.isWithinSelection}
>
  <div class="day-section">
    {#if isDayHasLetter === 1}
      <div class="check-letter" />
    {:else if isDayHasLetter === 0}
      <div class="check-yellow" />
      <!--{:else}
      <div class="check-yellew" />-->
    {/if}

    <!--<div class:check-yellow={isDayHasLetter} />-->
    <button
      type="button"
      aria-label={localeFormat(day.date, 'EEEE, MMMM co, yyyy')}
      aria-disabled={isDayDisabled}
      class="calendar-cell"
      disabled={isDayDisabled}
      on:keydown={onKeydown}
      on:click={() => dispatchEvent('selection', day.date)}
      class:selection={isSelected}
      class:check-letter={isDayPurple}
      class:check-yellow={isDayYellow}
    >
      {localeFormat(day.date, 'd')}
    </button>
  </div>
</div>

<style>
  div {
    margin: 2px 0;
  }

  button {
    color: #fff;
    background-color: transparent;
    border-radius: 100%;
    outline: 0;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .day-section {
    justify-content: center;
    /*align-items: center;*/
    display: flex;
  }

  .check-letter {
    width: 4.5px;
    height: 4.5px;
    border-radius: 7px;
    position: absolute;
    display: flex;
    margin-top: 1.6px;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #6c63eb;
    z-index: 999;
  }

  .check-yellow {
    width: 4.5px;
    height: 4.5px;
    border-radius: 7px;
    position: absolute;
    display: flex;
    margin-top: 1.6px;
    text-align: center;
    justify-content: center;
    align-items: center;
    /*background-color: #6c63eb;*/
    background-color: #fffc45;
    z-index: 999;
  }

  button:focus {
    /* border: 1px inset #6C63EB; */
  }

  .within-selection,
  .end-date,
  .start-date {
    background-color: #575b7e;
    color: #fff;
  }

  .end-date {
    border-radius: 0 100% 100% 0;
  }

  .start-date {
    border-radius: 100% 0 0 100%;
  }

  .end-date.start-date {
    border-radius: 100%;
  }

  .selection {
    background-color: #6c63eb;
  }

  .today {
    /* text-decoration: underline; */
  }

  /* 날짜 선택 */
  .start-date button,
  .end-date button,
  button:not(:disabled):hover {
    background-color: #6c63eb;
    color: white;
  }

  .next-month button,
  .prev-month button,
  button:disabled {
    opacity: 0.54;
  }

  /* Swap border radius when in rtl */
  .rtl.end-date {
    border-radius: 100% 0 0 100%;
  }

  .rtl.start-date {
    border-radius: 0 100% 100% 0;
  }
</style>
