import { navigate } from 'svelte-routing/src/history';
import axios from "../libs/axios"

export async function errorHandler(error) {
  try {
    console.log(error);
    const currentPageURL = window.location.pathname;
    const status = parseInt(error.response.status);
  
    if (status > 400 && status < 500) {
      if (currentPageURL.includes("/lover/")) {
        console.log(currentPageURL);
        const lastSlashIndex = currentPageURL.lastIndexOf('/');
        const id = currentPageURL.substring(lastSlashIndex + 1);
        let postboxId;
  
        const endpointsUsingLetterid = ["letter", "reply"];
        if (endpointsUsingLetterid.some(endpoint => currentPageURL.includes(endpoint))) {
          postboxId = await findPostboxByLetterId(id);
        }
        else {
          postboxId = id;
        }
  
        console.log(postboxId);
        if (postboxId) {
          navigate(`/lover/${postboxId}`);
        }
      }
      else {
        navigate('/onboarding');
      }
    } else if (status === 400 || status >= 500) {
      alert(error.response.data.detail);
      history.back();
    }
  } catch (error) {
    console.log(error);
    //navigate('/onboarding');
  }

}

async function findPostboxByLetterId(letterId) {
  try {
    const response = await axios.get(`api/rcpt/letter/${letterId}`);
    const postboxId = response.data.postbox;

    return postboxId;
  } catch (error) {
    navigate('/onboarding');
  }
}