<script>
  import Week from './Week.svelte';
  import DaysOfWeek from './DaysOfWeek.svelte';
  import Controls from './Controls.svelte';
  import { getCalendarWeeks, dayOffset } from '../utils';

  export let letterlist;
  export let notEmptyLetterlist;
  export let disabledDates;
  export let events;
  export let hasSelection;
  export let hoverDate;
  export let firstDayOfWeek;
  export let isoWeekNumbers;
  export let maxDate;
  export let minDate;
  export let month;
  export let monthDropdown;
  export let monthFormat;
  export let monthIndicator;
  export let pageWidth;
  export let rtl;
  export let prevIcon;
  export let nextIcon;
  export let singlePicker;
  export let tempTheEndDate;
  export let tempStartDate;
  export let today;
  export let weekGuides;
  export let weekNumbers;
  export let yearDropdown;
  export let letter = false; // 배달체크
  export let yellow = false; // 노랑체크

  $: weeks = getCalendarWeeks({
    month,
    firstDayOfWeek,
    events,
    disabledDates,
    hoverDate,
    hasSelection,
    minDate,
    maxDate,
    today,
    singlePicker,
  });
</script>

<div style={`width: ${pageWidth}px;`}>
  <Controls
    on:pageChange
    on:prevMonth
    on:nextMonth
    {prevIcon}
    {nextIcon}
    {month}
    {monthDropdown}
    {monthFormat}
    {maxDate}
    {minDate}
    {yearDropdown}
  />
  <DaysOfWeek {firstDayOfWeek} />
  {#each weeks as week}
    <Week
      on:selection
      on:hover
      on:apply
      {week}
      {month}
      {monthIndicator}
      {rtl}
      {weekGuides}
      {weekNumbers}
      {isoWeekNumbers}
      {letter}
      {yellow}
      {tempStartDate}
      {tempTheEndDate}
      {hoverDate}
      {letterlist}
      {notEmptyLetterlist}
    />
  {/each}
</div>

<style>
  div {
    padding: 0px 48px;
  }
</style>
