import { writable } from 'svelte/store';

// let startDateStore = new Date();
// let endDateStore = new Date()

// 초기값
export const letterDates = writable({
  startDateStore: new Date(),
  endDateStore: new Date(),
});

// 값 업데이트
export function setLetterDates(startDate, endDate) {
  letterDates.set({
    startDateStore: startDate,
    endDateStore: endDate,
  });

  console.log('UpdatedStore:', startDate, endDate);
}
