<script>
  import { navigate } from "svelte-routing";
  import {
    getLetterCoverType,
    getLetterCoverImagePath,
  } from "./LetterCardSetter";

  export let letterId = "";
  export let letterDate = "";
  export let letterCover = getLetterCoverType(letterId);
  export let isReply = false;

  const currentURL = window.location.pathname;
  const pageFor = currentURL.includes("/lover/")
    ? "lover"
    : currentURL.includes("/me/")
      ? "me"
      : "";

  const handleNext = () => {
    navigate(`/${pageFor}/letter/${letterId}`);
  };

  function getReplyCheck(reply) {
    if (isReply === true) {
      return "답장";
    } else return "";
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="container" on:click={handleNext}>
  <div
    class="letter-img"
    style="background-image: url({getLetterCoverImagePath(letterCover)})"
  />
  <div class="letter-date">{letterDate}</div>
  <div class="read-check">
    <span>{getReplyCheck(isReply)}</span>
    {#if isReply}
      <img src="/assets/image/check-icon-2.svg" />
    {/if}
  </div>
</div>

<style>
  .container {
    min-width: 260px;
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
    margin: 8px 28px;
    /* padding: 0 24px; */
    border-radius: 10px;
    background-color: var(--gray-25-color);
    text-align: center;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .container div {
    font-family: "Pretendard_Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  .letter-img {
    margin-left: 16px;
    width: 60px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .letter-date {
    position: absolute;
    left: 86px;
    font-size: 14px;
    font-family: "Pretendard_Medium";
    color: var(--gray-600-color);
  }

  .read-check {
    position: absolute;
    right: 16px;
    display: flex;
    color: var(--secondary-400-color);
    justify-content: center;
    align-items: center;
  }

  .read-check span {
    opacity: 0.48;
  }

  .read-check img {
    width: 18px;
    height: 18px;
    margin: auto;
    margin-left: 4px;
    display: inline;
  }
</style>
