<script>
  import { onMount } from 'svelte';
  import CalenderPicker from '../../components/Main/date-range-picker/CalenderPicker.svelte';
  import Navbar from '../../components/Main/Navbar.svelte';
  import Button from '../../components/Button.svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';
  import Toast from '../../components/Toast.svelte';
  import ProgressModal from '../../components/ProgressModal.svelte';
  import { navigate } from 'svelte-routing';
  import { calculateDDay } from '../../utils/DdayCalculator';

  let isWritten = false; // 작성 유무
  let isLetter = false; // 빈 편지라면 false
  let isYellow = false; // 노랑체크
  let isCelebrate = true;
  let remainDay = 0;
  let dDayPassed = false;
  let open = true;
  let letters = [];
  export let letterlist = [];
  export let notEmptyLetterlist = [];

  let letterBody = '';
  let today = new Date();
  let letterDayCount = 1;
  import { setLetterCheck } from '../../libs/checkStore';
  import { setLetterDates } from '../../libs/store';
  export let letter_start_date = new Date();
  export let letter_end_date = new Date();
  export let newEndDate = letter_start_date;
  let selectDate = new Date(letter_start_date);
  let selectDateText = '';
  export let hoverDate = new Date(selectDate);

  let ah_yeonaehagosipda_end_date = new Date();
  let d_day_count = 0;
  let selectedLetter = null;
  let postboxId = null;

  let deleteCheckModal;
  let toast;

  async function getPostboxId() {
    try {
      const response_1 = await axios.get('/api/postbox');
      console.log('백엔드 응답:', response_1.data);

      if (response_1.data.results[0].is_link_sent) {
        navigate('/sending/end');
      }

      postboxId = response_1.data.results[0].id;
      letter_start_date = new Date(
        response_1.data.results[0].letter_start_date
      );
      letter_end_date = new Date(response_1.data.results[0].letter_end_date);
      ah_yeonaehagosipda_end_date = new Date(
        response_1.data.results[0].ah_yeonaehagosipda_end_date
      );

      const response_2 = await axios.get(`/api/postbox/${postboxId}/letter`);
      console.log('백엔드_ 응답2:', response_2.data.results);
      letters = response_2.data.results;
      for (const result of letters) {
        letterlist.push(result.date);
        if (result.body !== null) {
          notEmptyLetterlist.push(result.date);
        }
      }
      remainDay = calculateDDay(letter_start_date)[0];
      dDayPassed = calculateDDay(letter_start_date)[1];

      let storeDateData = {
        startDateStore: letter_start_date,
        endDateStore: letter_end_date,
      };
      setLetterDates(storeDateData.startDateStore, storeDateData.endDateStore);
      console.log(
        'Main Store Data',
        storeDateData.startDateStore,
        storeDateData.endDateStore
      );

      // today = new Date(
      //   response_1.data.results[0].letter_start_date
      // );
      handleSelectDateChange({
        detail: {
          newEndDate: new Date(response_1.data.results[0].letter_start_date),
        },
      });

      console.log('Main date', letter_start_date, letter_end_date);
    } catch (error) {
      errorHandler(error);
    }
  }
  function handleFixLetter() {
    if (selectedLetter) {
      window.location.href = `/letter/${selectedLetter.id}`;
    }
  }

  function handleSendLetter() {
    const dateString =
      selectDate.getFullYear() +
      '-' +
      (selectDate.getMonth() + 1) +
      '-' +
      selectDate.getDate();
    axios
      .post(`/api/postbox/${postboxId}/letter`, {
        date: dateString,
      })
      .then((response) => {
        console.log('편지 작성 응답:', response.data);
        window.location.href = `/letter/${response.data.id}`;
      })
      .catch((error) => {
        errorHandler(error);
      });
  }

  function handleSelectDateChange(event) {
    // selectDate = storeDateData.startDateStore;
    selectDate = event.detail.newEndDate;
    hoverDate = selectDate;
    console.log('메인페이지에서 선택 날짜:', selectDate);
    selectedLetter = getLetterFromDate(selectDate);
    console.log('selectedLetter:', selectedLetter);
    selectDateText = formatDate(selectDate);

    if (selectedLetter) {
      isLetter = true;
      letterBody = selectedLetter.body;
      if (letterBody && letterBody.length > 0) {
        isWritten = true;
      } else {
        isWritten = false;
      }
    } else {
      isLetter = false;
      isWritten = false;
      letterBody = null;
    }

    let storeCheckData = {
      selectDate: selectDate,
      isLetter: isLetter,
      isWritten: isWritten,
    };
    setLetterCheck(
      storeCheckData.selectDate,
      storeCheckData.isLetter,
      storeCheckData.isWritten
    );
    console.log(
      'Main Store Check Data',
      storeCheckData.selectDate,
      storeCheckData.isLetter,
      storeCheckData.isWritten
    );

    // set letterDayCount to the difference between selected date and letter_start_date
    letterDayCount = Math.floor(
      (selectDate - letter_start_date) / (1000 * 60 * 60 * 24) + 1
    );
    d_day_count = Math.floor(
      (selectDate - ah_yeonaehagosipda_end_date) / (1000 * 60 * 60 * 24) + 1
    );
  }

  const handleOpen = () => {
    open = !open;
  };

  function formatDate(dateString) {
    const options = { month: 'long', day: 'numeric', weekday: 'long' };
    const date = new Date(dateString);
    const koreanDate = date.toLocaleDateString('ko-KR', options);
    return koreanDate;
  }

  function deleteLetter() {
    if (selectedLetter) {
      axios
        .delete(`/api/letter/${selectedLetter.id}`)
        .then((response) => {
          toast.show('편지가 정상적으로 삭제되었어요!');
          console.log('편지 삭제 응답:', response.data);
          window.location.reload();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }

  function getLetterFromDate(date) {
    let setDate = '';
    let setMonth = '';

    if (date.getDate().toString().length === 1) {
      setDate = '0' + date.getDate();
    } else {
      setDate = date.getDate();
    }

    if ((date.getMonth() + 1).toString().length === 1) {
      setMonth = '0' + (date.getMonth() + 1);
    } else {
      setMonth = date.getMonth() + 1;
    }

    let changedselectdate = date.getFullYear() + '-' + setMonth + '-' + setDate;
    const result = letters.find((letter) => letter.date === changedselectdate);
    return result || null;
  }

  onMount(async () => {
    getPostboxId();
    handleSelectDateChange();
  });

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setScreenSize();
</script>

<Toast bind:this={toast} />

<body class="main-ui">
  <Navbar {remainDay} {dDayPassed} />
  <div class="calender-container">
    <CalenderPicker
      on:newEndDateChange={handleSelectDateChange}
      {newEndDate}
      letter={isLetter}
      yellow={isYellow}
      tempStartDate={selectDate}
      tempTheEndDate={letter_end_date}
      letterStartDate={letter_start_date}
      letterEndDate={letter_end_date}
      hoverDate={selectDate}
      {letterlist}
      {notEmptyLetterlist}
    />
  </div>
  <div class="bottom-section">
    <div class="bottomsheet">
      <div class="container">
        <div class="main-wrapper">
          {#if isWritten && isLetter}
            <div class="head-section">
              {#if isCelebrate}
                <div class="celebrate-section">
                  ♥{d_day_count >= 0 ? '+' : ''}{d_day_count}
                </div>
              {/if}
              <span class="head-letter">편지 배달 {letterDayCount}일차</span>
              <span class="head-date">{selectDateText}</span>
            </div>
            <div class="letter-section">
              <div class="yes-letter-section">
                <div class="letter-img" />
                <div class="letter-wrapper">
                  <div class="letter-text">
                    <p>{letterBody}</p>
                  </div>
                  <div class="letter-write-date">
                    {#if selectedLetter}
                      <li on:click={() => deleteCheckModal.show()}>삭제하기</li>
                    {/if}
                    <li>
                      <circle cx="2" cy="2" r="2" fill="#98A2B3" />
                    </li>
                    <!-- <li>2023.08.22 작성</li> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="button-section" on:click={handleFixLetter}>
              <Button class="modal-ui">편지 꺼내서 고치기</Button>
            </div>
          {/if}
          {#if !isWritten && isLetter}
            <div class="head-section">
              <div class="letter-section">
                {#if isCelebrate}
                  <div class="celebrate-section">
                    ♥{d_day_count >= 0 ? '+' : ''}{d_day_count}
                  </div>
                {/if}
                <span class="head-letter">편지 배달 {letterDayCount}일차</span>
                <span class="head-date">{selectDateText}</span>
              </div>
              <div class="letter-section">
                <div class="yes-letter-section">
                  <div class="letter-img" />
                  <div class="letter-wrapper">
                    <div class="no-letter-text">작성된 편지 내용이 없어요</div>
                    <div class="letter-write-date">
                      <li on:click={() => deleteCheckModal.show()}>삭제하기</li>
                      <li>
                        <circle cx="2" cy="2" r="2" fill="#98A2B3" />
                      </li>
                      <!-- <li>2023.08.22 작성</li> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-section" on:click={handleFixLetter}>
                <Button class="modal-ui">편지 꺼내서 고치기</Button>
              </div>
            </div>
          {/if}
          {#if !isLetter}
            <div class="head-section">
              {#if isCelebrate}
                <div class="celebrate-section">
                  ♥{d_day_count >= 0 ? '+' : ''}{d_day_count}
                </div>
              {/if}
              <span class="head-letter">편지 배달 {letterDayCount}일차</span>
              <span class="head-date">{selectDateText}</span>
            </div>
            <div class="letter-section">
              <div class="no-letter-section" />
              <p class="no-letter-check">아직 작성된 편지가 없어요</p>
            </div>
            <div class="button-section" on:click={handleSendLetter}>
              <Button class="start-ui">편지 쓰러가기</Button>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</body>

<ProgressModal bind:this={deleteCheckModal}>
  <div class="modal-title">편지를 삭제하시겠어요?</div>
  <div class="modal-text">
    편지를 삭제하면 다시 복구할 수 없어요.<br />삭제하기를 누르면 선택한 편지의
    내용과<br />첨부한 파일이 모두 삭제됩니다.
  </div>
  <div class="modal-button-section">
    <button class="indigo-btn" on:click={deleteLetter}>삭제하기</button>
    <button class="gray-btn" on:click={() => deleteCheckModal.hide()}
      >다시 한 번 확인할래요</button
    >
  </div>
</ProgressModal>

<style>
  body {
    margin: 0;
    padding: 0;
  }

  .calender-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .bottom-section {
    position: relative;
    display: flex;
    padding-top: 10px;
  }

  .bottomsheet {
    width: 100%;
    min-height: calc(60 * var(--vh, 1vh) + 200px);
  }

  .bottomsheet .container {
    width: 100%;
    height: 100%;
    /* min-height: calc(108 * var(--vh, 1vh) - 200px); */
    border-radius: 40px 40px 0 0;
    border: none;
    color: #000;
    background-color: #f9fafb;
    align-items: center;
    z-index: 2;
  }

  .bottomsheet .main-wrapper {
    position: relative;
    margin: auto;
    /* height: calc(100vh - 280px); */
    padding: 10px 28px 0px 28px;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-y: hidden;
    /* overflow-y: auto; */
  }

  .bottomsheet .letter-wrapper {
    width: 100%;
    display: flex;
    left: -20px;
    flex-direction: row;
    gap: 10px;
  }

  .bottomsheet .head-section {
    text-align: left;
    margin-top: 32px;
  }

  .bottomsheet .head-letter {
    font-size: 14px;
    font-family: 'Pretendard_Regular';
    color: var(--gray-600-color);
  }

  .bottomsheet .head-date {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Pretendard_Bold';
    color: var(--black-color);
    margin-top: 4px;
  }

  .bottomsheet .celebrate-section {
    position: absolute;
    display: flex;
    top: 40px;
    right: 32px;
    margin: auto 0;
    width: 78px;
    height: 26px;
    background-color: var(--primary-25-color);
    color: var(--primary-500-color);
    border-radius: 12px;
    font-size: 14px;
    font-family: 'Pretendard_Medium';
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .bottomsheet .letter-section {
    margin: 24px 0px;
  }

  .bottomsheet .no-letter-section {
    margin-top: 100px;
    width: 128px;
    height: 148px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/image/icon/no-letter.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bottomsheet .yes-letter-section {
    top: 0;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }

  .bottomsheet .letter-img {
    width: 80px;
    height: 36px;
    margin: 0 auto;
    margin-right: 14px;
    margin-bottom: 72px;
    position: relative;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    background-image: url('/assets/image/letter-cover/letter-cover-1.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bottomsheet .letter-wrapper {
    display: flex;
    flex-direction: column;
  }

  .bottomsheet .letter-text {
    min-width: 236px;
    width: 100%;
    max-height: 84px;
    border-radius: 8px;
    background-color: var(--gray-200-color);
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
    font-family: 'Pretendard_Medium';
    text-align: left;
    line-height: 150%;
    flex-shrink: 0;
  }

  .bottomsheet .no-letter-text {
    min-width: 236px;
    width: 100%;
    height: 84px;
    margin: auto;
    display: flex;
    border-radius: 8px;
    background-color: var(--gray-200-color);
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--gray-400-color);
    font-size: 14px;
    font-family: 'Pretendard_Medium';
  }

  .bottomsheet .letter-text p {
    margin: 12px;
    /* min-width: 236px; */
    max-height: 66px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    color: var(--black-color);
    font-size: 14px;
    font-weight: 500;
    font-family: 'Pretendard_Medium';
  }

  .bottomsheet .letter-write-date {
    display: flex;
    flex-direction: row;
    color: var(--gray-400-color);
    font-size: 12px;
    font-family: 'Pretendard_Regular';
    font-weight: 400;
    line-height: 150%;
    justify-content: flex-end;
    align-items: center;
  }

  .bottomsheet .letter-write-date li {
    display: flex;
    list-style: none;
    margin: 0 2px;
    justify-content: center;
    align-items: center;
  }

  .bottomsheet .no-letter-check {
    font-size: 14px;
    color: var(--gray-400-color);
    font-family: 'Pretendard_Regular';
  }

  .button-section {
    /* margin: 96px 20px 48px 20px; */
    width: 100%;
    position: relative;
    display: flex;
    margin: auto;
    margin-top: 96px;
    margin-bottom: 28px;
    align-items: center;
    justify-content: center;
  }
</style>
