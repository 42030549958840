<script>
  let shown = false;

  export function show() {
    shown = true;
  }
  export function hide() {
    shown = false;
  }
</script>

<svelte:window
  on:keydown={(e) => {
    if (e.keyCode === 27) {
      hide();
    }
  }}
/>

{#if shown}
  <div class="modal-wrapper">
    <div class="modal-section">
      <div class="moon-section">
        <img src="/assets/image/icon/moon-icon-start.svg" alt="" />
      </div>
      <slot />
    </div>
    <div class="modal-dimmed" on:click={() => hide()} />
  </div>
{/if}

<style>
  .modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .modal-section {
    position: relative;
    min-width: 248px;
    max-width: 263px;
    min-height: 628px;
    max-height: 628px;
    border-radius: 18px;
    background-color: #fcfcfd;
    z-index: 999;
    margin: auto;
    padding: 10px 30px;
    overflow: hidden;
  }
  .modal-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
  }
  .moon-section {
    margin-top: 36px;
    text-align: center;
    margin-bottom: 24px;
  }
</style>
