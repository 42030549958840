<script>
  import { isBefore, format } from 'date-fns';
  import Day from './Day.svelte';
  import { localeFormat } from '../utils';

  export let letterlist;
  export let notEmptyLetterlist;
  export let isoWeekNumbers;
  export let month;
  export let monthIndicator;
  export let rtl;
  export let week;
  export let weekGuides;
  export let weekNumbers;
  export let letter = false; // 배달체크
  export let yellow = false; // 노랑체크
  export let tempStartDate;
  export let tempTheEndDate;
  export let hoverDate;

  $: weeksFromToday = function (week) {
    if (week.weeksFromToday > 0) {
      return `+${week.weeksFromToday}`;
    }

    return week.weeksFromToday;
  };
</script>

<div aria-label={`${localeFormat(month, 'yyyy')}`} class="calendar-row">
  {#if weekGuides && week.weeksFromToday}
    <div class="relative calendar-row side-width left-side">
      <span aria-label={`${week.weeksFromToday} weeks from today`}>
        {weeksFromToday(week)}
      </span>
    </div>
  {/if}

  <div class="calendar-row">
    {#each week.daysInWeek as day (day.date.toString())}
      <Day
        {letterlist}
        {notEmptyLetterlist}
        {day}
        {monthIndicator}
        {rtl}
        {letter}
        {yellow}
        {tempStartDate}
        {tempTheEndDate}
        {hoverDate}
        on:selection
        on:hover
        on:apply
      />
    {/each}
  </div>
  {#if weekNumbers || isoWeekNumbers}
    <div class="relative calendar-row side-width right-side">
      {#if weekNumbers}
        <span aria-label={`Week ${week.weekNumber}`}>{week.weekNumber}</span>
      {/if}
      {#if isoWeekNumbers}
        <span aria-label={`Week ${week.isoWeekNumber}`}>
          i{week.isoWeekNumber}
        </span>
      {/if}
    </div>
  {/if}
</div>

<style>
  .relative {
    position: relative;
  }

  span {
    font-size: 0.7rem;
    color: #667085;
  }

  .side-width {
    width: 20px;
  }

  .side-width span {
    position: absolute;
  }

  .left-side span {
    left: -36px;
  }

  .right-side span:first-child {
    left: 4px;
  }

  .right-side span:nth-child(2) {
    left: 24px;
  }
</style>
