<script>
  import { navigate } from 'svelte-routing';
  import { slide } from 'svelte/transition';
  import { onMount } from 'svelte';
  import axios from '../../libs/axios';
  import { errorHandler } from '../../utils/errorHandler';

  import LoverBottomSheet from '../../components/Lover/LoverBottomSheet.svelte';
  import LoverSidebar from '../../components/Lover/LoverSidebar.svelte';
  import LoverModal from '../../components/Lover/LoverModal.svelte';
  import InfoMoon from '../../components/Lover/InfoMoon.svelte';
  import Theme from '../../components/Theme.svelte';
  import '../../../public/sidebar.css';

  import { loverMoonData } from '../../stores/loverMoonData';
  import Button from '../../components/Button.svelte';
  import { min } from 'date-fns';

  export let postboxId;
  let title = '',
    titleLength;

  let showPostbox = false; // 데이터 받기 전 렌더링 방지
  let isPostboxOpen = false;
  let isLetterDeliveryCompleted = false; // 편지 배달이 완료된 우체통인지
  let unreadLetterCount = 0;

  let nextLetterDateTime;
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  let modal;
  let showTutorial = false; // 튜토리얼 모달 노출 여부

  getLetterDeliveryInfo();

  async function getLetterDeliveryInfo() {
    try {
      const postboxResponse = await axios.get(`/api/rcpt/postbox/${postboxId}`);
      const letterlistResponse = await axios.get(
        `/api/rcpt/postbox/${postboxId}/letter`
      );

      const postboxData = postboxResponse.data;
      const letterlistData = letterlistResponse.data;
      console.log(postboxData, letterlistData);

      // 우체통 타이틀 설정
      title = postboxData.title;
      titleLength = title.length;

      // 편지 읽은 적 없는 우체통에 한해 튜토리얼 모달 노출
      if (letterlistData.results.some((item) => item['viewed_at'] !== null)) {
        showTutorial = false;
      } else {
        showTutorial = true;
      }

      // 편지 배달이 완료된 우체통인지 확인
      const lastLetterDate = postboxData.letter_end_date;
      const lastLetterTime = postboxData.letter_send_time;
      const lastLetterDateTime = new Date(
        `${lastLetterDate}T${lastLetterTime}`
      );

      const nextLetterDate = letterlistData.next_letter_date;
      const nextLetterTime = postboxData.letter_send_time;

      const currentDateTime = new Date();

      if (nextLetterDate) {
        // 아직 배달되지 않은 편지가 있을 경우에만 다음 편지 배달 시각 설정
        nextLetterDateTime = new Date(`${nextLetterDate}T${nextLetterTime}`);
        updateRemainingTime();
      } else {
        isLetterDeliveryCompleted = true;
      }

      if (currentDateTime >= lastLetterDateTime) {
        isLetterDeliveryCompleted = true;
      }

      // 안 읽은 편지 카운트 (추후 재확인 필요)
      let letters = letterlistData.results;
      for (let i = 0; i < letters.length; i++) {
        if (letters[i].viewed_at === null) {
          unreadLetterCount++;
        }
      }
      showPostbox = true;
    } catch (error) {
      errorHandler(error);
    }
  }

  function updateRemainingTime() {
    if (!nextLetterDateTime) return;

    const currentDateTime = new Date().getTime();
    const remainingTime = nextLetterDateTime.getTime() - currentDateTime;

    days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    hours = Math.floor(
      (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      location.reload();
      return;
    }
  }

  const interval = setInterval(updateRemainingTime, 1000);

  let isOpen = false;

  function toggleMenu() {
    isOpen = !isOpen;
    isOpen = true;
    // console.log('메인 파일: ', isOpen)
  }

  let isLoading = false;
  let showBottomSheet = false;

  async function handleBox() {
    isLoading = true; // 로딩 상태 활성화
    await delay(1700);
    isPostboxOpen = true;
    showBottomSheet = true;

    // isLoading = false; // 로딩 상태 비활성화
  }

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleNext = () => {
    navigate(`/lover/letterlist/${postboxId}`);
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<Theme>
  <body class="lover-ui">
    <div class="menu-icon" on:click={toggleMenu}>
      <img src="/assets/image/icon/menu-icon-size.svg" alt="" />
    </div>
    <LoverSidebar {isOpen} />
    {#if showPostbox}
      <div class="text-section">
        <div class="underline-section">
          <h3 class="title">{title}를 위한 달의 우체통</h3>
          <div
            class="main-title-underline"
            style="width: {titleLength * 10 + 260}px;"
          />
        </div>
      </div>
      {#if isLetterDeliveryCompleted}
        <div class="time-section">
          <p>모든 편지가 배달되었어요 :)</p>
        </div>
      {:else}
        <div class="time-section">
          <p>다음 편지 배달까지</p>
          <p class="remain-time">
            <span>{days}</span>일 <span>{hours}</span>시간
            <span>{minutes}</span>분
            <span>{seconds}</span>초
          </p>
        </div>
      {/if}
      {#if isLoading}
        <div class="mailbox-open-container" on:click={handleBox}>
          <img
            src="/assets/image/mailbox/mailbox-lover-letter-open-size.png"
            alt=""
          />
        </div>
        <div class="button-section" disabled={isLoading}>
          우체통이 열렸어요! 편지를 불러올게요..
        </div>
        {#if isPostboxOpen}
          {#if showBottomSheet}
            <div
              class="bg-dimmed"
              on:click={() => (showBottomSheet = !showBottomSheet)}
            />
            <div class="bottom-section" transition:slide>
              <LoverBottomSheet {postboxId} />
            </div>
          {/if}
        {/if}
      {:else}
        {#if isLetterDeliveryCompleted}
          <div class="mailbox-container-completed">
            <img
              src="/assets/image/mailbox/mailbox-sender-finish-size.png"
              alt=""
            />
          </div>
        {:else if unreadLetterCount === 0}
          <div class="mailbox-container-0" on:click={handleBox}>
            <img
              src="/assets/image/mailbox/mailbox-basic-shadow-size.png"
              alt=""
            />
          </div>
        {:else if unreadLetterCount === 1}
          <div class="mailbox-container-1" on:click={handleBox}>
            <img
              src="/assets/image/mailbox/mailbox-lover-letter-1-size.png"
              alt=""
            />
          </div>
        {:else if unreadLetterCount === 2}
          <div class="mailbox-container-2" on:click={handleBox}>
            <img
              src="/assets/image/mailbox/mailbox-lover-letter-2-size.png"
              alt=""
            />
          </div>
        {:else if unreadLetterCount >= 3}
          <div class="mailbox-container-3" on:click={handleBox}>
            <img
              src="/assets/image/mailbox/mailbox-lover-letter-3-size.png"
              alt=""
            />
          </div>
        {/if}

        {#if isLetterDeliveryCompleted}
          <div class="button-section">
            <Button class="start-ui" handleClick={handleNext}
              >보관된 편지들 보러가기</Button
            >
          </div>
        {:else}
          <div class="button-section" disabled={isLoading}>
            우체통을 터치해 편지를 확인하세요!
          </div>
        {/if}
      {/if}
    {/if}
  </body>
</Theme>

<LoverModal shown={showTutorial} bind:this={modal}>
  <div class="info-modal-wrapper">
    <h3 class="info-modal-title">
      비밀코드가 확인되었습니다.<br />이제 달의 우체통이 열렸어요!
    </h3>
    <div class="title-underline" />
    <div class="info-modal-text">
      {#each loverMoonData as { moonLogo, moonTitle, moonInfo }}
        <div class="moon-section">
          <InfoMoon {moonLogo} {moonTitle} {moonInfo} />
        </div>
      {/each}
    </div>
  </div>
</LoverModal>

<style>
  /* body {
    overflow-y: hidden;
  } */

  .bg-dimmed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #060817;
    opacity: 0.75;
    z-index: 8;
  }

  .text-section {
    position: relative;
    margin: auto;
    top: 76px;
    margin-bottom: 36px;
    text-align: center;
    justify-content: center;
    z-index: 6;
  }

  .underline-section {
    width: 100%;
    position: relative;
    display: flex;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .main-title-underline {
    position: absolute;
    top: 52px;
    height: 12px;
    margin: auto 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: 0.16;
    background-color: #fff;
    z-index: 7;
  }

  .time-section {
    position: relative;
    margin: auto;
    margin-top: 64px;
    margin-bottom: 16px;
    line-height: 150%;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Pretendard_Medium';
    text-align: center;
    justify-content: center;
  }

  .time-section .remain-time {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Pretendard_Regular';
    line-height: 120%;
  }

  .time-section span {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Pretendard_SemiBold';
    margin-left: 4.8px;
  }

  .title {
    font-size: 28px;
    font-family: 'Sebang_Regular';
    margin-bottom: 36px;
  }

  .mailbox-container-completed,
  .mailbox-container-0,
  .mailbox-container-1,
  .mailbox-container-2,
  .mailbox-container-3,
  .mailbox-open-container {
    height: calc(50 * var(--vh, 1vh) + 38px);
    right: 12px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mailbox-container-completed img,
  .mailbox-container-0 img,
  .mailbox-container-1 img,
  .mailbox-container-2 img,
  .mailbox-container-3 img,
  .mailbox-open-container img {
    width: auto;
    height: calc(100% - 12px);
  }

  .button-section {
    position: relative;
    display: flex;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Pretendard_Medium';
    justify-content: center;
    margin: 10px 0 24px 0;
    cursor: pointer;
    z-index: 7;
  }

  .info-modal-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8px;
  }

  .info-modal-title {
    color: var(--black-color);
    font-family: 'Sebang_Regular';
    line-height: 150%;
    font-size: 18px;
    font-weight: 400;
  }

  .title-underline {
    position: absolute;
    top: 58px;
    left: 34px;
    width: 230px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 1px;
    opacity: 0.7;
    background: var(--gray-200, #eaecf0);
    z-index: -1;
  }

  .info-modal-text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    min-height: 216px;
    gap: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .moon-section {
    margin-top: 0;
  }

  .bottom-section {
    position: fixed;
    bottom: 0;
    z-index: 9;
  }

  .button-section {
    position: relative;
    display: flex;
    justify-content: center;
    /* margin: 18px 40px 24px 40px; 하단으로 내림 */
    margin: 18px 40px 52px 40px;
  }
</style>
