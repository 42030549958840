import { expoOut } from 'svelte/easing';

export function horizontalSlide(
  node,
  { delay = 0, duration = 400, easing = expoOut, axis = 'x', closing = false } = {}
) {
  const style = getComputedStyle(node);
  const opacity = +style.opacity;
  const primary_property = axis === 'y' ? 'height' : 'width';
  const primary_property_value = parseFloat(style[primary_property]);
  const secondary_properties =
    axis === 'y' ? ['top', 'bottom'] : ['left', 'right'];
  const capitalized_secondary_properties = secondary_properties.map(
    (e) => `${e[0].toUpperCase()}${e.slice(1)}`
  );
  const padding_start_value = parseFloat(
    style[`padding${capitalized_secondary_properties[0]}`]
  );
  const padding_end_value = parseFloat(
    style[`padding${capitalized_secondary_properties[1]}`]
  );
  const margin_start_value = parseFloat(
    style[`margin${capitalized_secondary_properties[0]}`]
  );
  const margin_end_value = parseFloat(
    style[`margin${capitalized_secondary_properties[1]}`]
  );
  const border_width_start_value = parseFloat(
    style[`border${capitalized_secondary_properties[0]}Width`]
  );
  const border_width_end_value = parseFloat(
    style[`border${capitalized_secondary_properties[1]}Width`]
  );

  if (closing) {
    return {
      delay,
      duration,
      easing,
      css: (t) =>
        'overflow: hidden;' +
        `opacity: ${1 - t * 20 * opacity};` +
        `${primary_property}: ${primary_property_value - t * primary_property_value}px;` +
        `padding-${secondary_properties[0]}: ${
          padding_start_value - t * padding_start_value
        }px;` +
        `padding-${secondary_properties[1]}: ${
          padding_end_value - t * padding_end_value
        }px;` +
        `margin-${secondary_properties[0]}: ${
          margin_start_value - t * margin_start_value
        }px;` +
        `margin-${secondary_properties[1]}: ${
          margin_end_value - t * margin_end_value
        }px;` +
        `border-${secondary_properties[0]}-width: ${
          border_width_start_value - t * border_width_start_value
        }px;` +
        `border-${secondary_properties[1]}-width: ${
          border_width_end_value - t * border_width_end_value
        }px;`,
    };
  } else {
    return {
      delay,
      duration,
      easing,
      css: (t) =>
        'overflow: hidden;' +
        `opacity: ${Math.min(t * 20, 1) * opacity};` +
        `${primary_property}: ${t * primary_property_value}px;` +
        `padding-${secondary_properties[0]}: ${t * padding_start_value}px;` +
        `padding-${secondary_properties[1]}: ${t * padding_end_value}px;` +
        `margin-${secondary_properties[0]}: ${t * margin_start_value}px;` +
        `margin-${secondary_properties[1]}: ${t * margin_end_value}px;` +
        `border-${secondary_properties[0]}-width: ${
          t * border_width_start_value
        }px;` +
        `border-${secondary_properties[1]}-width: ${
          t * border_width_end_value
        }px;`,
    };
  }
}
