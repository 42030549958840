<script>
  import { navigate } from "svelte-routing";
  import { Pincode, PincodeInput } from "../Start/svelte-pincode";
  import BackspaceOutline from "svelte-material-icons/BackspaceOutline.svelte";
  import axios from "../../libs/axios";
  import { errorHandler } from "../../utils/errorHandler";

  export let postboxId;
  let code = [];
  let value = "";
  let secretCodeDate = "";
  let error = false;

  // 사용자 정보 불러오기
  async function getSecretCode() {
    try {
      const response = await axios.get(`/api/rcpt/postbox/${postboxId}`);
      secretCode = response.data.ah_yeonaehagosipda_end_date;
      return secretCode;
    } catch (error) {
      errorHandler(error);
    }
  }

  let secretCode = getSecretCode();

  async function validSecretCode(inputValue) {
    const secretCodeDate = secretCode.replaceAll("-", "");
    if (secretCodeDate === "20" + inputValue) {
      error = false;
      navigate(`/lover/${postboxId}`);
    } else {
      error = true;
      alert("비밀코드가 올바른 형식이 아닙니다");
   }
  }

  let errorMsg = "비밀코드가 일치하지 않아요.";
  let inputValue = "";
  $: inputValue.length == 6 && validSecretCode(inputValue);

  function addToInput(value) {
    inputValue += value;
  }

  function backspaceInput() {
    inputValue = inputValue.slice(0, -1);
  }

  function clearInput() {
    inputValue = "";
  }

  validSecretCode();
</script>

<div class="keypad">
  <div class="display">
    <!-- <div>{inputValue}</div> -->
    <Pincode bind:value={inputValue}>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(0, 1)} disabled />
      </div>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(1, 2)} disabled />
      </div>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(2, 3)} disabled />
      </div>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(3, 4)} disabled />
      </div>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(4, 5)} disabled />
      </div>
      <div class="pincode-input">
        <input type="text" value={inputValue.slice(5, 6)} disabled />
      </div>
    </Pincode>
  </div>
  <div class="error-section">
    {#if error}
      {errorMsg}
    {/if}
  </div>
  <div class="keypad-buttons">
    <div class="key" on:click={() => addToInput("1")}>1</div>
    <div class="key" on:click={() => addToInput("2")}>2</div>
    <div class="key" on:click={() => addToInput("3")}>3</div>
    <div class="key" on:click={() => addToInput("4")}>4</div>
    <div class="key" on:click={() => addToInput("5")}>5</div>
    <div class="key" on:click={() => addToInput("6")}>6</div>
    <div class="key" on:click={() => addToInput("7")}>7</div>
    <div class="key" on:click={() => addToInput("8")}>8</div>
    <div class="key" on:click={() => addToInput("9")}>9</div>
    <div class="key"></div>
    <div class="key" on:click={() => addToInput("0")}>0</div>
    <div class="key" on:click={() => backspaceInput()}>
      <BackspaceOutline />
    </div>
  </div>
</div>

<style>
  .keypad {
    width: 100%;
    border-radius: 5px;
    display: inline-block;
  }

  .display {
    position: relative;
    display: flex;
    color: #000;
    border-radius: 5px;
    padding: 4px;
    justify-content: center;
    text-align: right;
    margin-bottom: 10px;
  }

  /** Pincode **/
  .display > :global([data-pincode]) {
    display: inline-flex;
  }

  /** PincodeInput */
  .display > :global([data-pincode] input) {
    width: 2.6rem;
    height: 3.4rem;
    margin: 0;
    border: 0;
    border-radius: 10px;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    outline: none;
    color: var(--secondary-500-color) !important;
    background-color: #eaebf3 !important;
    font-family: "Pretendard_Regular";
  }

  .error-section {
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Pretendard_Regular";
    color: var(--semantic-danger-color);
    text-align: center;
  }

  .keypad-buttons {
    margin-top: 44px;
    margin-bottom: 36px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
  }

  .key {
    font-family: "Pretendard_Regular";
    font-weight: 400;
    color: #070c15;
    font-size: 24px;
    border: none;
    margin: 0 auto;
    width: 80%;
    height: 40px;
    padding: 10px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .key:active {
    background-color: #eaebf3;
  }
</style>
