<div class="moon-container">
  <div class="moon-item" />
  <p>달에게 물어봐!</p>
</div>

<style>
  .moon-container {
    top: 0px;
    right: 0px;
    width: 132px;
    height: 140px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
  }

  .moon-item {
    width: 124px;
    height: 124px;
    margin: auto;
    background-image: url("/assets/image/moon-glow-icon-size.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  p {
    position: absolute;
    top: 70px;
    right: 18px;
    font-size: 10px;
    color: #F0F0E4;
    font-family: 'Pretendard_Medium';
    font-weight: 500;
  }
</style>
